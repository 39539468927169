import { Checkbox, FormControlLabel, Popover } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, createRef } from "react";
import { toast } from "react-toastify";
import clinic4 from "../../../../Images/clinical8.png";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import eyeGroup from "../../../../Images/eyeGroup.png";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-color-picker/dist/tui-color-picker.css";
import { whiteTheme } from "./white-theme";
import { useScreenshot } from "use-react-screenshot";
import moment from "moment";
// import eye

toast.configure();

const Eye = (props) => {
  const [historyShowAll, setHistoryShowAll] = useState(false);
  const [historyArray, setHistoryArray] = useState([]);
  const [historySort, setHistorySort] = useState([]);
  const [historyValue, setHistoryValue] = useState([]);
  const [externalObservation, setExternalObservation] = useState({});
  const [anteriorEyeExamination, setAnteriorEyeExamination] = useState(false);
  //UCVA
  const [UCVARightDistance, setUCVARightDistance] = useState("");
  const [UCVARightNear, setUCVARightNear] = useState("");
  const [UCVALeftDistance, setUCVALeftDistance] = useState("");
  const [UCVALeftNear, setUCVALeftNear] = useState("");
  const [UCVAOUDistance, setUCVAOUDistance] = useState("");
  const [UCVAOUNear, setUCVAOUNear] = useState("");
  //UCVA
  //Pinhole
  const [pinholeRightDistance, setPinholeRightDistance] = useState("");
  const [pinholeRightNear, setPinholeRightNear] = useState("");
  const [pinholeOUNear, setPinholeOUNear] = useState("");
  const [pinholeOUDistance, setPinholeOUDistance] = useState("");
  const [pinholeLeftDistance, setPinholeLeftDistance] = useState("");
  const [pinholeLeftNear, setPinholeLeftNear] = useState("");
  //Pinhole
  //Cover test
  const [coverDistancePhoria, setCoverDistancePhoria] = useState("");
  const [coverDistanceTropia, setCoverDistanceTropia] = useState("");
  const [coverDistanceTropiaSide, setCoverDistanceTropiaSide] = useState("");
  const [coverDistanceTropiaType, setCoverDistanceTropiaType] = useState([]);
  const [coverNearPhoria, setCoverNearPhoria] = useState("");
  const [coverNearTropia, setCoverNearTropia] = useState("");
  const [coverNearTropiaSide, setCoverNearTropiaSide] = useState("");
  const [coverNearTropiaType, setCoverNearTropiaType] = useState([]);
  //Cover test

  //EOM
  const [eomType, setEomType] = useState([]);
  const [isSaccades, setIsSaccades] = useState(false);
  const [saccades, setSaccades] = useState([]);
  const [eomValue, setEomValue] = useState([]);
  //EOM
  //Pupils
  const [pupilsRight, setPupilsRight] = useState("");
  const [pupilsLeft, setPupilsLeft] = useState("");
  const [pupilsTypeRight, setPupilsTypeRight] = useState([]);
  const [pupilsTypeLeft, setPupilsTypeLeft] = useState([]);
  const [pupilsLeftValue, setPupilsLeftValue] = useState("");
  const [pupilsRightValue, setPupilsRightValue] = useState("");
  const [pupilsRightShape, setPupilsRightShape] = useState({
    part1: "",
    part2: "",
    part3: "",
    part4: "",
    part5: "",
    part6: "",
    part7: "",
    part8: "",
    center: "",
  });
  const [pupilsLeftShape, setPupilsLeftShape] = useState({
    part1: "",
    part2: "",
    part3: "",
    part4: "",
    part5: "",
    part6: "",
    part7: "",
    part8: "",
    center: "",
  });
  const handlePupilsRight = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setPupilsTypeRight([...pupilsTypeRight, value]);
    } else {
      const newCn = pupilsTypeRight.filter((item) => item !== value);
      setPupilsTypeRight(newCn);
    }
  };
  const handlePupilsLeft = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setPupilsTypeLeft([...pupilsTypeLeft, value]);
    } else {
      const newCn = pupilsTypeLeft.filter((item) => item !== value);
      setPupilsTypeLeft(newCn);
    }
  };
  //Pupils
  //Gonioscopy
  const [gonioscopy, setGonioscopy] = useState("");
  const [gonioscopyPopup, setGonioscopyPopup] = useState("");
  const [gonioscopyVanHarrik, setGonioscopyVanHarrik] = useState("");
  const [gonioscopyShapeRightS, setGonioscopyShapeRightS] = useState([]);
  const [gonioscopyShapeRightN, setGonioscopyShapeRightN] = useState([]);
  const [gonioscopyShapeRightT, setGonioscopyShapeRightT] = useState([]);
  const [gonioscopyShapeRightI, setGonioscopyShapeRightI] = useState([]);
  const [gonioscopyShapeRightC, setGonioscopyShapeRightC] = useState([]);

  const [gonioscopyShapeLeftS, setGonioscopyShapeLeftS] = useState([]);
  const [gonioscopyShapeLeftN, setGonioscopyShapeLeftN] = useState([]);
  const [gonioscopyShapeLeftT, setGonioscopyShapeLeftT] = useState([]);
  const [gonioscopyShapeLeftI, setGonioscopyShapeLeftI] = useState([]);
  const [gonioscopyShapeLeftC, setGonioscopyShapeLeftC] = useState([]);
  //Gonioscopy
  //Intra-Ocular Pressure
  const [intraOcularPressure, setIntraOcularPressure] = useState([]);
  const [intraOcularPressureCheckbox, setIntraOcularPressureCheckbox] =
    useState([]);
  const [intraOcularPressureLeftPre, setIntraOcularPressureLeftPre] =
    useState("");
  const [intraOcularPressureLeftPost, setIntraOcularPressureLeftPost] =
    useState("");
  const [intraOcularPressureRightPre, setIntraOcularPressureRightPre] =
    useState("");
  const [intraOcularPressureRightPost, setIntraOcularPressureRightPost] =
    useState("");
  const [intraOcularPressureStartTime, setIntraOcularPressureStartTime] =
    useState("");
  const [intraOcularPressureEndTime, setIntraOcularPressureEndTime] =
    useState("");
  const handleIntraOcularPressure = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setIntraOcularPressureCheckbox([...intraOcularPressureCheckbox, value]);
    } else {
      const newCn = intraOcularPressureCheckbox.filter(
        (item) => item !== value
      );
      setIntraOcularPressureCheckbox(newCn);
    }
  };
  const [isPressureDilated, setIsPressureDilated] = useState(false);
  //Intra-Ocular Pressure
  //Pachymetry
  const [pachymetryLeft, setPachymetryLeft] = useState("");
  const [pachymetryRight, setPachymetryRight] = useState("");
  //Pachymetry
  //Keratometry
  const [kRight1, setKRight1] = useState("");
  const [kRight2, setKRight2] = useState("");
  const [kRight3, setKRight3] = useState("");
  const [kRightD1, setKRightD1] = useState("");
  const [kRightD3, setKRightD3] = useState("");
  const [kRightD2, setKRightD2] = useState("");
  const [kRightAt1, setKRightAt1] = useState("");
  const [kRightAt2, setKRightAt2] = useState("");
  const [kRightCly, setKRightCly] = useState("");
  const [kRightClyAt, setKRightClyAt] = useState("");

  const [kLeft1, setKLeft1] = useState("");
  const [kLeft2, setKLeft2] = useState("");
  const [kLeft3, setKLeft3] = useState("");
  const [kLeftD1, setKLeftD1] = useState("");
  const [kLeftD3, setKLeftD3] = useState("");
  const [kLeftD2, setKLeftD2] = useState("");
  const [kLeftAt1, setKLeftAt1] = useState("");
  const [kLeftAt2, setKLeftAt2] = useState("");
  const [kLeftCly, setKLeftCly] = useState("");
  const [kLeftClyAt, setKLeftClyAt] = useState("");

  //Keratometry
  //PosteriorEyeExamination
  const [posteriorEye, setPosteriorEye] = useState([]);
  const [posteriorEyeCheckbox, setPosteriorEyeCheckbox] = useState([]);
  const [posteriorEyeShapeRight, setPosteriorEyeShapeRight] = useState({
    part1: "",
    part2: "",
    part3: "",
    part4: "",
    center: "",
  });
  const [posteriorEyeShapeLeft, setPosteriorEyeShapeLeft] = useState({
    part1: "",
    part2: "",
    part3: "",
    part4: "",
    center: "",
  });
  const [posteriorEyeStartTime, setPosteriorEyeStartTime] = useState("");
  const [posteriorEyeEndTime, setPosteriorEyeEndTime] = useState("");
  const [posteriorEyeComment, setPosteriorEyeComment] = useState("");

  useEffect(() => {
    if (posteriorEyeStartTime) {
      const posteriorEndTime = moment(posteriorEyeStartTime, "HH:mm:ss")
        .add(30, "minutes")
        .format("HH:mm:ss");
      setPosteriorEyeEndTime(posteriorEndTime);
    }
  }, [posteriorEyeStartTime]);
  const [posteriorDilation, setPosteriorDilation] = useState(false);
  const handlePosterior = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setPosteriorEye([...posteriorEye, value]);
    } else {
      const newArray = posteriorEye.filter((item) => item !== value);
      setPosteriorEye(newArray);
    }
  };
  //PosteriorEyeExamination
  //Spectacles
  // activity
  const [spectaclesFirstActive, setSpectaclesFirstActive] = useState({
    others: true,
    title: "PR",
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesFirstRightActive, setSpectaclesFirstRightActive] = useState({
    others: true,
    title: "PR",
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSecondActive, setSpectaclesSecondActive] = useState({
    others: true,
    title: "#",
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSecondRightActive, setSpectaclesSecondRightActive] =
    useState({
      others: true,
      title: "#",
      sphere: true,
      cyl: true,
      axis: true,
      VA: true,
      add: true,
      intern: true,
      prism: true,
      prismUnit: true,
    });
  const [spectaclesThirdActive, setSpectaclesThirdActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesThirdRightActive, setSpectaclesThirdRightActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesFourthActive, setSpectaclesFourthActive] = useState({
    others: true,
    title: "AR",
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesFourthRightActive, setSpectaclesFourthRightActive] =
    useState({
      others: true,
      title: "AR",
      sphere: true,
      cyl: true,
      axis: true,
      VA: true,
      add: true,
      intern: true,
      prism: true,
      prismUnit: true,
    });
  const [spectaclesFifthActive, setSpectaclesFifthActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesFifthRightActive, setSpectaclesFifthRightActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSixthActive, setSpectaclesSixthActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSixthRightActive, setSpectaclesSixthRightActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSeventhActive, setSpectaclesSeventhActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesSeventhRightActive, setSpectaclesSeventhRightActive] =
    useState({
      others: true,
      title: true,
      sphere: true,
      cyl: true,
      axis: true,
      VA: true,
      add: true,
      intern: true,
      prism: true,
      prismUnit: true,
    });
  const [spectaclesEighthActive, setSpectaclesEighthActive] = useState({
    others: true,
    title: true,
    sphere: true,
    cyl: true,
    axis: true,
    VA: true,
    add: true,
    intern: true,
    prism: true,
    prismUnit: true,
  });
  const [spectaclesEighthRightActive, setSpectaclesEighthRightActive] =
    useState({
      others: true,
      title: true,
      sphere: true,
      cyl: true,
      axis: true,
      VA: true,
      add: true,
      intern: true,
      prism: true,
      prismUnit: true,
    });
  // activity
  //final value
  const [spectaclesFirstValue, setSpectaclesFirstValue] = useState({
    others: "",
    title: "PR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFirstRightValue, setSpectaclesFirstRightValue] = useState({
    others: "",
    title: "PR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSecondValue, setSpectaclesSecondValue] = useState({
    others: "",
    title: "#",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSecondRightValue, setSpectaclesSecondRightValue] = useState({
    others: "",
    title: "#",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesThirdValue, setSpectaclesThirdValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesThirdRightValue, setSpectaclesThirdRightValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFourthValue, setSpectaclesFourthValue] = useState({
    others: "",
    title: "AR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFourthRightValue, setSpectaclesFourthRightValue] = useState({
    others: "",
    title: "AR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFifthValue, setSpectaclesFifthValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFifthRightValue, setSpectaclesFifthRightValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSixthValue, setSpectaclesSixthValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSixthRightValue, setSpectaclesSixthRightValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSeventhValue, setSpectaclesSeventhValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSeventhRightValue, setSpectaclesSeventhRightValue] =
    useState({
      others: "",
      title: "",
      sphere: "",
      cyl: "",
      axis: "",
      VA: "",
      add: "",
      intern: "",
      prism: "",
      prismUnit: "",
    });
  const [spectaclesEighthValue, setSpectaclesEighthValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesEighthRightValue, setSpectaclesEighthRightValue] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  //final value
  const [spectaclesFirst, setSpectaclesFirst] = useState({
    others: "",
    title: "PR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFirstRight, setSpectaclesFirstRight] = useState({
    others: "",
    title: "PR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSecond, setSpectaclesSecond] = useState({
    others: "",
    title: "#",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSecondRight, setSpectaclesSecondRight] = useState({
    others: "",
    title: "#",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesThird, setSpectaclesThird] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesThirdRight, setSpectaclesThirdRight] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFourth, setSpectaclesFourth] = useState({
    others: "",
    title: "AR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFourthRight, setSpectaclesFourthRight] = useState({
    others: "",
    title: "AR",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFifth, setSpectaclesFifth] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesFifthRight, setSpectaclesFifthRight] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSixth, setSpectaclesSixth] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSixthRight, setSpectaclesSixthRight] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesSeventh, setSpectaclesSeventh] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });

  const [spectaclesSeventhRight, setSpectaclesSeventhRight] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  // near
  const [spectaclesEighth, setSpectaclesEighth] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });
  const [spectaclesEighthRight, setSpectaclesEighthRight] = useState({
    others: "",
    title: "",
    sphere: "",
    cyl: "",
    axis: "",
    VA: "",
    add: "",
    intern: "",
    prism: "",
    prismUnit: "",
  });

  const spectaclesActivity = {
    others: false,
    title: "PR",
    sphere: false,
    cyl: false,
    axis: false,
    VA: false,
    add: false,
    intern: false,
    prism: false,
    prismUnit: false,
  };

  const [trailFramed, setTrailFramed] = useState(false);
  const [rxAdvice, setRxAdvice] = useState([]);
  const suActivity = (
    value,
    valueRight,
    convertedValue,
    convertedValueRight
  ) => {
    setSpectaclesSixth(value);
    setSpectaclesSixthRight(valueRight);
    setSpectaclesSixthValue(convertedValue);
    setSpectaclesSixthRightValue(convertedValueRight);
    setSpectaclesSixthActive({
      others: false,
      title: "",
      sphere: false,
      cyl: false,
      axis: false,
      VA: false,
      add: false,
      intern: false,
      prism: false,
      prismUnit: false,
    });
    setSpectaclesSixthRightActive({
      others: false,
      title: "",
      sphere: false,
      cyl: false,
      axis: false,
      VA: false,
      add: false,
      intern: false,
      prism: false,
      prismUnit: false,
    });
  };
  const giActivity = (
    value,
    valueRight,
    convertedValue,
    convertedValueRight
  ) => {
    setSpectaclesSeventh(value);
    setSpectaclesSeventhRight(valueRight);
    setSpectaclesSeventhValue(convertedValue);
    setSpectaclesSeventhRightValue(convertedValueRight);
    setSpectaclesSeventhActive({
      others: false,
      title: "PR",
      sphere: false,
      cyl: false,
      axis: false,
      VA: false,
      add: false,
      intern: false,
      prism: false,
      prismUnit: false,
    });
    setSpectaclesSeventhRightActive({
      others: false,
      title: "PR",
      sphere: false,
      cyl: false,
      axis: false,
      VA: false,
      add: false,
      intern: false,
      prism: false,
      prismUnit: false,
    });
  };
  //Spectacles
  //contract lens
  const [contractLens, setContractLens] = useState({
    consultationDate: "",
    dueDate: "",
    prescriber: "",
    dispenser: "",
    invNo: "",
    warrantyExpire: "",
    right: "",
    left: "",
  });
  const [contractLensInfoRight, setContractLensInfoRight] = useState({
    cLCode: "",
    sup: "",
    BCOR: "",
    OAD: "",
    sphere: "",
    cyl: "",
    axis: "",
    add: "",
    BCOD: "",
    perip: "",
    orderStatus: "",
    price: "",
    qty: "",
  });
  const [contractLensInfoLeft, setContractLensInfoLeft] = useState({
    cLCode: "",
    sup: "",
    BCOR: "",
    OAD: "",
    sphere: "",
    cyl: "",
    axis: "",
    add: "",
    BCOD: "",
    perip: "",
    orderStatus: "",
    price: "",
    qty: "",
  });
  //contract lens
  //Ancillary Test
  const [ancillaryTestInfo, setAncillaryTestInfo] = useState();
  console.log("ancillaryTestInfo", ancillaryTestInfo);
  const [ancillaryTestImportExport, setAncillaryTestImportExport] = useState(
    []
  );
  //Ancillary Test

  //Previous Spectacles
  // activity
  const [previousSpectaclesActive1, setPreviousSpectaclesActive1] = useState({
    optom: true,
    type: true,
    sphereRight: true,
    cylRight: true,
    addRight: true,
    interRight: true,
    sphereLeft: true,
    cylLeft: true,
    addLeft: true,
    interLeft: true,
  });
  const [previousSpectaclesActive2, setPreviousSpectaclesActive2] = useState({
    optom: true,
    type: true,
    sphereRight: true,
    cylRight: true,
    addRight: true,
    interRight: true,
    sphereLeft: true,
    cylLeft: true,
    addLeft: true,
    interLeft: true,
  });
  const [previousSpectaclesActive3, setPreviousSpectaclesActive3] = useState({
    optom: true,
    type: true,
    sphereRight: true,
    cylRight: true,
    addRight: true,
    interRight: true,
    sphereLeft: true,
    cylLeft: true,
    addLeft: true,
    interLeft: true,
  });
  // activity
  const today = moment(new Date()).format("YYYY-MM-DD");
  const [previousSpectacles1, setPreviousSpectacles1] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  const [previousSpectaclesValue1, setPreviousSpectaclesValue1] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  const [previousSpectacles2, setPreviousSpectacles2] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  const [previousSpectaclesValue2, setPreviousSpectaclesValue2] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  const [previousSpectacles3, setPreviousSpectacles3] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  const [previousSpectaclesValue3, setPreviousSpectaclesValue3] = useState({
    date: today,
    optom: "",
    type: "",
    sphereRight: "",
    cylRight: "",
    axisRight: "",
    addRight: "",
    interRight: "",
    sphereLeft: "",
    cylLeft: "",
    axisLeft: "",
    addLeft: "",
    interLeft: "",
    noteLeft: "",
  });
  // const [setPreviousSpectacles2, setPreviousSpectacles2] = useState([]);
  //Previous Spectacles

  //Slit lamp
  const [sitLamp, setSitLamp] = useState("");
  //Slit lamp

  const [externalObservationArray, setExternalObservationArray] = useState([]);

  const [UCVAArray1, setUCVAArray1] = useState([
    { name: "6/5+", id: 1 },
    { name: "6/5", id: 2 },
    { name: "6/5-", id: 3 },
    { name: "6/5=", id: 4 },
    { name: "6/6+", id: 5 },
    { name: "6/6", id: 6 },
    { name: "6/6-", id: 7 },
    { name: "6/6=", id: 8 },
    { name: "6/7.5+", id: 9 },
    { name: "6/7.5", id: 10 },
    { name: "6/7.5-", id: 11 },
    { name: "6/7.5=", id: 12 },
    { name: "6/9+", id: 13 },
  ]);
  const [UCVAArray2, setUCVAArray2] = useState([
    { name: "6/9", id: 1 },
    { name: "6/9-", id: 2 },
    { name: "6/9=", id: 3 },
    { name: "6/12+", id: 4 },
    { name: "6/12", id: 5 },
    { name: "6/12-", id: 6 },
    { name: "6/12=", id: 7 },
    { name: "6/24+", id: 8 },
    { name: "6/24", id: 9 },
    { name: "6/24-", id: 10 },
    { name: "6/24=", id: 11 },
    { name: "6/36+", id: 12 },
    { name: "6/36", id: 13 },
  ]);
  const [UCVAArray3, setUCVAArray3] = useState([
    { name: "6/36-", id: 1 },
    { name: "6/36=", id: 2 },
    { name: "6/60+", id: 3 },
    { name: "6/60", id: 4 },
    { name: "6/60-", id: 5 },
    { name: "6/60=", id: 6 },
    { name: "6/120+", id: 7 },
    { name: "6/120", id: 8 },
    { name: "6/120-", id: 9 },
    { name: "6/120=", id: 10 },
    { name: "HM", id: 11 },
    { name: "LP", id: 12 },
  ]);
  useEffect(() => {
    let controller = new AbortController();
    axios
      .get(`/commonHistory-all/eye`, { signal: controller.signal })
      .then((res) => {
        if (res.data.status === 200) {
          setHistoryArray(res.data.commonHistory);
          setHistorySort(res.data.commonHistory);
        }
      });
    axios
      .get(`/external-observation`, { signal: controller.signal })
      .then((res) => {
        if (res.data.status === 200) {
          setExternalObservationArray(res.data.ExternalObservation);
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  //
  const [change, setChange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [change4, setChange4] = useState(false);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? "simple-popover" : undefined;
  //
  //
  const [change7, setChange7] = useState(false);
  const [anchorEl7, setAnchorEl7] = useState(null);
  const open7 = Boolean(anchorEl7);
  const id7 = open7 ? "simple-popover" : undefined;
  //
  const [change8, setChange8] = useState(false);
  const [anchorEl8, setAnchorEl8] = useState(null);
  const open8 = Boolean(anchorEl8);
  const id8 = open8 ? "simple-popover" : undefined;
  //
  const [change9, setChange9] = useState(false);
  const [anchorEl9, setAnchorEl9] = useState(null);
  const open9 = Boolean(anchorEl9);
  const id9 = open9 ? "simple-popover" : undefined;
  //
  const [change10, setChange10] = useState(false);
  const [anchorEl10, setAnchorEl10] = useState(null);
  const open10 = Boolean(anchorEl10);
  const id10 = open10 ? "simple-popover" : undefined;
  //
  const [change11, setChange11] = useState(false);
  const [anchorEl11, setAnchorEl11] = useState(null);
  const open11 = Boolean(anchorEl11);
  const id11 = open11 ? "simple-popover" : undefined;
  //
  const [change12, setChange12] = useState(false);
  const [anchorEl12, setAnchorEl12] = useState(null);
  const open12 = Boolean(anchorEl12);
  const id12 = open12 ? "simple-popover" : undefined;
  //
  const [change13, setChange13] = useState(false);
  const [anchorEl13, setAnchorEl13] = useState(null);
  const open13 = Boolean(anchorEl13);
  const id13 = open13 ? "simple-popover" : undefined;
  //
  const [change14, setChange14] = useState(false);
  const [anchorEl14, setAnchorEl14] = useState(null);
  const open14 = Boolean(anchorEl14);
  const id14 = open14 ? "simple-popover" : undefined;
  //
  const [change15, setChange15] = useState(false);
  const [anchorEl15, setAnchorEl15] = useState(null);
  const open15 = Boolean(anchorEl15);
  const id15 = open15 ? "simple-popover" : undefined;
  //
  const [change16, setChange16] = useState(false);
  const [anchorEl16, setAnchorEl16] = useState(null);
  const open16 = Boolean(anchorEl16);
  const id16 = open16 ? "simple-popover" : undefined;
  //
  const [change17, setChange17] = useState(false);
  const [anchorEl17, setAnchorEl17] = useState(null);
  const open17 = Boolean(anchorEl17);
  const id17 = open17 ? "simple-popover" : undefined;
  //
  const [change18, setChange18] = useState(false);
  const [anchorEl18, setAnchorEl18] = useState(null);
  const open18 = Boolean(anchorEl18);
  const id18 = open18 ? "simple-popover" : undefined;
  //
  const [change19, setChange19] = useState(false);
  const [anchorEl19, setAnchorEl19] = useState(null);
  const open19 = Boolean(anchorEl19);
  const id19 = open19 ? "simple-popover" : undefined;
  //
  const [change20, setChange20] = useState(false);
  const [anchorEl20, setAnchorEl20] = useState(null);
  const open20 = Boolean(anchorEl20);
  const id20 = open20 ? "simple-popover" : undefined;
  //
  const [anchorEl21, setAnchorEl21] = useState(null);
  const open21 = Boolean(anchorEl21);
  const id21 = open21 ? "simple-popover" : undefined;
  //
  //
  const [anchorEl22, setAnchorEl22] = useState(null);
  const open22 = Boolean(anchorEl22);
  const id22 = open22 ? "simple-popover" : undefined;
  //

  //
  // Image
  const ref = createRef(null);
  const ref2 = createRef(null);
  const refSitLamp = createRef(null);
  const spectaclesRef = createRef(null);
  const KeratometryRef = createRef(null);
  const PreviousSpectaclesRef = createRef(null);
  const ContactLenseslesRef = createRef(null);
  const pupilsRef = createRef(null);

  const [image, takeScreenShot] = useScreenshot();
  const [imageGonioscopy, takeGonioscopyScreenShot] = useScreenshot("");
  const [imageSitLamp, takeSitLampScreenShot] = useScreenshot("");
  const [SpectaclesScrenShot, takeSpectaclesScrenShot] = useScreenshot("");
  const [KeratometryScrenShot, takeKeratometryScrenShot] = useScreenshot("");
  const [PreviousSpectaclesScrenShot, takePreviousSpectaclesScrenShot] =
    useScreenshot("");
  const [ContactLensesScrenShot, takeContactLensesScrenShot] =
    useScreenshot("");
  const [PupilsScrenShot, takePupilsScrenShot] = useScreenshot("");

  console.log("ContactLensesScrenShot", PupilsScrenShot);
  const getImage = () => takeScreenShot(ref.current);
  const getGonioscopyImage = () => takeGonioscopyScreenShot(ref2.current);
  const getSitLampImage = () => {
    takeSitLampScreenShot(refSitLamp.current);
    setAnchorEl13(null);
  };
  // fff
  const [pdData, setPdData] = useState({
    distantLeft: "",
    distantRight: "",
    nearRight: "",
    nearLeft: "",
  });
  const handlePdChange = (e) => {
    const { name, value } = e.target;
    setPdData({ ...pdData, [name]: value });
  };
  const [adviceData, setAdviceData] = useState({
    lens: [],
    multifocal: [],
    instruction: [],
  });
  const handleMultifocalChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAdviceData({
        ...adviceData,
        multifocal: [...adviceData.multifocal, value],
      });
    } else {
      const newArray = adviceData.multifocal.filter((item) => item !== value);
      setAdviceData({ ...adviceData, multifocal: newArray });
    }
  };
  const handleInstructionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAdviceData({
        ...adviceData,
        instruction: [...adviceData.instruction, value],
      });
    } else {
      const newArray = adviceData.instruction.filter((item) => item !== value);
      setAdviceData({ ...adviceData, instruction: newArray });
    }
  };
  const handleLensChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAdviceData({ ...adviceData, lens: [...adviceData.lens, value] });
    } else {
      const newArray = adviceData.lens.filter((item) => item !== value);
      setAdviceData({ ...adviceData, lens: newArray });
    }
  };

  console.log(pdData, "adviceData");
  // Image
  const [saveLoding, setsaveLoding] = useState(false);

  const saveEyeData = () => {
    setsaveLoding(true);
    const data = {
      patient_id: props.patient_id,
      appointment_id: props?.appId,
      eye_History: historyValue.toString(), //arr
      externalObservation: JSON.stringify(externalObservation), //obj
      spectaclesFirstValue: JSON.stringify(spectaclesFirstValue), //obj
      spectaclesFirstRightValue: JSON.stringify(spectaclesFirstRightValue), //obj
      spectaclesSecondValue: JSON.stringify(spectaclesSecondValue), //obj
      spectaclesSecondRightValue: JSON.stringify(spectaclesSecondRightValue), //obj
      spectaclesThirdValue: JSON.stringify(spectaclesThirdValue), //obj
      spectaclesThirdRightValue: JSON.stringify(spectaclesThirdRightValue), //obj
      spectaclesFourthValue: JSON.stringify(spectaclesFourthValue), //obj
      spectaclesFourthRightValue: JSON.stringify(spectaclesFourthRightValue), //obj
      spectaclesFifthValue: JSON.stringify(spectaclesFifthValue), //obj
      spectaclesFifthRightValue: JSON.stringify(spectaclesFifthRightValue), //obj
      spectaclesSixthValue: JSON.stringify(spectaclesSixthValue), //obj
      spectaclesSixthRightValue: JSON.stringify(spectaclesSixthRightValue), //obj
      spectaclesSeventhValue: JSON.stringify(spectaclesSeventhValue), //obj
      spectaclesSeventhRightValue: JSON.stringify(spectaclesSeventhRightValue), //obj
      trailFramed: trailFramed,
      eyeRx: JSON.stringify(eyeRx), // obj
      contractLens: JSON.stringify(contractLens), // obj
      contractLensInfoRight: JSON.stringify(contractLensInfoRight), // obj
      contractLensInfoLeft: JSON.stringify(contractLensInfoLeft), // obj
      ancillaryTestInfo: JSON.stringify(ancillaryTestInfo), // obj
      ancillaryTestImportExport: ancillaryTestImportExport.toString(), // arr
      posteriorEye: posteriorEye.toString(), // arr
      posteriorEyeCheckbox: posteriorEyeCheckbox.toString(), // arr
      posteriorEyeShapeRight: JSON.stringify(posteriorEyeShapeRight), // obj
      posteriorEyeShapeLeft: JSON.stringify(posteriorEyeShapeLeft), // obj
      posteriorEyeStartTime: posteriorEyeStartTime,
      posteriorEyeEndTime: posteriorEyeEndTime,
      posteriorEyeComment: posteriorEyeComment,
      kRight1: kRight1,
      kRightD1: kRightD1,
      kRightD3: kRightD3,
      kRightD2: kRightD2,
      kRightAt1: kRightAt1,
      kRightAt2: kRightAt2,
      kRightCly: kRightCly,
      kRightClyAt: kRightClyAt,
      kLeft1: kLeft1,
      kLeft2: kLeft2,
      kLeft3: kLeft3,
      kLeftD1: kLeftD1,
      kLeftD3: kLeftD3,
      kLeftD2: kLeftD2,
      kLeftAt1: kLeftAt1,
      kLeftAt2: kLeftAt2,
      kLeftCly: kLeftCly,
      kLeftClyAt: kLeftClyAt,
      previousSpectaclesValue1: JSON.stringify(previousSpectaclesValue1), //obj
      previousSpectaclesValue2: JSON.stringify(previousSpectaclesValue2), //obj
      previousSpectaclesValue3: JSON.stringify(previousSpectaclesValue3), //obj
      UCVARightDistance: UCVARightDistance,
      UCVARightNear: UCVARightNear,
      UCVALeftNear: UCVALeftNear,
      UCVALeftDistance: UCVALeftDistance,
      UCVAOUDistance: UCVAOUDistance,
      UCVAOUNear: UCVAOUNear,
      pinholeRightDistance: pinholeRightDistance,
      pinholeRightNear: pinholeRightNear,
      pinholeOUNear: pinholeOUNear,
      pinholeOUDistance: pinholeOUDistance,
      pinholeLeftDistance: pinholeLeftDistance,
      pinholeLeftNear: pinholeLeftNear,
      coverDistancePhoria: coverDistancePhoria,
      coverDistanceTropia: coverDistanceTropia,
      coverDistanceTropiaSide: coverDistanceTropiaSide,
      coverDistanceTropiaType: coverDistanceTropiaType.toString(), //arr
      coverNearPhoria: coverNearPhoria,
      coverNearTropia: coverNearTropia,
      coverNearTropiaSide: coverNearTropiaSide,
      coverNearTropiaType: coverNearTropiaType.toString(),
      eomType: eomType.toString(), //arr
      isSaccades: isSaccades,
      saccades: saccades.toString(), //arr
      eomValue: eomValue.toString(), //arr
      pupilsRight: pupilsRight,
      pupilsLeft: pupilsLeft,
      pupilsTypeRight: pupilsTypeRight.toString(), //arr
      pupilsTypeLeft: pupilsTypeLeft.toString(), //arr
      pupilsLeftValue: pupilsLeftValue,
      pupilsRightValue: pupilsRightValue,
      pupilsRightShape: JSON.stringify(pupilsRightShape), //obj
      pupilsLeftShape: JSON.stringify(pupilsLeftShape), //obj
      imageSitLamp: imageSitLamp, //image big string
      sitLamp: sitLamp,
      gonioscopy: gonioscopy,
      gonioscopyVanHarrik: gonioscopyVanHarrik,
      imageGonioscopy: imageGonioscopy, // Image big string
      gonioscopyShapeRightS: gonioscopyShapeRightS.toString(), //arr
      gonioscopyShapeRightN: gonioscopyShapeRightN.toString(), //arr
      gonioscopyShapeRightT: gonioscopyShapeRightT.toString(), //arr
      gonioscopyShapeRightI: gonioscopyShapeRightI.toString(), //arr
      gonioscopyShapeRightC: gonioscopyShapeRightC.toString(), //arr
      gonioscopyShapeLeftS: gonioscopyShapeLeftS.toString(), //arr
      gonioscopyShapeLeftN: gonioscopyShapeLeftN.toString(), //arr
      gonioscopyShapeLeftT: gonioscopyShapeLeftT.toString(), //arr
      gonioscopyShapeLeftI: gonioscopyShapeLeftI.toString(), //arr
      gonioscopyShapeLeftC: gonioscopyShapeLeftC.toString(), //arr
      intraOcularPressure: intraOcularPressure.toString(), //arr
      intraOcularPressureCheckbox: intraOcularPressureCheckbox.toString(), //arr
      intraOcularPressureLeftPre: intraOcularPressureLeftPre,
      intraOcularPressureLeftPost: intraOcularPressureLeftPost,
      intraOcularPressureRightPre: intraOcularPressureRightPre,
      intraOcularPressureRightPost: intraOcularPressureRightPost,
      intraOcularPressureStartTime: intraOcularPressureStartTime,
      intraOcularPressureEndTime: intraOcularPressureEndTime,
      pachymetryLeft: pachymetryLeft,
      pachymetryRight: pachymetryRight,
      eyeImage: eyeImage.toString(), //  arr image
    };

    axios.post("/save-eye-history", data).then((res) => {
      console.log("eye", res.data);

      const note = `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
   
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Eye:</strong></span><br>
   
   
    
       ${
         res.data.data1st.eye_History === null
           ? ``
           : `
            <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>History:</strong></span><br>
            ${res.data.data1st.eye_History.replaceAll(",", " . ")} <br>`
       }

 
    ${
      eyeImage.length === 0
        ? ``
        : `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Diagram:</strong></span><br>
         ${eyeImage.map(
           (item, i) =>
             `<img key=${i} src=${item} alt="" width="300" height="177">&nbsp;&nbsp;`
         )}`
    }  

    
    <br><span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Examination:</strong></span><br>
    
    ${
      res.data.data1st.externalObservation === "{}"
        ? ``
        : `
        <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>External observation:</strong></span><br>
        ${res.data.data1st.externalObservation.replaceAll(/"|{|}/gi, " ")}`
    }

        ${
          !SpectaclesScrenShot && res.data.data1st.eyeRx === "{}"
            ? ``
            : `
                 <br><span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Spectacles:</strong></span><br>
            ${
              !SpectaclesScrenShot
                ? ``
                : `<img src=${SpectaclesScrenShot} alt="" width="631" height="280"><br>`
            } 
            ${
              res.data.data1st.eyeRx === "{}"
                ? ``
                : res.data.data1st.eyeRx
                    .replaceAll(/"|{|}/gi, " ")
                    .replaceAll("[", "")
                    .replaceAll("],", "<br>")
                    .replaceAll("]", "<br>")
            }
        `
        }
  

    ${
      !KeratometryScrenShot
        ? ``
        : `
        <br><span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Keratometry:</strong></span><br>
        <img src=${KeratometryScrenShot} alt="" width="385" height="175"><br>`
    }
   
 
    ${
      !PreviousSpectaclesScrenShot
        ? ``
        : `
       <br><span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Previous spectacles Rx:</strong></span><br>
       <img src=${PreviousSpectaclesScrenShot} alt="" width="631" height="230">`
    }
    
    ${
      !ContactLensesScrenShot
        ? ``
        : `
       <br><span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Contact lenses:</strong></span><br>
       <img src=${ContactLensesScrenShot} alt="" width="631" height="331"><br>`
    }
   

       ${
         res.data.data1st.UCVARightDistance === null &&
         res.data.data1st.UCVALeftDistance === null &&
         res.data.data1st.UCVAOUDistance === null &&
         res.data.data1st.UCVARightNear === null &&
         res.data.data1st.UCVALeftNear === null &&
         res.data.data1st.UCVAOUNear === null &&
         res.data.data1st.pinholeRightDistance === null &&
         res.data.data1st.pinholeLeftDistance === null &&
         res.data.data1st.pinholeOUDistance === null &&
         res.data.data1st.pinholeRightNear === null &&
         res.data.data1st.pinholeLeftNear === null &&
         res.data.data1st.pinholeOUNear === null &&
         res.data.data1st.coverDistancePhoria === null &&
         res.data.data1st.coverDistanceTropia === null &&
         res.data.data1st.coverNearPhoria === null &&
         res.data.data1st.coverNearTropia === null &&
         res.data.data1st.eomValue === null &&
         res.data.data2nd.pupilsRight === null &&
         res.data.data2nd.pupilsTypeRight === null &&
         res.data.data2nd.pupilsRightValue === null &&
         res.data.data2nd.pupilsLeft === null &&
         res.data.data2nd.pupilsTypeLeft === null &&
         res.data.data2nd.pupilsLeftValue === null &&
         !PupilsScrenShot &&
         res.data.data2nd.gonioscopy === null &&
         !imageGonioscopy &&
         res.data.data1st.intraOcularPressure === null &&
         res.data.data1st.intraOcularPressureCheckbox === null &&
         res.data.data1st.intraOcularPressureStartTime === null &&
         res.data.data1st.intraOcularPressureLeftPre === null &&
         res.data.data1st.intraOcularPressureLeftPost === null &&
         !imageSitLamp &&
         res.data.data1st.pachymetryRight === null &&
         res.data.data1st.pachymetryLeft === null
           ? ``
           : `
           <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Anterior eye examination:</strong></span><br>       
                `
       }

   ${
     res.data.data1st.UCVARightDistance === null &&
     res.data.data1st.UCVALeftDistance === null &&
     res.data.data1st.UCVAOUDistance === null &&
     res.data.data1st.UCVARightNear === null &&
     res.data.data1st.UCVALeftNear === null &&
     res.data.data1st.UCVAOUNear === null
       ? ``
       : `   Ulva:
    <table style="border-collapse: collapse; width: 458px; height: 99px;">
        <tbody>
            <tr>
                <td style="width: 30%;"><br></td>
                <td style="width: 20%;">Right</td>
                <td style="width: 20%;">Left</td>
                <td style="width: 20%;">OU</td>
            </tr>
            <tr>

                <td>Distant</td>
                <td>${
                  res.data.data1st.UCVARightDistance === null
                    ? `<br>`
                    : res.data.data1st.UCVARightDistance
                }</td>
                <td>${
                  res.data.data1st.UCVALeftDistance === null
                    ? `<br>`
                    : res.data.data1st.UCVALeftDistance
                }</td>
                <td>${
                  res.data.data1st.UCVAOUDistance === null
                    ? `<br>`
                    : res.data.data1st.UCVAOUDistance
                }</td>
              
            </tr>
            <tr>
                <td>Near</td>
                <td>${
                  res.data.data1st.UCVARightNear === null
                    ? `<br>`
                    : res.data.data1st.UCVARightNear
                }</td>
                <td>${
                  res.data.data1st.UCVALeftNear === null
                    ? `<br>`
                    : res.data.data1st.UCVALeftNear
                }</td>
                <td>${
                  res.data.data1st.UCVAOUNear === null
                    ? `<br>`
                    : res.data.data1st.UCVAOUNear
                }</td>
                
            </tr>
        </tbody>
    </table>
`
   }

   ${
     res.data.data1st.pinholeRightDistance === null &&
     res.data.data1st.pinholeLeftDistance === null &&
     res.data.data1st.pinholeOUDistance === null &&
     res.data.data1st.pinholeRightNear === null &&
     res.data.data1st.pinholeLeftNear === null &&
     res.data.data1st.pinholeOUNear === null
       ? ``
       : `   Pinhole:
    <table style="border-collapse: collapse; width: 458px; height: 99px;">
        <tbody>
            <tr>
                <td style="width: 30%;"><br></td>
                <td style="width: 20%;">Right</td>
                <td style="width: 20%;">Left</td>
                <td style="width: 20%;">OU</td>
            </tr>
            <tr>

                <td>Distant</td>
                <td>${
                  res.data.data1st.pinholeRightDistance === null
                    ? `<br>`
                    : res.data.data1st.pinholeRightDistance
                }</td>
                <td>${
                  res.data.data1st.pinholeLeftDistance === null
                    ? `<br>`
                    : res.data.data1st.pinholeLeftDistance
                }</td>
                <td>${
                  res.data.data1st.pinholeOUDistance === null
                    ? `<br>`
                    : res.data.data1st.pinholeOUDistance
                }</td>
              
            </tr>
            <tr>
                <td>Near</td>
                <td>${
                  res.data.data1st.pinholeRightNear === null
                    ? `<br>`
                    : res.data.data1st.pinholeRightNear
                }</td>
                <td>${
                  res.data.data1st.pinholeLeftNear === null
                    ? `<br>`
                    : res.data.data1st.pinholeLeftNear
                }</td>
                <td>${
                  res.data.data1st.pinholeOUNear === null
                    ? `<br>`
                    : res.data.data1st.pinholeOUNear
                }</td>
                
            </tr>
        </tbody>
    </table>

    
`
   }


${
  res.data.data1st.coverDistancePhoria === null &&
  res.data.data1st.coverDistanceTropia === null &&
  res.data.data1st.coverNearPhoria === null &&
  res.data.data1st.coverNearTropia === null
    ? ``
    : `Cover test : `
}



        ${
          res.data.data1st.coverDistancePhoria === null &&
          res.data.data1st.coverDistanceTropia === null
            ? ``
            : `
       <br>&nbsp; &nbsp; &nbsp; Distant -
            ${
              res.data.data1st.coverDistancePhoria === null
                ? ``
                : `Phoria : ${res.data.data1st.coverDistancePhoria} <br> `
            }
            ${
              res.data.data1st.coverDistanceTropia === null
                ? ``
                : `Tropia : ${res.data.data1st.coverDistanceTropia} . ${res.data.data1st.coverDistanceTropiaSide} . ${res.data.data1st.coverDistanceTropiaType}`
            }

        `
        }

        ${
          res.data.data1st.coverNearPhoria === null &&
          res.data.data1st.coverNearTropia === null
            ? ``
            : `
        <br>&nbsp; &nbsp; &nbsp; Near -
            ${
              res.data.data1st.coverNearPhoria === null
                ? ``
                : `Phoria : ${res.data.data1st.coverNearPhoria} <br> `
            }
            ${
              res.data.data1st.coverNearTropia === null
                ? ``
                : `Tropia : ${res.data.data1st.coverNearTropia} . ${res.data.data1st.coverNearTropiaSide} . ${res.data.data1st.coverNearTropiaType}`
            }

        `
        }

      

            ${
              res.data.data1st.eomValue === null
                ? ``
                : `
             <br> Extra ocular muscles: <br>
            &nbsp; &nbsp; &nbsp;${res.data.data1st.eomValue.replaceAll(
              ",",
              " . "
            )} `
            }


            ${
              res.data.data2nd.pupilsRight === null &&
              res.data.data2nd.pupilsTypeRight === null &&
              res.data.data2nd.pupilsRightValue === null &&
              res.data.data2nd.pupilsLeft === null &&
              res.data.data2nd.pupilsTypeLeft === null &&
              res.data.data2nd.pupilsLeftValue === null &&
              !PupilsScrenShot
                ? ``
                : ` <br> Pupils:`
            }
           

             ${
               res.data.data2nd.pupilsRight === null &&
               res.data.data2nd.pupilsTypeRight === null &&
               res.data.data2nd.pupilsRightValue === null
                 ? ``
                 : `
            <br>&nbsp; &nbsp; &nbsp; Right -
                ${
                  res.data.data2nd.pupilsRight === null
                    ? ``
                    : `${res.data.data2nd.pupilsRight} | `
                }
                ${
                  res.data.data2nd.pupilsTypeRight === null
                    ? ``
                    : `${res.data.data2nd.pupilsTypeRight} | `
                }
                ${
                  res.data.data2nd.pupilsRightValue === null
                    ? ``
                    : `${res.data.data2nd.pupilsRightValue} mm`
                }
            `
             }

             ${
               res.data.data2nd.pupilsLeft === null &&
               res.data.data2nd.pupilsTypeLeft === null &&
               res.data.data2nd.pupilsLeftValue === null &&
               !PupilsScrenShot
                 ? ``
                 : `
              <br>&nbsp; &nbsp; &nbsp; Left -
                ${
                  res.data.data2nd.pupilsLeft === null
                    ? ``
                    : `${res.data.data2nd.pupilsLeft} | `
                }
                ${
                  res.data.data2nd.pupilsTypeLeft === null
                    ? ``
                    : `${res.data.data2nd.pupilsTypeLeft} | `
                }
                ${
                  res.data.data2nd.pupilsLeftValue === null
                    ? ``
                    : `${res.data.data2nd.pupilsLeftValue} mm`
                }
           
                 ${
                   !PupilsScrenShot
                     ? ``
                     : `
                    <br>&nbsp; &nbsp; &nbsp;
                    <img src=${PupilsScrenShot} alt="" width="230" height="155"><br>`
                 }
           
            `
             }

        
   
 
        ${
          res.data.data2nd.gonioscopy === null && !imageGonioscopy
            ? ``
            : `
               Gonioscopy: <br>
               ${res.data.data2nd.gonioscopy} ${
                res.data.data2nd.gonioscopyVanHarrik === null
                  ? ``
                  : res.data.data2nd.gonioscopyVanHarrik
              }

                       ${
                         !imageGonioscopy
                           ? ``
                           : `
        <br>&nbsp; &nbsp; &nbsp;
        <img src=${imageGonioscopy} alt="" width="420" height="200"><br>`
                       }
   
        `
        }


        ${
          res.data.data1st.intraOcularPressure === null &&
          res.data.data1st.intraOcularPressureCheckbox === null &&
          res.data.data1st.intraOcularPressureStartTime === null &&
          res.data.data1st.intraOcularPressureLeftPre === null &&
          res.data.data1st.intraOcularPressureLeftPost === null
            ? ``
            : `
            Intra-Ocular Pressure: <br>
                    ${
                      res.data.data1st.intraOcularPressure === null
                        ? ``
                        : `&nbsp; &nbsp; &nbsp;${res.data.data1st.intraOcularPressure}`
                    }
                    ${
                      res.data.data1st.intraOcularPressureCheckbox === null
                        ? ``
                        : `&nbsp;${res.data.data1st.intraOcularPressureCheckbox}`
                    }

                    ${
                      res.data.data1st.intraOcularPressureStartTime === null
                        ? ``
                        : ` <br>&nbsp; &nbsp; &nbsp; Drop Given at ${res.data.data1st.intraOcularPressureStartTime}  | Check at ${res.data.data1st.intraOcularPressureEndTime}`
                    }
                    ${
                      res.data.data1st.intraOcularPressureLeftPre === null
                        ? ``
                        : ` <br>&nbsp; &nbsp; &nbsp;Pre Dilation R : ${
                            res.data.data1st.intraOcularPressureLeftPre
                          } mm Hg ${
                            res.data.data1st.intraOcularPressureRightPre ===
                            null
                              ? ``
                              : ` L : ${res.data.data1st.intraOcularPressureRightPre} mm Hg`
                          }`
                    }
                    ${
                      res.data.data1st.intraOcularPressureLeftPost === null
                        ? ``
                        : ` <br>&nbsp; &nbsp; &nbsp;Post Dilation R : ${
                            res.data.data1st.intraOcularPressureLeftPost
                          } mm Hg ${
                            res.data.data1st.intraOcularPressureRightPost ===
                            null
                              ? ``
                              : ` L : ${res.data.data1st.intraOcularPressureRightPost} mm Hg`
                          }`
                    }
        `
        }
        ${
          !imageSitLamp
            ? ``
            : `
        <br>Slit Lamp Bio-microscopy: <br>
        <img src=${imageSitLamp} alt="" width="610" height="300"><br>`
        }

         ${
           res.data.data1st.pachymetryRight === null &&
           res.data.data1st.pachymetryLeft === null
             ? ``
             : `
                    Pachymetry: <br>
                    ${
                      res.data.data1st.pachymetryRight === null
                        ? ``
                        : `&nbsp; &nbsp; &nbsp; R : ${res.data.data1st.pachymetryRight} microns`
                    }
                    ${
                      res.data.data1st.pachymetryLeft === null
                        ? ``
                        : `| L : ${res.data.data1st.pachymetryLeft} microns`
                    } <br>      
        `
         }


         ${
           res.data.data1st.posteriorEye === null &&
           res.data.data1st.posteriorEyeCheckbox === null &&
           res.data.data1st.posteriorEyeStartTime === null
             ? ``
             : `
            Posterior eye examination: <br>
                    ${
                      res.data.data1st.posteriorEye === null
                        ? ``
                        : `&nbsp; &nbsp; &nbsp;${res.data.data1st.posteriorEye}`
                    }
                    ${
                      res.data.data1st.posteriorEyeCheckbox === null
                        ? ``
                        : ` ${res.data.data1st.posteriorEyeCheckbox}`
                    }

                    ${
                      res.data.data1st.posteriorEyeStartTime === null
                        ? ``
                        : ` <br>&nbsp; &nbsp; &nbsp; Start time ${res.data.data1st.posteriorEyeStartTime}  | End time ${res.data.data1st.posteriorEyeEndTime}`
                    }
                    <br>
                  
        `
         }

        ${
          res.data.data1st.ancillaryTestInfo === null &&
          res.data.data1st.ancillaryTestImportExport === null
            ? ``
            : `
        Ancillary tests: <br>

                    ${
                      res.data.data1st.ancillaryTestInfo === null
                        ? ``
                        : `&nbsp; &nbsp; &nbsp; ${res.data.data1st.ancillaryTestInfo
                            .replaceAll(/","/gi, "<br>&nbsp; &nbsp; &nbsp;")
                            .replaceAll(/"|{|}/gi, "")} `
                    }
                    ${
                      res.data.data1st.ancillaryTestImportExport === null
                        ? ``
                        : ` <br>&nbsp; &nbsp; &nbsp; Import/Export: ${res.data.data1st.ancillaryTestImportExport.replaceAll(
                            ",",
                            " . "
                          )} `
                    }

        `
        }
      
   
       
  
    </p>`;
      const data = {
        appointment_id: props?.appId,
        patient_id: props?.patient_id,
        note: note,
      };
      axios.post("doctors-note", data).then((res) => console.log(res));
      props?.setUpdateForHistory(Math.random());

      toast.success(res.data.message);
    });
  };

  useEffect(() => {
    if (kRight1 !== "" && kRight2 !== "") {
      const newValue = parseFloat(kRight1) + parseFloat(kRight2);
      setKRight3(newValue.toFixed(2));
    }
  }, [kRight1, kRight2]);
  useEffect(() => {
    if (kRightD1 !== "" && kRightD2 !== "") {
      const newValue = (parseFloat(kRightD1) + parseFloat(kRightD2)) / 2;
      setKRightD3(newValue.toFixed(2));
    }
  }, [kRightD1, kRightD2]);
  useEffect(() => {
    if (kLeft1 !== "" && kLeft2 !== "") {
      const newValue = parseFloat(kLeft1) + parseFloat(kLeft2);
      setKLeft3(newValue.toFixed(2));
    }
  }, [kLeft1, kLeft2]);
  useEffect(() => {
    if (kLeftD1 !== "" && kLeftD2 !== "") {
      const newValue = (parseFloat(kLeftD1) + parseFloat(kLeftD2)) / 2;
      setKLeftD3(newValue.toFixed(2));
    }
  }, [kLeftD1, kLeftD2]);

  const [eyeRx, setEyeRx] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const addRx = (e) => {
    e.preventDefault();
    let newAdvice = [...rxAdvice];
    let newRx = { ...eyeRx };
    let newRxName = `Rx Advice-${Object.keys(eyeRx).length + 1}`;
    if (newAdvice.length > 0) {
      setEyeRx({ ...eyeRx, [newRxName]: newAdvice });
    }
    setRxAdvice([]);
    e.target.reset();
    setIsChecked(false);
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(false);
    setIsChecked5(false);
    setIsChecked6(false);
    setIsChecked7(false);
    setIsChecked8(false);
  };
  const [eyeImage, setEyeImage] = useState([]);
  useEffect(() => {
    if (image) {
      let newImage = [...eyeImage];
      newImage.push(image);
      setEyeImage(newImage);
    }
  }, [image]);
  const removeEyeImage = (img) => {
    const filtered = eyeImage.filter((item) => item !== img);
    setEyeImage(filtered);
  };
  useEffect(() => {
    const currentTime = moment().format("HH:mm:ss");
    setIntraOcularPressureEndTime(currentTime);
  }, [intraOcularPressureStartTime]);

  const handleEomType = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setEomType([...eomType, value]);
    } else {
      const newArray = eomType.filter((item) => item !== value);
      setEomType(newArray);
    }
  };
  const pressureHandler = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setIntraOcularPressure([...intraOcularPressure, value]);
    } else {
      const newArray = intraOcularPressure.filter((item) => item !== value);
      setIntraOcularPressure(newArray);
    }
  };
  const handleValueFormat = (value) => {
    if (value) {
      if (value.startsWith(0) && value > 0 && !value.includes(".")) {
        const vv = value.slice(0, 1);
        return `+ ${vv}.${value.slice(1)}`;
      } else if (value.startsWith(0, 1) && value < 0 && !value.includes(".")) {
        const vv = value.slice(1, 2);
        return `-${vv}.${value.slice(2)}`;
      } else if (value.startsWith(0) && value > 0 && value.includes(".")) {
      } else if (value.startsWith(0, 1) && value < 0 && value.includes(".")) {
        return `${value}`;
      } else if (value > 0 && value.length >= 1) {
        if (value.includes(".") && value.length >= 5) {
          return `+${value}`;
        } else if (value.includes(".") && value.length <= 4) {
          return `+${value}0`;
        } else {
          return `+${value}.00`;
        }
      } else if (value < 0 && value.length >= 2) {
        if (value.includes(".") && value.length >= 6) {
          return `${value}`;
        } else if (value.includes(".") && value.length <= 5) {
          return `${value}0`;
        } else {
          return `${value}.00`;
        }
      }
    }
  };
  //history Search

  const historySearch = (e) => {
    const { value } = e.target;
    if (value?.length > 0) {
      const existing = [...historySort];
      setHistoryArray(
        existing.filter((item) =>
          item?.history_name?.toLowerCase().includes(value?.toLowerCase())
        )
      );
    } else {
      setHistoryArray(historySort);
    }
  };
  //
  const [focus, setFocus] = useState("");
  console.log(spectaclesEighthRight, "spectaclesEighth");
  console.log(spectaclesEighthRightValue, "spectaclesEighthValue");
  return (
    <div className="row">
      <div className="history-main-header d-flex justify-content-between mb-2">
        <div>
          <h6>EYE examination</h6>
        </div>
        <div>
          <img src={clinic4} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="col-lg-2 col-3">
        <div className="he-history eye-history p-1">
          <input
            type="text"
            placeholder="History"
            onChange={historySearch}
            className="form-control form-control-sm mt-1 mb-2"
          />
          <ul
            className={`g-doc-scroll ${
              historyShowAll ? "full-height" : "half-height"
            }`}
          >
            {historyArray.map((item, i) => (
              <li key={i}>
                <div className="d-flex justify-content-between">
                  <p className="w-75">{item.history_name}</p>
                  <div className="ms-1">
                    <label className="switch me-1">
                      <input
                        name={item.id}
                        type="checkbox"
                        value={item.history_name}
                        onChange={(e) => {
                          const { value, checked } = e.target;

                          if (checked) {
                            setHistoryValue([...historyValue, value]);
                          } else {
                            const newHistory = historyValue.filter(
                              (item) => item !== value
                            );
                            setHistoryValue(newHistory);
                          }
                        }}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {!historyShowAll ? (
            <span
              onClick={() => setHistoryShowAll(!historyShowAll)}
              className="history-see-all"
            >
              Show All <i className="ms-1 far fa-angle-down"></i>
            </span>
          ) : (
            <span
              onClick={() => setHistoryShowAll(!historyShowAll)}
              className="history-see-all"
            >
              Show Less <i className="ms-1 far fa-angle-up"></i>
            </span>
          )}
        </div>
      </div>
      <div className="col-lg-10 col-9 cns-container">
        <div className="history-main-header mb-2">
          <div className="d-flex">
            <input
              type="text"
              placeholder="Examination search"
              className="form-control form-control-sm w-75"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  window.find(e.target.value);
                }
              }}
            />
            <div className="w-50">
              <button
                onClick={() => setsaveLoding(false)}
                className="vaital-setup-btn-cancel float-end"
              >
                Reset
              </button>

              {saveLoding ? (
                <button className="vaital-setup-btn float-end me-2">
                  <i className="fas fa-check-circle"></i>
                </button>
              ) : (
                <button
                  onClick={saveEyeData}
                  className="vaital-setup-btn float-end me-2"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        <ul className="nav nav-pills " id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-home-tab-eye"
              data-bs-toggle="pill"
              data-bs-target="#pills-home-eye"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Examination
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-profile-tab-eye"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile-eye"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Eye Diagram
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home-eye"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="row">
              <div className="col-4">
                <div className="exam-bg-white p-1">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">External Observation</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange(!change);
                            setExternalObservation({});
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change && (
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{ width: "460px" }}
                              className="left-popup"
                            >
                              {externalObservationArray.length > 0 &&
                                externalObservationArray.map((v, i) => {
                                  return (
                                    <div key={i} className="form-check ms-1">
                                      <label className="d-flex">
                                        <span style={{ width: "170px" }}>
                                          {v.ExternalObservation_name}
                                        </span>
                                        <input
                                          type="text"
                                          name={v.ExternalObservation_name}
                                          className="form-control form-control-sm w-75 ms-2"
                                          onChange={(e) => {
                                            if (e.target.value.length > 0) {
                                              setExternalObservation({
                                                ...externalObservation,
                                                [e.target
                                                  .name]: `${e.target.value}`,
                                              });
                                            } else {
                                              setExternalObservation({
                                                ...externalObservation,
                                                [e.target.name]: ``,
                                              });
                                            }
                                          }}
                                        />
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value">
                    {Object.keys(externalObservation).map((item, i) => (
                      <p key={i}>
                        {item} : {externalObservation[item]}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="exam-bg-white p-1 mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Spectacles</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange17(!change17);
                            setRxAdvice([]);
                            setTrailFramed(false);
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl17(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change17 && (
                          <Popover
                            id={id17}
                            open={open17}
                            anchorEl={anchorEl17}
                            onClose={() => setAnchorEl17(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div className="left-popup eye-spack-popup">
                              <div className="d-flex justify-content-end">
                                <button className="eye-spack-btn my-2 shadow">
                                  Retrieve Data from Auto-Refractor
                                </button>
                              </div>
                              <div className="d-flex">
                                <div className="me-1 eye-spack-table">
                                  <table ref={spectaclesRef} className="">
                                    <tr>
                                      <td>
                                        <span style={{ width: "30px" }}>
                                          Rx R
                                        </span>
                                      </td>
                                      <td>Sphere</td>
                                      <td>Cyl</td>
                                      <td>Axis</td>
                                      <td>VA</td>
                                      <td>Add</td>
                                      <td>Intem</td>
                                      <td>Prism</td>
                                      <td> </td>
                                      <td> </td>
                                      <td> </td>
                                      <td>
                                        <span style={{ width: "30px" }}>
                                          Rx R
                                        </span>
                                      </td>
                                      <td>Sphere</td>
                                      <td>Cyl</td>
                                      <td>Axis</td>
                                      <td>VA</td>
                                      <td>Add</td>
                                      <td>Intem</td>
                                      <td>Prism</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          value={spectaclesFirst.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFirst({
                                              ...spectaclesFirst,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFirstActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFirst.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirst.sphere !== "" &&
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFirstValue({
                                                ...spectaclesFirstValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstActive({
                                                ...spectaclesFirstActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFirstValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesFirst.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirst.cyl !== "" &&
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFirstValue({
                                                ...spectaclesFirstValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstActive({
                                                ...spectaclesFirstActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFirstValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          defaultValue={spectaclesFirst.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFirst({
                                              ...spectaclesFirst,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFirstActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesFirst.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirst.VA !== "" &&
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFirstValue({
                                                ...spectaclesFirstValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstActive({
                                                ...spectaclesFirstActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFirstValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesFirst.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirst.add !== "" &&
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFirstValue({
                                                ...spectaclesFirstValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstActive({
                                                ...spectaclesFirstActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFirstValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFirst.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirst.intern !== "" &&
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFirstValue({
                                                ...spectaclesFirstValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstActive({
                                                ...spectaclesFirstActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFirstValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFirstActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFirst.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFirst.prism !== "" &&
                                                  setSpectaclesFirstActive({
                                                    ...spectaclesFirstActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFirst({
                                                  ...spectaclesFirst,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFirstValue({
                                                  ...spectaclesFirstValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFirstActive({
                                                  ...spectaclesFirstActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFirstValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesFirst.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesFirst({
                                                ...spectaclesFirst,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT">OUT</option>
                                          </select>
                                        </div>
                                      </td>

                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFirst({
                                              ...spectaclesFirstRight,
                                            });
                                            setSpectaclesFirstValue({
                                              ...spectaclesFirstRightValue,
                                            });
                                            setSpectaclesFirstActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSecond({
                                              ...spectaclesFirst,
                                            });
                                            setSpectaclesSecondRight({
                                              ...spectaclesFirstRight,
                                            });
                                            setSpectaclesSecondValue({
                                              ...spectaclesFirstValue,
                                            });
                                            setSpectaclesSecondRightValue({
                                              ...spectaclesFirstRightValue,
                                            });
                                            setSpectaclesSecondActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesSecondRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFirstRight({
                                              ...spectaclesFirst,
                                            });
                                            setSpectaclesFirstRightValue({
                                              ...spectaclesFirstValue,
                                            });
                                            setSpectaclesFirstRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFirstRight.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFirstRight({
                                              ...spectaclesFirstRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFirstRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFirstRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirstRight.sphere !==
                                                "" &&
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFirstRightValue({
                                                ...spectaclesFirstRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstRightActive({
                                                ...spectaclesFirstRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFirstRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesFirstRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirstRight.cyl !== "" &&
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFirstRightValue({
                                                ...spectaclesFirstRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstRightActive({
                                                ...spectaclesFirstRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFirstRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFirstRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFirstRight({
                                              ...spectaclesFirstRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFirstRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesFirstRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirstRight.VA !== "" &&
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFirstRightValue({
                                                ...spectaclesFirstRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstRightActive({
                                                ...spectaclesFirstRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFirstRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesFirstRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirstRight.add !== "" &&
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFirstRightValue({
                                                ...spectaclesFirstRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstRightActive({
                                                ...spectaclesFirstRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFirstRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFirstRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFirstRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFirstRight.intern !==
                                                "" &&
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFirstRightValue({
                                                ...spectaclesFirstRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFirstRightActive({
                                                ...spectaclesFirstRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFirstRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFirstRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFirstRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFirstRight.prism !==
                                                  "" &&
                                                  setSpectaclesFirstRightActive(
                                                    {
                                                      ...spectaclesFirstRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFirstRight({
                                                  ...spectaclesFirstRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFirstRightValue({
                                                  ...spectaclesFirstRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFirstRightActive({
                                                  ...spectaclesFirstRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFirstRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesFirstRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesFirstRight({
                                                ...spectaclesFirstRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFirstRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFirstRight({
                                              ...spectaclesFirstRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => {
                                            suActivity(
                                              { ...spectaclesFirst },
                                              { ...spectaclesFirstRight },
                                              { ...spectaclesFirstValue },
                                              { ...spectaclesFirstRightValue }
                                            );
                                          }}
                                          className="me-1"
                                        >
                                          To Sub
                                        </button>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesFirst },
                                              { ...spectaclesFirstRight },
                                              { ...spectaclesFirstValue },
                                              { ...spectaclesFirstRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          value={spectaclesSecond.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSecond({
                                              ...spectaclesSecond,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>

                                      <td>
                                        {spectaclesSecondActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSecond.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecond.sphere !== "" &&
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSecondValue({
                                                ...spectaclesSecondValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondActive({
                                                ...spectaclesSecondActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSecondValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesSecond.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecond.cyl !== "" &&
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSecondValue({
                                                ...spectaclesSecondValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondActive({
                                                ...spectaclesSecondActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSecondValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSecond.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSecond({
                                              ...spectaclesSecond,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSecondActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesSecond.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecond.VA !== "" &&
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSecondValue({
                                                ...spectaclesSecondValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondActive({
                                                ...spectaclesSecondActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSecondValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesSecond.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecond.add !== "" &&
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSecondValue({
                                                ...spectaclesSecondValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondActive({
                                                ...spectaclesSecondActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSecondValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSecond.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecond.intern !== "" &&
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSecondValue({
                                                ...spectaclesSecondValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondActive({
                                                ...spectaclesSecondActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSecondValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSecondActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSecond.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSecond.prism !== "" &&
                                                  setSpectaclesSecondActive({
                                                    ...spectaclesSecondActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSecond({
                                                  ...spectaclesSecond,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSecondValue({
                                                  ...spectaclesSecondValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSecondActive({
                                                  ...spectaclesSecondActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesSecondValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesSecond.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesSecond({
                                                ...spectaclesSecond,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>

                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSecond({
                                              ...spectaclesSecondRight,
                                            });
                                            setSpectaclesSecondValue({
                                              ...spectaclesSecondRightValue,
                                            });
                                            setSpectaclesSecondActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesThird({
                                              ...spectaclesSecond,
                                            });
                                            setSpectaclesThirdRight({
                                              ...spectaclesSecondRight,
                                            });
                                            setSpectaclesThirdValue({
                                              ...spectaclesSecondValue,
                                            });
                                            setSpectaclesThirdRightValue({
                                              ...spectaclesSecondRightValue,
                                            });
                                            setSpectaclesThirdActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesThirdRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSecondRight({
                                              ...spectaclesSecond,
                                            });
                                            setSpectaclesSecondRightValue({
                                              ...spectaclesSecondValue,
                                            });
                                            setSpectaclesSecondRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSecondRight.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSecondRight({
                                              ...spectaclesSecondRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSecondRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSecondRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecondRight.sphere !==
                                                "" &&
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSecondRightValue({
                                                ...spectaclesSecondRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondRightActive({
                                                ...spectaclesSecondRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSecondRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesSecondRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecondRight.cyl !==
                                                "" &&
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSecondRightValue({
                                                ...spectaclesSecondRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondRightActive({
                                                ...spectaclesSecondRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSecondRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          defaultValue={
                                            spectaclesSecondRight.axis
                                          }
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSecondRight({
                                              ...spectaclesSecondRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSecondRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesSecondRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecondRight.VA !== "" &&
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSecondRightValue({
                                                ...spectaclesSecondRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondRightActive({
                                                ...spectaclesSecondRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSecondRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesSecondRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecondRight.add !==
                                                "" &&
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSecondRightValue({
                                                ...spectaclesSecondRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondRightActive({
                                                ...spectaclesSecondRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSecondRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSecondRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSecondRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSecondRight.intern !==
                                                "" &&
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSecondRightValue({
                                                ...spectaclesSecondRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSecondRightActive({
                                                ...spectaclesSecondRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSecondRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSecondRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSecondRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSecondRight.prism !==
                                                  "" &&
                                                  setSpectaclesSecondRightActive(
                                                    {
                                                      ...spectaclesSecondRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSecondRight({
                                                  ...spectaclesSecondRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSecondRightValue({
                                                  ...spectaclesSecondRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSecondRightActive({
                                                  ...spectaclesSecondRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesSecondRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesSecondRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesSecondRight({
                                                ...spectaclesSecondRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSecondRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSecondRight({
                                              ...spectaclesSecondRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => {
                                            suActivity(
                                              { ...spectaclesSecond },
                                              { ...spectaclesSecondRight },
                                              { ...spectaclesSecondValue },
                                              { ...spectaclesSecondRightValue }
                                            );
                                          }}
                                          className="me-1"
                                        >
                                          To Sub
                                        </button>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesSecond },
                                              { ...spectaclesSecondRight },
                                              { ...spectaclesSecondValue },
                                              { ...spectaclesSecondRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          value={spectaclesThird.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesThird({
                                              ...spectaclesThird,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesThirdActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesThird.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThird.sphere !== "" &&
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesThirdValue({
                                                ...spectaclesThirdValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdActive({
                                                ...spectaclesThirdActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesThirdValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesThird.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThird.cyl !== "" &&
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesThirdValue({
                                                ...spectaclesThirdValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdActive({
                                                ...spectaclesThirdActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesThirdValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesThird.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesThird({
                                              ...spectaclesThird,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesThirdActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesThird.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThird.VA !== "" &&
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesThirdValue({
                                                ...spectaclesThirdValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdActive({
                                                ...spectaclesThirdActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesThirdValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesThird.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThird.add !== "" &&
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                add: e.target.value,
                                              });
                                              setSpectaclesThirdValue({
                                                ...spectaclesThirdValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdActive({
                                                ...spectaclesThirdActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesThirdValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesThird.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThird.intern !== "" &&
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesThirdValue({
                                                ...spectaclesThirdValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdActive({
                                                ...spectaclesThirdActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesThirdValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesThirdActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesThird.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesThird.prism !== "" &&
                                                  setSpectaclesThirdActive({
                                                    ...spectaclesThirdActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesThird({
                                                  ...spectaclesThird,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesThirdValue({
                                                  ...spectaclesThirdValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesThirdActive({
                                                  ...spectaclesThirdActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesThirdValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesThird.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesThird({
                                                ...spectaclesThird,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>

                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesThird({
                                              ...spectaclesThirdRight,
                                            });
                                            setSpectaclesThirdValue({
                                              ...spectaclesThirdRightValue,
                                            });
                                            setSpectaclesThirdActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFourth({
                                              ...spectaclesThird,
                                            });
                                            setSpectaclesFourthRight({
                                              ...spectaclesThirdRight,
                                            });
                                            setSpectaclesFourthValue({
                                              ...spectaclesThirdValue,
                                            });
                                            setSpectaclesFourthRightValue({
                                              ...spectaclesThirdRightValue,
                                            });
                                            setSpectaclesFourthActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesFourthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesThirdRight({
                                              ...spectaclesThird,
                                            });
                                            setSpectaclesThirdRightValue({
                                              ...spectaclesThirdValue,
                                            });
                                            setSpectaclesThirdRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesThirdRight.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesThirdRight({
                                              ...spectaclesThirdRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesThirdRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesThirdRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThirdRight.sphere !==
                                                "" &&
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesThirdRightValue({
                                                ...spectaclesThirdRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdRightActive({
                                                ...spectaclesThirdRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesThirdRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdRightActive.cly ? (
                                          <input
                                            autoFocus={focus === "cly"}
                                            defaultValue={
                                              spectaclesThirdRight.cly
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThirdRight.cly !== "" &&
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  cly: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                cly: e.target.value,
                                              });
                                              setSpectaclesThirdRightValue({
                                                ...spectaclesThirdRightValue,
                                                cly: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdRightActive({
                                                ...spectaclesThirdRightActive,
                                                cly: true,
                                              });
                                              setFocus("cly");
                                            }}
                                          >
                                            {spectaclesThirdRightValue.cly}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesThirdRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesThirdRight({
                                              ...spectaclesThirdRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesThirdRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesThirdRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThirdRight.VA !== "" &&
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesThirdRightValue({
                                                ...spectaclesThirdRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdRightActive({
                                                ...spectaclesThirdRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesThirdRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesThirdRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThirdRight.add !== "" &&
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesThirdRightValue({
                                                ...spectaclesThirdRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdRightActive({
                                                ...spectaclesThirdRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesThirdRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesThirdRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesThirdRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesThirdRight.intern !==
                                                "" &&
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesThirdRightValue({
                                                ...spectaclesThirdRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesThirdRightActive({
                                                ...spectaclesThirdRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesThirdRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesThirdRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesThirdRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesThirdRight.prism !==
                                                  "" &&
                                                  setSpectaclesThirdRightActive(
                                                    {
                                                      ...spectaclesThirdRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesThirdRight({
                                                  ...spectaclesThirdRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesThirdRightValue({
                                                  ...spectaclesThirdRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesThirdRightActive({
                                                  ...spectaclesThirdRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesThirdRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesThirdRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesThirdRight({
                                                ...spectaclesThirdRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesThirdRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesThirdRight({
                                              ...spectaclesThirdRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {/* <button onClick={() => { suActivity(); setSpectaclesSixth({ ...spectaclesThird }); setSpectaclesSixthRight({ ...spectaclesThirdRight }); setSU(spectaclesThird.sphere) }} className='me-1'> To Sub</button>
                                                                                <button onClick={() => { giActivity(); setGI(spectaclesThird.sphere); setSpectaclesSeventh({ ...spectaclesThird }); setSpectaclesSeventhRight({ ...spectaclesThirdRight }) }}> To Giv</button> */}
                                        <button
                                          onClick={() => {
                                            suActivity(
                                              { ...spectaclesThird },
                                              { ...spectaclesThirdRight },
                                              { ...spectaclesThirdValue },
                                              { ...spectaclesThirdRightValue }
                                            );
                                          }}
                                          className="me-1"
                                        >
                                          To Sub
                                        </button>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesThird },
                                              { ...spectaclesThirdRight },
                                              { ...spectaclesThirdValue },
                                              { ...spectaclesThirdRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          value={spectaclesFourth.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourth({
                                              ...spectaclesFourth,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFourthActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFourth.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourth.sphere !== "" &&
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFourthValue({
                                                ...spectaclesFourthValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthActive({
                                                ...spectaclesFourthActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFourthValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesFourth.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourth.cyl !== "" &&
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFourthValue({
                                                ...spectaclesFourthValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthActive({
                                                ...spectaclesFourthActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFourthValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFourth.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourth({
                                              ...spectaclesFourth,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFourthActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesFourth.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourth.VA !== "" &&
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFourthValue({
                                                ...spectaclesFourthValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthActive({
                                                ...spectaclesFourthActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFourthValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesFourth.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourth.add !== "" &&
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFourthValue({
                                                ...spectaclesFourthValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthActive({
                                                ...spectaclesFourthActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFourthValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFourth.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourth.intern !== "" &&
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFourthValue({
                                                ...spectaclesFourthValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthActive({
                                                ...spectaclesFourthActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFourthValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFourthActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFourth.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFourth.prism !== "" &&
                                                  setSpectaclesFourthActive({
                                                    ...spectaclesFourthActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFourth({
                                                  ...spectaclesFourth,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFourthValue({
                                                  ...spectaclesFourthValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFourthActive({
                                                  ...spectaclesFourthActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFourthValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesFourth.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesFourth({
                                                ...spectaclesFourth,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFourth({
                                              ...spectaclesFourthRight,
                                            });
                                            setSpectaclesFourthValue({
                                              ...spectaclesFourthRightValue,
                                            });
                                            setSpectaclesFourthActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFifth({
                                              ...spectaclesFourth,
                                            });
                                            setSpectaclesFifthRight({
                                              ...spectaclesFourthRight,
                                            });
                                            setSpectaclesFifthValue({
                                              ...spectaclesFourthValue,
                                            });
                                            setSpectaclesFifthRightValue({
                                              ...spectaclesFourthRightValue,
                                            });
                                            setSpectaclesFifthActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesFifthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFourthRight({
                                              ...spectaclesFourth,
                                            });
                                            setSpectaclesFourthRightValue({
                                              ...spectaclesFourthValue,
                                            });
                                            setSpectaclesFourthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFourthRight.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourthRight({
                                              ...spectaclesFourthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFourthRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFourthRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourthRight.sphere !==
                                                "" &&
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFourthRightValue({
                                                ...spectaclesFourthRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthRightActive({
                                                ...spectaclesFourthRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFourthRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesFourthRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourthRight.cyl !==
                                                "" &&
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFourthRightValue({
                                                ...spectaclesFourthRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthRightActive({
                                                ...spectaclesFourthRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFourthRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFourthRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourthRight({
                                              ...spectaclesFourthRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFourthRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesFourthRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourthRight.VA !== "" &&
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFourthRightValue({
                                                ...spectaclesFourthRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthRightActive({
                                                ...spectaclesFourthRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFourthRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesFourthRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourthRight.add !==
                                                "" &&
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFourthRightValue({
                                                ...spectaclesFourthRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthRightActive({
                                                ...spectaclesFourthRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFourthRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFourthRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFourthRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFourthRight.intern !==
                                                "" &&
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFourthRightValue({
                                                ...spectaclesFourthRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFourthRightActive({
                                                ...spectaclesFourthRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFourthRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFourthRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFourthRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFourthRight.prism !==
                                                  "" &&
                                                  setSpectaclesFourthRightActive(
                                                    {
                                                      ...spectaclesFourthRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFourthRight({
                                                  ...spectaclesFourthRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFourthRightValue({
                                                  ...spectaclesFourthRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFourthRightActive({
                                                  ...spectaclesFourthRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFourthRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesFourthRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesFourthRight({
                                                ...spectaclesFourthRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFourthRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourthRight({
                                              ...spectaclesFourthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {/* <button onClick={() => { suActivity(); setSpectaclesSixth({ ...spectaclesFourth }); setSpectaclesSixthRight({ ...spectaclesFourthRight }); setSU(spectaclesFourth.sphere) }} className='me-1'> To Sub</button>
                                                                                <button onClick={() => { giActivity(); setGI(spectaclesFourth.sphere); setSpectaclesSeventh({ ...spectaclesFourth }); setSpectaclesSeventhRight({ ...spectaclesFourthRight }) }}> To Giv</button> */}
                                        <button
                                          onClick={() => {
                                            suActivity(
                                              { ...spectaclesFourth },
                                              { ...spectaclesFourthRight },
                                              { ...spectaclesFourthValue },
                                              { ...spectaclesFourthRightValue }
                                            );
                                          }}
                                          className="me-1"
                                        >
                                          To Sub
                                        </button>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesFourth },
                                              { ...spectaclesFourthRight },
                                              { ...spectaclesFourthValue },
                                              { ...spectaclesFourthRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          value={spectaclesFifth.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFifth({
                                              ...spectaclesFifth,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFifthActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFifth.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifth.sphere !== "" &&
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFifthValue({
                                                ...spectaclesFifthValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthActive({
                                                ...spectaclesFifthActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFifthValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesFifth.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifth.cyl !== "" &&
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFifthValue({
                                                ...spectaclesFifthValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthActive({
                                                ...spectaclesFifthActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFifthValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFifth.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFifth({
                                              ...spectaclesFifth,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFifthActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesFifth.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifth.VA !== "" &&
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFifthValue({
                                                ...spectaclesFifthValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthActive({
                                                ...spectaclesFifthActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFifthValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesFifth.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifth.add !== "" &&
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFifthValue({
                                                ...spectaclesFifthValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthActive({
                                                ...spectaclesFifthActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFifthValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFifth.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifth.intern !== "" &&
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFifthValue({
                                                ...spectaclesFifthValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthActive({
                                                ...spectaclesFifthActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFifthValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFifthActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFifth.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFifth.prism !== "" &&
                                                  setSpectaclesFifthActive({
                                                    ...spectaclesFifthActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFifth({
                                                  ...spectaclesFifth,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFifthValue({
                                                  ...spectaclesFifthValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFifthActive({
                                                  ...spectaclesFifthActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFifthValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesFifth.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesFifth({
                                                ...spectaclesFifth,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFifth({
                                              ...spectaclesFifthRight,
                                            });
                                            setSpectaclesFifthValue({
                                              ...spectaclesFifthRightValue,
                                            });
                                            setSpectaclesFifthActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSixth({
                                              ...spectaclesFifth,
                                            });
                                            setSpectaclesSixthRight({
                                              ...spectaclesFifthRight,
                                            });
                                            setSpectaclesSixthValue({
                                              ...spectaclesFifthValue,
                                            });
                                            setSpectaclesSixthRightValue({
                                              ...spectaclesFifthRightValue,
                                            });
                                            setSpectaclesSixthActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesSixthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesFifthRight({
                                              ...spectaclesFifth,
                                            });
                                            setSpectaclesFifthRightValue({
                                              ...spectaclesFifthValue,
                                            });
                                            setSpectaclesFifthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFifthRight.title}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFifthRight({
                                              ...spectaclesFifthRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFifthRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesFifthRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifthRight.sphere !==
                                                "" &&
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesFifthRightValue({
                                                ...spectaclesFifthRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthRightActive({
                                                ...spectaclesFifthRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesFifthRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesFifthRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifthRight.cyl !== "" &&
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesFifthRightValue({
                                                ...spectaclesFifthRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthRightActive({
                                                ...spectaclesFifthRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesFifthRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFifthRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFifthRight({
                                              ...spectaclesFifthRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesFifthRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesFifthRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifthRight.VA !== "" &&
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesFifthRightValue({
                                                ...spectaclesFifthRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthRightActive({
                                                ...spectaclesFifthRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesFifthRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesFifthRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifthRight.add !== "" &&
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesFifthRightValue({
                                                ...spectaclesFifthRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthRightActive({
                                                ...spectaclesFifthRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesFifthRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesFifthRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesFifthRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesFifthRight.intern !==
                                                "" &&
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesFifthRightValue({
                                                ...spectaclesFifthRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesFifthRightActive({
                                                ...spectaclesFifthRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesFifthRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesFifthRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesFifthRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesFifthRight.prism !==
                                                  "" &&
                                                  setSpectaclesFifthRightActive(
                                                    {
                                                      ...spectaclesFifthRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesFifthRight({
                                                  ...spectaclesFifthRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesFifthRightValue({
                                                  ...spectaclesFifthRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesFifthRightActive({
                                                  ...spectaclesFifthRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesFifthRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesFifthRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesFifthRight({
                                                ...spectaclesFifthRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesFifthRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesFourthRight({
                                              ...spectaclesFifthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => {
                                            suActivity(
                                              { ...spectaclesFifth },
                                              { ...spectaclesFifthRight },
                                              { ...spectaclesFifthValue },
                                              { ...spectaclesFifthRightValue }
                                            );
                                          }}
                                          className="me-1"
                                        >
                                          To Sub
                                        </button>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesFifth },
                                              { ...spectaclesFifthRight },
                                              { ...spectaclesFifthValue },
                                              { ...spectaclesFifthRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sub</td>
                                      <td>
                                        {spectaclesSixthActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSixth.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixth.sphere !== "" &&
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSixthValue({
                                                ...spectaclesSixthValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthActive({
                                                ...spectaclesSixthActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSixthValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesSixth.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixth.cyl !== "" &&
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSixthValue({
                                                ...spectaclesSixthValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthActive({
                                                ...spectaclesSixthActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSixthValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          defaultValue={spectaclesSixth.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSixth({
                                              ...spectaclesSixth,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSixthActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesSixth.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixth.VA !== "" &&
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSixthValue({
                                                ...spectaclesSixthValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthActive({
                                                ...spectaclesSixthActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSixthValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesSixth.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixth.add !== "" &&
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSixthValue({
                                                ...spectaclesSixthValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthActive({
                                                ...spectaclesSixthActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSixthValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSixth.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixth.intern !== "" &&
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSixthValue({
                                                ...spectaclesSixthValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthActive({
                                                ...spectaclesSixthActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSixthValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSixthActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSixth.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSixth.prism !== "" &&
                                                  setSpectaclesSixthActive({
                                                    ...spectaclesSixthActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSixth({
                                                  ...spectaclesSixth,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSixthValue({
                                                  ...spectaclesSixthValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSixthActive({
                                                  ...spectaclesSixthActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesSixthValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesSixth.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesSixth({
                                                ...spectaclesSixth,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSixth({
                                              ...spectaclesSixthRight,
                                            });
                                            setSpectaclesSixthValue({
                                              ...spectaclesSixthRightValue,
                                            });
                                            setSpectaclesSixthActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSeventh({
                                              ...spectaclesSixth,
                                            });
                                            setSpectaclesSixthRight({
                                              ...spectaclesSixthRight,
                                            });
                                            setSpectaclesSeventhValue({
                                              ...spectaclesSixthValue,
                                            });
                                            setSpectaclesSixthRightValue({
                                              ...spectaclesSixthRightValue,
                                            });
                                            setSpectaclesSeventhActive(
                                              spectaclesActivity
                                            );
                                            setSpectaclesSixthRightActive(
                                              spectaclesActivity
                                            );

                                            setSpectaclesEighth({
                                              ...spectaclesEighth,
                                              sphere:
                                                parseFloat(
                                                  spectaclesSixth?.sphere
                                                ) +
                                                parseFloat(
                                                  spectaclesSixth?.add
                                                ),
                                              cyl: spectaclesSixth?.cyl,
                                              axis: spectaclesSixth?.axis,
                                            });
                                            let val =
                                              parseFloat(
                                                spectaclesSixth?.sphere || 0
                                              ) +
                                              parseFloat(
                                                spectaclesSixth?.add || 0
                                              );
                                            let stringVal = val.toString();
                                            setSpectaclesEighthValue({
                                              ...spectaclesEighthValue,
                                              sphere:
                                                handleValueFormat(stringVal),
                                              cyl: spectaclesSixthValue?.cyl,
                                              axis: spectaclesSixthValue?.axis,
                                            });
                                            setSpectaclesEighthRight({
                                              ...spectaclesSixthRight,
                                              sphere:
                                                spectaclesSixthRight?.sphere,
                                              cyl: spectaclesSixthRight?.cyl,
                                              axis: spectaclesSixthRight?.axis,
                                            });
                                            let valRight =
                                              parseFloat(
                                                spectaclesSixthRight?.sphere ||
                                                  0
                                              ) +
                                              parseFloat(
                                                spectaclesSixthRight?.add || 0
                                              );
                                            let stringValRight =
                                              valRight.toString();
                                            setSpectaclesEighthRightValue({
                                              ...spectaclesEighthRightValue,
                                              sphere:
                                                handleValueFormat(
                                                  stringValRight
                                                ),
                                              cyl: spectaclesSixthRightValue?.cyl,
                                              axis: spectaclesSixthRightValue?.axis,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSixthRight({
                                              ...spectaclesSixth,
                                            });
                                            setSpectaclesSixthRightValue({
                                              ...spectaclesSixthValue,
                                            });
                                            setSpectaclesSixthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSixthRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSixthRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixthRight.sphere !==
                                                "" &&
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSixthRightValue({
                                                ...spectaclesSixthRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthRightActive({
                                                ...spectaclesSixthRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSixthRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesSixthRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixthRight.cyl !== "" &&
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSixthRightValue({
                                                ...spectaclesSixthRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthRightActive({
                                                ...spectaclesSixthRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSixthRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSixthRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSixthRight({
                                              ...spectaclesSixthRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSixthRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesSixthRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixthRight.VA !== "" &&
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSixthRightValue({
                                                ...spectaclesSixthRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthRightActive({
                                                ...spectaclesSixthRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSixthRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesSixthRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixthRight.add !== "" &&
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSixthRightValue({
                                                ...spectaclesSixthRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthRightActive({
                                                ...spectaclesSixthRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSixthRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSixthRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSixthRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSixthRight.intern !==
                                                "" &&
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSixthRightValue({
                                                ...spectaclesSixthRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSixthRightActive({
                                                ...spectaclesSixthRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSixthRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSixthRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSixthRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSixthRight.prism !==
                                                  "" &&
                                                  setSpectaclesSixthRightActive(
                                                    {
                                                      ...spectaclesSixthRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSixthRight({
                                                  ...spectaclesSixthRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSixthRightValue({
                                                  ...spectaclesSixthRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSixthRightActive({
                                                  ...spectaclesSixthRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesSixthRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesSixthRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesSixthRight({
                                                ...spectaclesSixthRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSixthRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSixthRight({
                                              ...spectaclesSixthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => {
                                            giActivity(
                                              { ...spectaclesSixth },
                                              { ...spectaclesSixthRight },
                                              { ...spectaclesSixthValue },
                                              { ...spectaclesSixthRightValue }
                                            );
                                          }}
                                        >
                                          To Giv
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dist</td>
                                      <td>
                                        {spectaclesSeventhActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSeventh.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                sphere: false,
                                              });
                                              spectaclesSeventh.sphere !== "" &&
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSeventhValue({
                                                ...spectaclesSeventhValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              let val =
                                                parseFloat(e.target.value) +
                                                parseFloat(
                                                  spectaclesSeventh?.add || 0
                                                );
                                              let stringVal = val?.toString();
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                sphere: stringVal,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                sphere:
                                                  handleValueFormat(stringVal),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhActive({
                                                ...spectaclesSeventhActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSeventhValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesSeventh.cyl}
                                            type="text"
                                            onBlur={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                cyl: false,
                                              });
                                              spectaclesSeventh.cyl !== "" &&
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSeventhValue({
                                                ...spectaclesSeventhValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhActive({
                                                ...spectaclesSeventhActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSeventhValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSeventh.axis}
                                          type="text"
                                          onChange={(e) => {
                                            setSpectaclesSeventh({
                                              ...spectaclesSeventh,
                                              axis: e.target.value,
                                            });
                                            setSpectaclesEighth({
                                              ...spectaclesEighth,
                                              axis: e.target.value,
                                            });
                                          }}
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSeventhActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesSeventh.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventh.VA !== "" &&
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSeventhValue({
                                                ...spectaclesSeventhValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhActive({
                                                ...spectaclesSeventhActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSeventhValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesSeventh.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventh.add !== "" &&
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSeventhValue({
                                                ...spectaclesSeventhValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              let val =
                                                parseFloat(e.target.value) +
                                                parseFloat(
                                                  spectaclesSeventh?.sphere || 0
                                                );
                                              let stringVal = val?.toString();
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                sphere: stringVal,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                sphere:
                                                  handleValueFormat(stringVal),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhActive({
                                                ...spectaclesSeventhActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSeventhValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSeventh.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventh.intern !== "" &&
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSeventhValue({
                                                ...spectaclesSeventhValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhActive({
                                                ...spectaclesSeventhActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSeventhValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSeventhActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSeventh.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSeventh.prism !==
                                                  "" &&
                                                  setSpectaclesSeventhActive({
                                                    ...spectaclesSeventhActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSeventh({
                                                  ...spectaclesSeventh,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSeventhValue({
                                                  ...spectaclesSeventhValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSeventhActive({
                                                  ...spectaclesSeventhActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesSeventhValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesSeventh.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesSeventh({
                                                ...spectaclesSeventh,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSeventh({
                                              ...spectaclesSeventhRight,
                                            });
                                            setSpectaclesSeventhValue({
                                              ...spectaclesSeventhRightValue,
                                            });
                                            setSpectaclesSeventhActive(
                                              spectaclesActivity
                                            );
                                            let val =
                                              parseFloat(
                                                spectaclesSeventhRight?.add || 0
                                              ) +
                                              parseFloat(
                                                spectaclesSeventhRight?.sphere ||
                                                  0
                                              );
                                            let stringVal = val?.toString();
                                            setSpectaclesEighth({
                                              ...spectaclesEighth,
                                              sphere: stringVal,
                                              cyl: spectaclesSeventh?.cyl,
                                              axis: spectaclesSeventh?.axis,
                                            });
                                            setSpectaclesEighthValue({
                                              ...spectaclesEighthValue,
                                              sphere:
                                                handleValueFormat(stringVal),
                                              cyl: spectaclesSeventhValue?.cyl,
                                              axis: spectaclesSeventhValue?.axis,
                                            });
                                            setSpectaclesEighthActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        {/* <ArrowCircleDownOutlinedIcon onClick={() => { setSpectaclesSixth({ ...spectaclesFifth }); setSpectaclesFifthRight({ ...spectaclesFourthRight }) }} /> */}
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesSeventhRight({
                                              ...spectaclesSeventh,
                                            });
                                            setSpectaclesSeventhRightValue({
                                              ...spectaclesSeventhValue,
                                            });
                                            setSpectaclesSeventhRightActive(
                                              spectaclesActivity
                                            );

                                            let val =
                                              parseFloat(
                                                spectaclesSeventh?.add || 0
                                              ) +
                                              parseFloat(
                                                spectaclesSeventh?.sphere || 0
                                              );
                                            let stringVal = val?.toString();
                                            setSpectaclesEighthRight({
                                              ...spectaclesEighthRight,
                                              sphere: stringVal,
                                              cyl: spectaclesSeventhRight?.cyl,
                                              axis: spectaclesSeventhRight?.axis,
                                            });
                                            setSpectaclesEighthRightValue({
                                              ...spectaclesEighthRightValue,
                                              sphere:
                                                handleValueFormat(stringVal),
                                              cyl: spectaclesSeventhRightValue?.cyl,
                                              axis: spectaclesSeventhRightValue?.axis,
                                            });
                                            setSpectaclesEighthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSeventhRight({
                                              ...spectaclesSeventhRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSeventhRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesSeventhRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventhRight.sphere !==
                                                "" &&
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    sphere: false,
                                                  }
                                                );
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                sphere: false,
                                              });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesSeventhRightValue({
                                                ...spectaclesSeventhRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              let val =
                                                parseFloat(e.target.value) +
                                                parseFloat(
                                                  spectaclesSeventhRight?.add ||
                                                    0
                                                );
                                              let stringVal = val?.toString();
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                sphere: stringVal,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                sphere:
                                                  handleValueFormat(stringVal),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhRightActive({
                                                ...spectaclesSeventhRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesSeventhRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesSeventhRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventhRight.cyl !==
                                                "" &&
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    cyl: false,
                                                  }
                                                );
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                cyl: false,
                                              });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesSeventhRightValue({
                                                ...spectaclesSeventhRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhRightActive({
                                                ...spectaclesSeventhRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesSeventhRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSeventhRight.axis}
                                          type="text"
                                          onChange={(e) => {
                                            setSpectaclesSeventhRight({
                                              ...spectaclesSeventhRight,
                                              axis: e.target.value,
                                            });
                                            setSpectaclesEighthRight({
                                              ...spectaclesEighthRight,
                                              axis: e.target.value,
                                            });
                                          }}
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesSeventhRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesSeventhRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventhRight.VA !==
                                                "" &&
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    VA: false,
                                                  }
                                                );
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesSeventhRightValue({
                                                ...spectaclesSeventhRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhRightActive({
                                                ...spectaclesSeventhRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesSeventhRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesSeventhRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventhRight.add !==
                                                "" &&
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    add: false,
                                                  }
                                                );
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesSeventhRightValue({
                                                ...spectaclesSeventhRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                              let val =
                                                parseFloat(e.target.value) +
                                                parseFloat(
                                                  spectaclesSeventhRight?.sphere ||
                                                    0
                                                );
                                              let stringVal = val?.toString();
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                sphere: stringVal,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                sphere:
                                                  handleValueFormat(stringVal),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhRightActive({
                                                ...spectaclesSeventhRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesSeventhRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesSeventhRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesSeventhRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesSeventhRight.intern !==
                                                "" &&
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    intern: false,
                                                  }
                                                );
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesSeventhRightValue({
                                                ...spectaclesSeventhRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesSeventhRightActive({
                                                ...spectaclesSeventhRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesSeventhRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesSeventhRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesSeventhRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesSeventhRight.prism !==
                                                  "" &&
                                                  setSpectaclesSeventhRightActive(
                                                    {
                                                      ...spectaclesSeventhRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesSeventhRight({
                                                  ...spectaclesSeventhRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesSeventhRightValue({
                                                  ...spectaclesSeventhRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesSeventhRightActive(
                                                  {
                                                    ...spectaclesSeventhRightActive,
                                                    prism: true,
                                                  }
                                                );
                                                setFocus("prism");
                                              }}
                                            >
                                              {
                                                spectaclesSeventhRightValue.prism
                                              }
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesSeventhRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesSeventhRight({
                                                ...spectaclesSeventhRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesSeventhRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesSeventhRight({
                                              ...spectaclesSixthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                    {/* near row  */}
                                    <tr>
                                      <td>Near</td>
                                      <td>
                                        {spectaclesEighthActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesEighth.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighth.sphere !== "" &&
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesEighthValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={spectaclesEighth.cyl}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighth.cyl !== "" &&
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesEighthValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesEighth.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesEighth({
                                              ...spectaclesEighth,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesEighthActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={spectaclesEighth.VA}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighth.VA !== "" &&
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesEighthValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={spectaclesEighth.add}
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighth.add !== "" &&
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                add: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesEighthValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesEighth.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighth.intern !== "" &&
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesEighthValue({
                                                ...spectaclesEighthValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthActive({
                                                ...spectaclesEighthActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesEighthValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesEighthActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesEighth.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesEighth.prism !== "" &&
                                                  setSpectaclesEighthActive({
                                                    ...spectaclesEighthActive,
                                                    prism: false,
                                                  });
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesEighth({
                                                  ...spectaclesEighth,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesEighthValue({
                                                  ...spectaclesEighthValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesEighthActive({
                                                  ...spectaclesEighthActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesEighthValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={spectaclesEighth.prismUnit}
                                            onChange={(e) =>
                                              setSpectaclesEighth({
                                                ...spectaclesEighth,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleLeftOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesEighth({
                                              ...spectaclesEighthRight,
                                            });
                                            setSpectaclesEighthValue({
                                              ...spectaclesEighthRightValue,
                                            });
                                            setSpectaclesEighthActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        {/* <ArrowCircleDownOutlinedIcon onClick={() => { setSpectaclesSixth({ ...spectaclesFifth }); setSpectaclesFifthRight({ ...spectaclesFourthRight }) }} /> */}
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleRightOutlinedIcon
                                          onClick={() => {
                                            setSpectaclesEighthRight({
                                              ...spectaclesEighth,
                                            });
                                            setSpectaclesEighthRightValue({
                                              ...spectaclesEighthValue,
                                            });
                                            setSpectaclesEighthRightActive(
                                              spectaclesActivity
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesEighthRight({
                                              ...spectaclesEighthRight,
                                              title: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesEighthRightActive.sphere ? (
                                          <input
                                            autoFocus={focus === "sphere"}
                                            defaultValue={
                                              spectaclesEighthRight.sphere
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighthRight.sphere !==
                                                "" &&
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  sphere: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                sphere: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                sphere: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                sphere: true,
                                              });
                                              setFocus("sphere");
                                            }}
                                          >
                                            {spectaclesEighthRightValue.sphere}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthRightActive.cyl ? (
                                          <input
                                            autoFocus={focus === "cyl"}
                                            defaultValue={
                                              spectaclesEighthRight.cyl
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighthRight.cyl !==
                                                "" &&
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  cyl: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                cyl: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                cyl: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                cyl: true,
                                              });
                                              setFocus("cyl");
                                            }}
                                          >
                                            {spectaclesEighthRightValue.cyl}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesEighthRight.axis}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesEighthRight({
                                              ...spectaclesEighthRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td>
                                        {spectaclesEighthRightActive.VA ? (
                                          <input
                                            autoFocus={focus === "VA"}
                                            defaultValue={
                                              spectaclesEighthRight.VA
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighthRight.VA !== "" &&
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  VA: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                VA: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                VA: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                VA: true,
                                              });
                                              setFocus("VA");
                                            }}
                                          >
                                            {spectaclesEighthRightValue.VA}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthRightActive.add ? (
                                          <input
                                            autoFocus={focus === "add"}
                                            defaultValue={
                                              spectaclesEighthRight.add
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighthRight.add !==
                                                "" &&
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  add: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                add: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                add: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                add: true,
                                              });
                                              setFocus("add");
                                            }}
                                          >
                                            {spectaclesEighthRightValue.add}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {spectaclesEighthRightActive.intern ? (
                                          <input
                                            autoFocus={focus === "intern"}
                                            defaultValue={
                                              spectaclesEighthRight.intern
                                            }
                                            type="text"
                                            onBlur={() => {
                                              spectaclesEighthRight.intern !==
                                                "" &&
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  intern: false,
                                                });
                                            }}
                                            onChange={(e) => {
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                intern: e.target.value,
                                              });
                                              setSpectaclesEighthRightValue({
                                                ...spectaclesEighthRightValue,
                                                intern: handleValueFormat(
                                                  e.target.value
                                                ),
                                              });
                                            }}
                                            className="eye-input-box"
                                          />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setSpectaclesEighthRightActive({
                                                ...spectaclesEighthRightActive,
                                                intern: true,
                                              });
                                              setFocus("intern");
                                            }}
                                          >
                                            {spectaclesEighthRightValue.intern}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {spectaclesEighthRightActive.prism ? (
                                            <input
                                              autoFocus={focus === "prism"}
                                              defaultValue={
                                                spectaclesEighthRight.prism
                                              }
                                              type="text"
                                              onBlur={() => {
                                                spectaclesEighthRight.prism !==
                                                  "" &&
                                                  setSpectaclesEighthRightActive(
                                                    {
                                                      ...spectaclesEighthRightActive,
                                                      prism: false,
                                                    }
                                                  );
                                              }}
                                              onChange={(e) => {
                                                setSpectaclesEighthRight({
                                                  ...spectaclesEighthRight,
                                                  prism: e.target.value,
                                                });
                                                setSpectaclesEighthRightValue({
                                                  ...spectaclesEighthRightValue,
                                                  prism: handleValueFormat(
                                                    e.target.value
                                                  ),
                                                });
                                              }}
                                              className="eye-input-box"
                                            />
                                          ) : (
                                            <span
                                              style={{ minWidth: "40px" }}
                                              onClick={() => {
                                                setSpectaclesEighthRightActive({
                                                  ...spectaclesEighthRightActive,
                                                  prism: true,
                                                });
                                                setFocus("prism");
                                              }}
                                            >
                                              {spectaclesEighthRightValue.prism}
                                            </span>
                                          )}
                                          <select
                                            value={
                                              spectaclesEighthRight.prismUnit
                                            }
                                            onChange={(e) =>
                                              setSpectaclesEighthRight({
                                                ...spectaclesEighthRight,
                                                prismUnit: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="DN"> DN</option>
                                            <option value="UP"> UP</option>
                                            <option value="IN"> IN</option>
                                            <option value="OUT"> OUT</option>
                                          </select>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          value={spectaclesEighthRight.others}
                                          type="text"
                                          onChange={(e) =>
                                            setSpectaclesEighthRight({
                                              ...spectaclesSixthRight,
                                              others: e.target.value,
                                            })
                                          }
                                          className="eye-input-box"
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                              <div className="row mt-2 p-2">
                                <div className="col-4">
                                  <div className="border rounded row p-1">
                                    <div className="">
                                      <table className="table">
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td>Left</td>
                                            <td>Right</td>
                                          </tr>
                                          <tr>
                                            <td>Distant PD</td>
                                            <td>
                                              <input
                                                name="distantLeft"
                                                onChange={handlePdChange}
                                                type="text"
                                                className="form-control form-control-sm"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                name="distantRight"
                                                onChange={handlePdChange}
                                                type="text"
                                                className="form-control form-control-sm"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Near PD</td>
                                            <td>
                                              <input
                                                name="nearLeft"
                                                onChange={handlePdChange}
                                                type="text"
                                                className="form-control form-control-sm"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                name="nearRight"
                                                onChange={handlePdChange}
                                                type="text"
                                                className="form-control form-control-sm"
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="d-flex">
                                      <label>Trail Framed : </label>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          setTrailFramed(!trailFramed)
                                        }
                                        className="custom-radio"
                                      />
                                    </div>
                                  </div>
                                  {Object.keys(eyeRx).map((item, i) => (
                                    <div
                                      key={i}
                                      className="d-flex mt-1 align-items-center"
                                    >
                                      <h6 className="eye-rx-heading">
                                        Rx Advice - {i + 1} :
                                      </h6>
                                      <span className="ms-1">
                                        {eyeRx[item].toString()}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                                <div className="col-8 eye-rx-container">
                                  <div className="border rounded ms-1">
                                    <div className="border-bottom p-1">
                                      <h6>
                                        Rx Advice:
                                        {Object.keys(eyeRx).length + 1}
                                      </h6>
                                    </div>
                                    <div className="row p-1">
                                      <div className="col-2">
                                        <label htmlFor="">Lens</label>
                                      </div>
                                      <div className="col-10">
                                        <div className="d-flex">
                                          :
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="glass"
                                              id="glass"
                                              value="Glass"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="glass">Glass</label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="plastic"
                                              id="plastic"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              value="Plastic"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="plastic">
                                              Plastic
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="white"
                                              id="white"
                                              value="White"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="white">White</label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="tint"
                                              id="tint"
                                              value="Tint"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="tint">Tint</label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="photocromic"
                                              id="photocromic"
                                              value="Photocromic"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="photocromic">
                                              Photocromic
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="arCoat"
                                              id="arCoat"
                                              value="Ar Coat"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleLensChange}
                                            />
                                            <label htmlFor="arCoat">
                                              Ar Coat
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row p-1">
                                      <div className="col-2">
                                        <label htmlFor="">Multifocal</label>
                                      </div>
                                      <div className="col-10">
                                        <div className="d-flex">
                                          :
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="pal"
                                              id="pal"
                                              value="PAL"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleMultifocalChange}
                                            />
                                            <label htmlFor="pal">PAL</label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="kryptop"
                                              id="kryptop"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              value="Kryptop"
                                              onChange={handleMultifocalChange}
                                            />
                                            <label htmlFor="kryptop">
                                              Kryptop
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="executive"
                                              id="executive"
                                              value="Executive"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleMultifocalChange}
                                            />
                                            <label htmlFor="executive">
                                              Executive"
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="univis"
                                              id="univis"
                                              value="Univis-D"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleMultifocalChange}
                                            />
                                            <label htmlFor="univis">
                                              Univis-D
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row p-1">
                                      <div className="col-2">
                                        <label htmlFor="">Instruction</label>
                                      </div>
                                      <div className="col-10">
                                        <div className="d-flex">
                                          :
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="distanceOnly"
                                              id="distanceOnly"
                                              value="Distance Only"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleInstructionChange}
                                            />
                                            <label htmlFor="distanceOnly">
                                              Distance Only
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="nvOnly"
                                              id="nvOnly"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              value="NV Only"
                                              onChange={handleInstructionChange}
                                            />
                                            <label htmlFor="nvOnly">
                                              NV Only
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="constantWear"
                                              id="constantWear"
                                              value="Constant Wear"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleInstructionChange}
                                            />
                                            <label htmlFor="constantWear">
                                              Constant Wear
                                            </label>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center me-1">
                                            <input
                                              type="checkbox"
                                              name="vocationalUse"
                                              id="vocationalUse"
                                              value="Vocational Use"
                                              style={{ height: "16px" }}
                                              className="mt-1"
                                              onChange={handleInstructionChange}
                                            />
                                            <label htmlFor="vocationalUse">
                                              Vocational Use
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end rx-one-button-group mt-2">
                                <button
                                  onClick={() => setAnchorEl17(null)}
                                  className="btn me-2"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => {
                                    setAnchorEl17(null);
                                    takeSpectaclesScrenShot(
                                      spectaclesRef.current
                                    );
                                  }}
                                  className="btn"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value d-flex">
                    <p className="ms-1">
                      {trailFramed && ` Trail Framed : ${trailFramed} | `}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="exam-bg-white p-1">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Anterior Eye Examination</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setAnteriorEyeExamination(!anteriorEyeExamination);
                          }}
                          id="togBtn"
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="exam-bg-white p-1 mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Contact Lenses</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange18(!change18);
                            setContractLens({
                              ...contractLens,
                              left: "",
                              right: "",
                            });
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl18(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change18 && (
                          <Popover
                            id={id18}
                            open={open18}
                            anchorEl={anchorEl18}
                            onClose={() => setAnchorEl18(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{ width: "650px" }}
                              className="left-popup "
                            >
                              <div ref={ContactLenseslesRef}>
                                <div className="eye-lens-popup">
                                  <table>
                                    <tr>
                                      <td>Consultation Date</td>
                                      <td>Due Date</td>
                                      <td>Prescriber</td>
                                      <td>Dispenser</td>
                                      <td>Inv No.</td>
                                      <td>Warranty Expire</td>
                                      <td>Right</td>
                                      <td>Left</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="date"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              consultationDate: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="date"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              dueDate: e.target.value,
                                            })
                                          }
                                          className="form-control "
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              prescriber: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              dispenser: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              invNo: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              warrantyExpire: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              right: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          onChange={(e) =>
                                            setContractLens({
                                              ...contractLens,
                                              left: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                                <div className="eye-lens-popup mt-2">
                                  <table>
                                    <tr>
                                      <td></td>
                                      <td>CL Code</td>
                                      <td>Sup</td>
                                      <td>BCOR</td>
                                      <td>Sphere</td>
                                      <td>Cyl</td>
                                      <td>Axis</td>
                                      <td>Add</td>
                                      <td>BCOD</td>
                                      <td>Perip</td>
                                      <td></td>
                                      <td>Order Status</td>
                                      <td>Qty.</td>
                                      <td>Price</td>
                                    </tr>
                                    <tr>
                                      <td>Right</td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.cLCode}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              cLCode: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.sup}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              sup: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.BCOR}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              BCOR: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.OAD}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              OAD: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.sphere}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              sphere: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.cyl}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              cyl: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.axis}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.add}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              add: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.BCOD}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              BCOD: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleDownOutlinedIcon
                                          onClick={() =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoRight,
                                            })
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={
                                            contractLensInfoRight.orderStatus
                                          }
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              orderStatus: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.qty}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              qty: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoRight.price}
                                          onChange={(e) =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoRight,
                                              price: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Left</td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.cLCode}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              cLCode: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.sup}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              sup: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.BCOR}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              BCOR: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.OAD}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              OAD: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.sphere}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              sphere: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.cyl}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              cyl: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.axis}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              axis: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.add}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              add: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.BCOD}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              BCOD: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td className="eye-spac-table-icon">
                                        <ArrowCircleUpOutlinedIcon
                                          onClick={() =>
                                            setContractLensInfoRight({
                                              ...contractLensInfoLeft,
                                            })
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={
                                            contractLensInfoLeft.orderStatus
                                          }
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              orderStatus: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.qty}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              qty: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={contractLensInfoLeft.price}
                                          onChange={(e) =>
                                            setContractLensInfoLeft({
                                              ...contractLensInfoLeft,
                                              price: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                                <div className="mt-2">
                                  <h6>Notes:</h6>
                                  <div className="border rounded p-2">
                                    <div className="row mb-1">
                                      <div className="col-4">
                                        <label>Tint: </label>
                                      </div>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-1">
                                      <div className="col-4">
                                        <label>Patient instruction: </label>
                                      </div>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-1">
                                      <div className="col-4">
                                        <label>Lab instruction: </label>
                                      </div>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-4">
                                        <label>Others: </label>
                                      </div>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="rx-one-button-group d-flex justify-content-end">
                                <button
                                  onClick={() => {
                                    setAnchorEl18(null);
                                    takeContactLensesScrenShot(
                                      ContactLenseslesRef.current
                                    );
                                  }}
                                  className="btn"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value d-flex">
                    <p className="ms-1">
                      {contractLens.right && ` R : ${contractLens.right} | `}
                      {contractLens.left && ` L : ${contractLens.left} `}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="exam-bg-white p-1">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Posterior eye examination</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange16(!change16);
                            setPosteriorEyeCheckbox([]);
                            setPosteriorEye([]);
                            setPosteriorEyeEndTime("");
                            setPosteriorEyeStartTime("");
                            setPosteriorEyeComment("");
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl16(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change16 && (
                          <Popover
                            id={id16}
                            open={open16}
                            anchorEl={anchorEl16}
                            onClose={() => setAnchorEl16(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <div
                              style={{ width: "550px" }}
                              className="left-popup eye-check-popup"
                            >
                              <div className="row">
                                <div className="col-4">
                                  <div className="border">
                                    <div className="d-flex align-items-start">
                                      <input
                                        className="custom-radio mt-1"
                                        value="BIO (Binocular Indirect Ophthalmoscopy)"
                                        type="checkbox"
                                        name="cough2"
                                        onChange={(e) => {
                                          handlePosterior(e);
                                        }}
                                        id="flexRadioDefault1"
                                      />
                                      <label className="">
                                        BIO (Binocular Indirect Ophthalmoscopy)
                                      </label>
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        className="custom-radio"
                                        value="Fundoscopy"
                                        type="checkbox"
                                        name="cough2"
                                        onChange={(e) => {
                                          handlePosterior(e);
                                        }}
                                        id="flexRadioDefault1"
                                      />
                                      <label className="">Fundoscopy</label>
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        className="custom-radio"
                                        value="Undilated"
                                        type="checkbox"
                                        name="cough2"
                                        onChange={(e) => {
                                          handlePosterior(e);
                                        }}
                                        id="flexRadioDefault1"
                                      />
                                      <label className="">Undiluted</label>
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        className="custom-radio"
                                        value="Dilated"
                                        type="checkbox"
                                        name="cough2"
                                        onChange={(e) => {
                                          handlePosterior(e);
                                          setPosteriorDilation(
                                            !posteriorDilation
                                          );
                                        }}
                                        id="flexRadioDefault1"
                                      />
                                      <label className="">Dilated</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8">
                                  <div className="row p-1 border">
                                    <div className="col-6 border-end row">
                                      <span className="">
                                        Start Time :
                                        <input
                                          type="time"
                                          onChange={(e) =>
                                            setPosteriorEyeStartTime(
                                              e.target.value
                                            )
                                          }
                                          className="w-"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <span className="ms-2">
                                        End Time :
                                        <input
                                          type="time"
                                          defaultValue={posteriorEyeEndTime}
                                          onChange={(e) =>
                                            setPosteriorEyeEndTime(
                                              e.target.value
                                            )
                                          }
                                          className="w-"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  {posteriorDilation && (
                                    <div className="mt-1 border row">
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`Cyclopentolate : 1%`}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;

                                              if (checked) {
                                                setPosteriorEyeCheckbox([
                                                  ...posteriorEyeCheckbox,
                                                  value,
                                                ]);
                                              } else {
                                                const newCn =
                                                  posteriorEyeCheckbox.filter(
                                                    (item) => item !== value
                                                  );
                                                setPosteriorEyeCheckbox(newCn);
                                              }
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            Cyclopentolate 1%
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`Tropicamide: 1%`}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;

                                              if (checked) {
                                                setPosteriorEyeCheckbox([
                                                  ...posteriorEyeCheckbox,
                                                  value,
                                                ]);
                                              } else {
                                                const newCn =
                                                  posteriorEyeCheckbox.filter(
                                                    (item) => item !== value
                                                  );
                                                setPosteriorEyeCheckbox(newCn);
                                              }
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            Tropicamide 1%
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`Tropicamide : 0.5%`}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;

                                              if (checked) {
                                                setPosteriorEyeCheckbox([
                                                  ...posteriorEyeCheckbox,
                                                  value,
                                                ]);
                                              } else {
                                                const newCn =
                                                  posteriorEyeCheckbox.filter(
                                                    (item) => item !== value
                                                  );
                                                setPosteriorEyeCheckbox(newCn);
                                              }
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            Tropicamide 0.5%
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`Phenylephrine: 2.5%`}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;

                                              if (checked) {
                                                setPosteriorEyeCheckbox([
                                                  ...posteriorEyeCheckbox,
                                                  value,
                                                ]);
                                              } else {
                                                const newCn =
                                                  posteriorEyeCheckbox.filter(
                                                    (item) => item !== value
                                                  );
                                                setPosteriorEyeCheckbox(newCn);
                                              }
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            Phenylephrine 2.5%
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={`Phenylephrine : 10%`}
                                            onChange={(e) => {
                                              const { value, checked } =
                                                e.target;

                                              if (checked) {
                                                setPosteriorEyeCheckbox([
                                                  ...posteriorEyeCheckbox,
                                                  value,
                                                ]);
                                              } else {
                                                const newCn =
                                                  posteriorEyeCheckbox.filter(
                                                    (item) => item !== value
                                                  );
                                                setPosteriorEyeCheckbox(newCn);
                                              }
                                            }}
                                            id="flexCheckDefault"
                                          />
                                          <label
                                            className="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            Phenylephrine 10%
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="border mt-1 pb-1 row">
                                    <div className="col-6 text-center">
                                      <div className="text-center">
                                        <span className="mt-1 mb-2">Right</span>
                                      </div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                      >
                                        {/* part1 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeRight.part1 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeRight(
                                              posteriorEyeShapeRight.part1
                                                ? {
                                                    ...posteriorEyeShapeRight,
                                                    part1: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeRight,
                                                    part1: "part1",
                                                  }
                                            )
                                          }
                                          d="m 25.379164,41.439909 a 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.567421,14.56478 0 0 1 3.613403,-9.650589 L 11.003793,12.452584 A 39.832793,39.82557 0 0 0 -1.5761401e-5,39.996233 c 0,0.483596 0,0.967192 0,1.422341 A 39.832793,39.82557 0 0 0 12.447732,68.983558 L 30.365376,51.069163 a 14.58876,14.586115 0 0 1 -4.986212,-9.629254 z"
                                        />
                                        {/* part2 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeRight.part2 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeRight(
                                              posteriorEyeShapeRight.part2
                                                ? {
                                                    ...posteriorEyeShapeRight,
                                                    part2: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeRight,
                                                    part2: "part2",
                                                  }
                                            )
                                          }
                                          d="M 40.010602,-1.8261322e-5 A 39.832793,39.82557 0 0 0 11.018019,12.452584 l 17.917644,17.914395 a 14.652777,14.65012 0 0 1 22.149878,0 L 68.996072,12.445472 A 39.889697,39.882464 0 0 0 40.010602,-1.8261322e-5 Z"
                                        />
                                        {/* part3 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeRight.part3 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeRight(
                                              posteriorEyeShapeRight.part3
                                                ? {
                                                    ...posteriorEyeShapeRight,
                                                    part3: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeRight,
                                                    part3: "part3",
                                                  }
                                            )
                                          }
                                          d="M 68.996072,12.445472 51.078428,30.366979 a 14.567421,14.56478 0 0 1 3.613403,9.629254 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.58876,14.586115 0 0 1 -4.979099,9.629254 l 17.93187,17.93573 A 39.832793,39.82557 0 0 0 79.999881,41.439909 c 0,-0.476484 0,-0.96008 0,-1.422341 A 39.889697,39.882464 0 0 0 68.996072,12.445472 Z"
                                        />
                                        {/* part4 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeRight.part4 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeRight(
                                              posteriorEyeShapeRight.part4
                                                ? {
                                                    ...posteriorEyeShapeRight,
                                                    part4: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeRight,
                                                    part4: "part4",
                                                  }
                                            )
                                          }
                                          d="m 49.634489,51.069163 v 0 a 14.652777,14.65012 0 0 1 -19.254887,0 L 12.454845,68.983558 a 39.975053,39.967804 0 0 0 55.118627,0 z"
                                        />
                                        {/* part middle*/}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeRight.center !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeRight(
                                              posteriorEyeShapeRight.center
                                                ? {
                                                    ...posteriorEyeShapeRight,
                                                    center: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeRight,
                                                    center: "center",
                                                  }
                                            )
                                          }
                                          d="m 54.698944,39.996233 a 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.688342,14.685679 0 0 1 -29.234424,0 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.688342,14.685679 0 0 1 29.376684,0 z"
                                        />
                                      </svg>
                                    </div>
                                    <div className="col-6 text-center">
                                      <div className="text-center">
                                        <span className="mt-1 mb-2">Left</span>
                                      </div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                      >
                                        {/* part1 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeLeft.part1 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeLeft(
                                              posteriorEyeShapeLeft.part1
                                                ? {
                                                    ...posteriorEyeShapeLeft,
                                                    part1: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeLeft,
                                                    part1: "part1",
                                                  }
                                            )
                                          }
                                          d="m 25.379164,41.439909 a 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.567421,14.56478 0 0 1 3.613403,-9.650589 L 11.003793,12.452584 A 39.832793,39.82557 0 0 0 -1.5761401e-5,39.996233 c 0,0.483596 0,0.967192 0,1.422341 A 39.832793,39.82557 0 0 0 12.447732,68.983558 L 30.365376,51.069163 a 14.58876,14.586115 0 0 1 -4.986212,-9.629254 z"
                                        />
                                        {/* part2 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeLeft.part2 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeLeft(
                                              posteriorEyeShapeLeft.part2
                                                ? {
                                                    ...posteriorEyeShapeLeft,
                                                    part2: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeLeft,
                                                    part2: "part2",
                                                  }
                                            )
                                          }
                                          d="M 40.010602,-1.8261322e-5 A 39.832793,39.82557 0 0 0 11.018019,12.452584 l 17.917644,17.914395 a 14.652777,14.65012 0 0 1 22.149878,0 L 68.996072,12.445472 A 39.889697,39.882464 0 0 0 40.010602,-1.8261322e-5 Z"
                                        />
                                        {/* part3 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeLeft.part3 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeLeft(
                                              posteriorEyeShapeLeft.part3
                                                ? {
                                                    ...posteriorEyeShapeLeft,
                                                    part3: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeLeft,
                                                    part3: "part3",
                                                  }
                                            )
                                          }
                                          d="M 68.996072,12.445472 51.078428,30.366979 a 14.567421,14.56478 0 0 1 3.613403,9.629254 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.58876,14.586115 0 0 1 -4.979099,9.629254 l 17.93187,17.93573 A 39.832793,39.82557 0 0 0 79.999881,41.439909 c 0,-0.476484 0,-0.96008 0,-1.422341 A 39.889697,39.882464 0 0 0 68.996072,12.445472 Z"
                                        />
                                        {/* part4 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeLeft.part4 !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeLeft(
                                              posteriorEyeShapeLeft.part4
                                                ? {
                                                    ...posteriorEyeShapeLeft,
                                                    part4: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeLeft,
                                                    part4: "part4",
                                                  }
                                            )
                                          }
                                          d="m 49.634489,51.069163 v 0 a 14.652777,14.65012 0 0 1 -19.254887,0 L 12.454845,68.983558 a 39.975053,39.967804 0 0 0 55.118627,0 z"
                                        />
                                        {/* part middle*/}
                                        <path
                                          className={`eye-svg-bg ${
                                            posteriorEyeShapeLeft.center !==
                                              "" && "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPosteriorEyeShapeLeft(
                                              posteriorEyeShapeLeft.center
                                                ? {
                                                    ...posteriorEyeShapeLeft,
                                                    center: "",
                                                  }
                                                : {
                                                    ...posteriorEyeShapeLeft,
                                                    center: "center",
                                                  }
                                            )
                                          }
                                          d="m 54.698944,39.996233 a 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.688342,14.685679 0 0 1 -29.234424,0 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.688342,14.685679 0 0 1 29.376684,0 z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-floating mt-1">
                                  <textarea
                                    className="form-control"
                                    onChange={(e) =>
                                      setPosteriorEyeComment(e.target.value)
                                    }
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea"
                                  ></textarea>
                                  <label for="floatingTextarea">Comments</label>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value d-flex">
                    <p className="ms-1">
                      {posteriorEye.toString()}
                      {posteriorEyeCheckbox.map((item, i) => (
                        <span key={i}>{item} ,</span>
                      ))}
                      {posteriorEyeStartTime &&
                        `Start Time : ${posteriorEyeStartTime}`}
                      {posteriorEyeEndTime &&
                        `End Time : ${posteriorEyeEndTime}`}
                    </p>
                  </div>
                </div>
                <div className="exam-bg-white p-1 mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Ancillary Test</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange19(!change19);
                            setAncillaryTestImportExport([]);
                            setAncillaryTestInfo();
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl19(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change19 && (
                          <Popover
                            id={id19}
                            open={open19}
                            anchorEl={anchorEl19}
                            onClose={() => setAnchorEl19(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <div
                              style={{ width: "570px" }}
                              className="left-popup p-2"
                            >
                              <div className="row eye-ancilary-popup">
                                <div className="">
                                  <div className="row">
                                    <div className="col-4">
                                      <label>Color vision method </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Color Vision Method"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Result </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Result"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Topography </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Topography"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Stereopsis </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Stereopsis"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Maddox Wing </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Maddox Wing"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>B+/B- </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="B+/B-"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Fusional Reserves</label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Fusional Reserves"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>Vision training</label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="Vision Training"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col-4">
                                      <label>OCT </label>:
                                    </div>
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        name="OCT"
                                        onChange={(e) =>
                                          setAncillaryTestInfo({
                                            ...ancillaryTestInfo,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label style={{ width: "125px" }}>
                                    Import/Export
                                  </label>
                                  :
                                </div>
                                <div className="col-8">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="Import Images"
                                      onChange={(e) => {
                                        const { value, checked } = e.target;

                                        if (checked) {
                                          setAncillaryTestImportExport([
                                            ...ancillaryTestImportExport,
                                            value,
                                          ]);
                                        } else {
                                          const newCn =
                                            ancillaryTestImportExport.filter(
                                              (item) => item !== value
                                            );
                                          setAncillaryTestImportExport(newCn);
                                        }
                                      }}
                                      id="flexCheckDefault"
                                    />
                                    <label className="" for="flexCheckDefault">
                                      Import images
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="Retrieve Rx data from Auto-Refractor"
                                      onChange={(e) => {
                                        const { value, checked } = e.target;

                                        if (checked) {
                                          setAncillaryTestImportExport([
                                            ...ancillaryTestImportExport,
                                            value,
                                          ]);
                                        } else {
                                          const newCn =
                                            ancillaryTestImportExport.filter(
                                              (item) => item !== value
                                            );
                                          setAncillaryTestImportExport(newCn);
                                        }
                                      }}
                                      id="flexCheckDefault"
                                    />
                                    <label className="" for="flexCheckDefault">
                                      Retrieve Rx data from Auto-Refractor
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="Retrieve data from Phoropter To Sub"
                                      onChange={(e) => {
                                        const { value, checked } = e.target;

                                        if (checked) {
                                          setAncillaryTestImportExport([
                                            ...ancillaryTestImportExport,
                                            value,
                                          ]);
                                        } else {
                                          const newCn =
                                            ancillaryTestImportExport.filter(
                                              (item) => item !== value
                                            );
                                          setAncillaryTestImportExport(newCn);
                                        }
                                      }}
                                      id="flexCheckDefault"
                                    />
                                    <label className="" for="flexCheckDefault">
                                      Retrieve data from Phoropter To Sub
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="Send Rx to Phoropter"
                                      onChange={(e) => {
                                        const { value, checked } = e.target;

                                        if (checked) {
                                          setAncillaryTestImportExport([
                                            ...ancillaryTestImportExport,
                                            value,
                                          ]);
                                        } else {
                                          const newCn =
                                            ancillaryTestImportExport.filter(
                                              (item) => item !== value
                                            );
                                          setAncillaryTestImportExport(newCn);
                                        }
                                      }}
                                      id="flexCheckDefault"
                                    />
                                    <label className="" for="flexCheckDefault">
                                      Send Rx to Phoropter
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="OCT image import"
                                      onChange={(e) => {
                                        const { value, checked } = e.target;

                                        if (checked) {
                                          setAncillaryTestImportExport([
                                            ...ancillaryTestImportExport,
                                            value,
                                          ]);
                                        } else {
                                          const newCn =
                                            ancillaryTestImportExport.filter(
                                              (item) => item !== value
                                            );
                                          setAncillaryTestImportExport(newCn);
                                        }
                                      }}
                                      id="flexCheckDefault"
                                    />
                                    <label className="" for="flexCheckDefault">
                                      OCT image import
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value d-flex">
                    <p className="ms-1">
                      {ancillaryTestImportExport.length > 0 &&
                        ancillaryTestImportExport.map((item, i) => (
                          <span key={i}>{item} ,</span>
                        ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {anteriorEyeExamination && (
              <div className="row mt-2 exam-bg-white">
                <h6 className="my-1">Anterior Eye Examination</h6>
                <div className="col-4">
                  <div className="exam-bg-white p-1">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">UCVA</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange4(!change4);
                              setUCVAOUNear("");
                              setUCVAOUDistance("");
                              setUCVALeftNear();
                              setUCVALeftDistance("");
                              setUCVARightNear("");
                              setUCVARightDistance("");
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl4(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change4 && (
                            <Popover
                              id={id4}
                              open={open4}
                              anchorEl={anchorEl4}
                              onClose={() => setAnchorEl4(null)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div className="cns-container">
                                  <ul
                                    className="nav nav-pills"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link active"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                      >
                                        Right
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-Left-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-Left"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-Left"
                                        aria-selected="false"
                                      >
                                        Left
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-OU-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-OU"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-OU"
                                        aria-selected="false"
                                      >
                                        OU
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="pills-home"
                                      role="tabpanel"
                                      aria-labelledby="pills-home-tab"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setUCVARightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setUCVARightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setUCVARightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setUCVARightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setUCVARightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setUCVARightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-Left"
                                      role="tabpanel"
                                      aria-labelledby="pills-Left-tab"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVALeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVALeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVALeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVALeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVALeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVALeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-OU"
                                      role="tabpanel"
                                      aria-labelledby="pills-OU-tab"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVAOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVAOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setUCVAOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVAOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVAOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setUCVAOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p className="ms-1">
                        {UCVARightDistance && `R : D - ${UCVARightDistance}`}
                        {UCVARightNear && `,N -  ${UCVARightNear} `}
                        {(UCVARightNear || UCVARightDistance) && " | "}
                        {UCVALeftDistance && `L : D - ${UCVALeftDistance}`}
                        {UCVALeftNear && `,N -  ${UCVALeftNear} `}
                        {(UCVALeftNear || UCVALeftDistance) && " | "}
                        {UCVAOUDistance && `OU : D - ${UCVAOUDistance}`}
                        {UCVAOUNear && `,N -  ${UCVAOUNear} `}
                        {(UCVAOUNear || UCVAOUDistance) && " | "}
                      </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">EOM</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange9(!change9);
                              setEomType([]);
                              setEomValue([]);
                              setSaccades([]);
                              setIsSaccades(false);
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl9(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change9 && (
                            <Popover
                              id={id9}
                              open={open9}
                              anchorEl={anchorEl9}
                              onClose={() => setAnchorEl9(null)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div className="d-flex">
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Restricted"
                                      type="checkbox"
                                      name="cough2"
                                      onChange={(e) => handleEomType(e)}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Restricted</label>
                                  </div>
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Pain"
                                      type="checkbox"
                                      name="cough2"
                                      onChange={(e) => {
                                        handleEomType(e);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Pain</label>
                                  </div>
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Saccades"
                                      type="checkbox"
                                      name="cough2"
                                      onChange={(e) => {
                                        handleEomType(e);
                                        setSaccades([]);
                                        setIsSaccades(!isSaccades);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Saccades</label>
                                  </div>

                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Pursuits"
                                      type="checkbox"
                                      name="cough2"
                                      onChange={(e) => {
                                        handleEomType(e);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Pursuits</label>
                                  </div>
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Full"
                                      type="checkbox"
                                      name="cough2"
                                      onChange={(e) => {
                                        handleEomType(e);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Full</label>
                                  </div>
                                </div>
                                {isSaccades && (
                                  <div
                                    style={{ width: "180px" }}
                                    className="form-check ms-3 border rounded shadow"
                                  >
                                    <div className="p-2">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="Jerky"
                                        onChange={(e) => {
                                          const { value, checked } = e.target;

                                          if (checked) {
                                            setSaccades([...saccades, value]);
                                          } else {
                                            const newCn = saccades.filter(
                                              (item) => item !== value
                                            );
                                            setSaccades(newCn);
                                          }
                                        }}
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Jerky
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="Overshoot"
                                        onChange={(e) => {
                                          const { value, checked } = e.target;

                                          if (checked) {
                                            setSaccades([...saccades, value]);
                                          } else {
                                            const newCn = saccades.filter(
                                              (item) => item !== value
                                            );
                                            setSaccades(newCn);
                                          }
                                        }}
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Overshoot
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="Undershoot"
                                        onChange={(e) => {
                                          const { value, checked } = e.target;

                                          if (checked) {
                                            setSaccades([...saccades, value]);
                                          } else {
                                            const newCn = saccades.filter(
                                              (item) => item !== value
                                            );
                                            setSaccades(newCn);
                                          }
                                        }}
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Undershoot
                                      </label>
                                    </div>
                                  </div>
                                )}
                                <div className="border rsr-timeline row rounded shadow p-1">
                                  <div className="col-4">
                                    <Timeline position="left">
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="RSR"
                                              value="RSR"
                                              type="checkbox"
                                              name="coughRSR2"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>RSR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <hr />
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot className="tricky">
                                            <input
                                              className="RLR"
                                              value="RLR"
                                              type="checkbox"
                                              name="cough2RLR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>RLR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="RIR"
                                              value="RIR"
                                              type="checkbox"
                                              name="cough2RIR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent>RIR</TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  </div>
                                  <div className="col-2">
                                    <Timeline position="right">
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="RIO"
                                              value="RIO"
                                              type="checkbox"
                                              name="coughrio"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>RIO</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot className="tricky2">
                                            <input
                                              className="RMR"
                                              value="RMR"
                                              type="checkbox"
                                              name="coughRMR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>RMR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="RSO"
                                              value="RSO"
                                              type="checkbox"
                                              name="coughRIO"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;
                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          {/* <TimelineConnector /> */}
                                        </TimelineSeparator>
                                        <TimelineContent>RSO</TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  </div>
                                  <div className="col-4">
                                    <Timeline position="left">
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="LIO"
                                              value="LIO"
                                              type="checkbox"
                                              name="cough2LIO"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>LIO</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <hr />
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot className="tricky">
                                            <input
                                              className="LMR"
                                              value="LMR"
                                              type="checkbox"
                                              name="cough2LMR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>LMR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="LSO"
                                              value="LSO"
                                              type="checkbox"
                                              name="cough2LSO"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          {/* <TimelineConnector /> */}
                                        </TimelineSeparator>
                                        <TimelineContent>LSO</TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  </div>
                                  <div className="col-2">
                                    <Timeline position="right">
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="LSR"
                                              value="LSR"
                                              type="checkbox"
                                              name="cough2LSR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>LSR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot className="tricky2">
                                            <input
                                              className="LLR"
                                              value="LLR"
                                              type="checkbox"
                                              name="cough2LLR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>LLR</TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineSeparator>
                                          <TimelineDot>
                                            <input
                                              className="LIR"
                                              value="LIR"
                                              type="checkbox"
                                              name="cough2LIR"
                                              onChange={(e) => {
                                                const { value, checked } =
                                                  e.target;

                                                if (checked) {
                                                  setEomValue([
                                                    ...eomValue,
                                                    value,
                                                  ]);
                                                } else {
                                                  const newCn = eomValue.filter(
                                                    (item) => item !== value
                                                  );
                                                  setEomValue(newCn);
                                                }
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                          </TimelineDot>
                                          {/* <TimelineConnector /> */}
                                        </TimelineSeparator>
                                        <TimelineContent>LIR</TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value">
                      <p>
                        {eomType.toString()} {saccades.length > 0 && " - "}
                        {saccades.length > 0 &&
                          saccades.map((item, i) => (
                            <span key={i} className="me-1">
                              {item},
                            </span>
                          ))}
                      </p>
                      <p>
                        {eomValue.map((item, i) => (
                          <span key={i} className="me-1">
                            {item},
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Gonioscopy</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange11(!change11);
                              setGonioscopy("");
                              setGonioscopyShapeLeftC([]);
                              setGonioscopyShapeLeftI([]);
                              setGonioscopyShapeLeftT([]);
                              setGonioscopyShapeLeftN([]);
                              setGonioscopyShapeLeftS([]);
                              setGonioscopyShapeRightC([]);
                              setGonioscopyShapeRightI([]);
                              setGonioscopyShapeRightN([]);
                              setGonioscopyShapeRightS([]);
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl11(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change11 && (
                            <Popover
                              id={id11}
                              open={open11}
                              anchorEl={anchorEl11}
                              onClose={() => setAnchorEl11(null)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div
                                  className={`${
                                    gonioscopy === "Van Herrick" ? "" : "d-flex"
                                  } `}
                                >
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Van Herrick"
                                      type="radio"
                                      name="cough2"
                                      onChange={(e) => {
                                        setGonioscopy(e.target.value);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Van Herrick</label>
                                  </div>

                                  {gonioscopy === "Van Herrick" && (
                                    <div className="ms-2 mt-1 d-flex border rounded p-1">
                                      <div className="d-flex me-1">
                                        <input
                                          className="custom-radio"
                                          value="G1"
                                          type="radio"
                                          name="cough6"
                                          onChange={(e) => {
                                            setGonioscopyVanHarrik(
                                              e.target.value
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">G1</label>
                                      </div>
                                      <div className="d-flex me-1">
                                        <input
                                          className="custom-radio"
                                          value="G2"
                                          type="radio"
                                          name="cough6"
                                          onChange={(e) => {
                                            setGonioscopyVanHarrik(
                                              e.target.value
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">G2</label>
                                      </div>
                                      <div className="d-flex me-1">
                                        <input
                                          className="custom-radio"
                                          value="G3"
                                          type="radio"
                                          name="cough6"
                                          onChange={(e) => {
                                            setGonioscopyVanHarrik(
                                              e.target.value
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">G3</label>
                                      </div>
                                      <div className="d-flex me-1">
                                        <input
                                          className="custom-radio"
                                          value="G4"
                                          type="radio"
                                          name="cough6"
                                          onChange={(e) => {
                                            setGonioscopyVanHarrik(
                                              e.target.value
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">G4</label>
                                      </div>
                                      <div className="d-flex me-1">
                                        <input
                                          className="custom-radio"
                                          value="G5"
                                          type="radio"
                                          name="cough6"
                                          onChange={(e) => {
                                            setGonioscopyVanHarrik(
                                              e.target.value
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">G5</label>
                                      </div>
                                    </div>
                                  )}

                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Schaffer"
                                      type="radio"
                                      name="cough2"
                                      onChange={(e) => {
                                        setGonioscopy(e.target.value);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">Schaffer</label>
                                  </div>
                                  <div className="d-flex">
                                    <input
                                      className="custom-radio"
                                      value="Land Mark Visible"
                                      type="radio"
                                      name="cough2"
                                      onChange={(e) => {
                                        setGonioscopy(e.target.value);
                                      }}
                                      id="flexRadioDefault1"
                                    />
                                    <label className="">
                                      Land Mark Visible
                                    </label>
                                  </div>
                                </div>
                                <div className="mt-1 border rounded p-1 eye-svg-content">
                                  <div ref={ref2} className="row">
                                    <div className="col-6">
                                      <div className="text-center">
                                        <span className="py-2">Right</span>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 d-flex align-items-center justify-content-end ">
                                          <span className="me-2 ">
                                            T
                                            {gonioscopyShapeRightT.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeRightT.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-4 text-center">
                                          <span className="d-block mt-2 mb-1">
                                            S
                                            {gonioscopyShapeRightS.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeRightS.toString()}
                                              </>
                                            )}
                                          </span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="80"
                                            height="80"
                                          >
                                            {/* part1 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeRightT.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl21(e.currentTarget);
                                                setGonioscopyPopup("RightT");
                                              }}
                                              d="m 25.379164,41.439909 a 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.567421,14.56478 0 0 1 3.613403,-9.650589 L 11.003793,12.452584 A 39.832793,39.82557 0 0 0 -1.5761401e-5,39.996233 c 0,0.483596 0,0.967192 0,1.422341 A 39.832793,39.82557 0 0 0 12.447732,68.983558 L 30.365376,51.069163 a 14.58876,14.586115 0 0 1 -4.986212,-9.629254 z"
                                            />
                                            {/* part2 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeRightS.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl21(e.currentTarget);
                                                setGonioscopyPopup("RightS");
                                              }}
                                              d="M 40.010602,-1.8261322e-5 A 39.832793,39.82557 0 0 0 11.018019,12.452584 l 17.917644,17.914395 a 14.652777,14.65012 0 0 1 22.149878,0 L 68.996072,12.445472 A 39.889697,39.882464 0 0 0 40.010602,-1.8261322e-5 Z"
                                            />
                                            {/* part3 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeRightN.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl21(e.currentTarget);
                                                setGonioscopyPopup("RightN");
                                              }}
                                              d="M 68.996072,12.445472 51.078428,30.366979 a 14.567421,14.56478 0 0 1 3.613403,9.629254 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.58876,14.586115 0 0 1 -4.979099,9.629254 l 17.93187,17.93573 A 39.832793,39.82557 0 0 0 79.999881,41.439909 c 0,-0.476484 0,-0.96008 0,-1.422341 A 39.889697,39.882464 0 0 0 68.996072,12.445472 Z"
                                            />
                                            {/* part4 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeRightI.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl21(e.currentTarget);
                                                setGonioscopyPopup("RightI");
                                              }}
                                              d="m 49.634489,51.069163 v 0 a 14.652777,14.65012 0 0 1 -19.254887,0 L 12.454845,68.983558 a 39.975053,39.967804 0 0 0 55.118627,0 z"
                                            />
                                            {/* part middle*/}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeRightC.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl21(e.currentTarget);
                                                setGonioscopyPopup("RightC");
                                              }}
                                              d="m 54.698944,39.996233 a 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.688342,14.685679 0 0 1 -29.234424,0 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.688342,14.685679 0 0 1 29.376684,0 z"
                                            />
                                            <Popover
                                              id={id21}
                                              open={open21}
                                              anchorEl={anchorEl21}
                                              onClose={() =>
                                                setAnchorEl21(null)
                                              }
                                              anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                              }}
                                            >
                                              <div
                                                style={{ width: "300px" }}
                                                className="left-popup"
                                              >
                                                {gonioscopyPopup ===
                                                  "RightT" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightT(
                                                              [
                                                                ...gonioscopyShapeRightT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightT(
                                                              [
                                                                ...gonioscopyShapeRightT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightT(
                                                              [
                                                                ...gonioscopyShapeRightT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightT(
                                                              [
                                                                ...gonioscopyShapeRightT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightT(
                                                              [
                                                                ...gonioscopyShapeRightT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "RightS" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeRightS.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightS(
                                                              [
                                                                ...gonioscopyShapeRightS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeRightS.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightS(
                                                              [
                                                                ...gonioscopyShapeRightS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightS.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightS(
                                                              [
                                                                ...gonioscopyShapeRightS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightS.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightS(
                                                              [
                                                                ...gonioscopyShapeRightS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeRightS.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightS(
                                                              [
                                                                ...gonioscopyShapeRightS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "RightN" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeRightN.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightN(
                                                              [
                                                                ...gonioscopyShapeRightN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeRightN.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightN(
                                                              [
                                                                ...gonioscopyShapeRightN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightN.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightN(
                                                              [
                                                                ...gonioscopyShapeRightN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightN.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightN(
                                                              [
                                                                ...gonioscopyShapeRightN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeRightN.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightN(
                                                              [
                                                                ...gonioscopyShapeRightN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "RightI" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeRightI.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightI(
                                                              [
                                                                ...gonioscopyShapeRightI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeRightI.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightI(
                                                              [
                                                                ...gonioscopyShapeRightI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightI.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightI(
                                                              [
                                                                ...gonioscopyShapeRightI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightI.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightI(
                                                              [
                                                                ...gonioscopyShapeRightI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeRightI.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightI(
                                                              [
                                                                ...gonioscopyShapeRightI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "RightC" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeRightC.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightC(
                                                              [
                                                                ...gonioscopyShapeRightC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeRightC.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightC(
                                                              [
                                                                ...gonioscopyShapeRightC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightC.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightC(
                                                              [
                                                                ...gonioscopyShapeRightC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightC.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightC(
                                                              [
                                                                ...gonioscopyShapeRightC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeRightC.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeRightC(
                                                              [
                                                                ...gonioscopyShapeRightC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeRightC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </Popover>
                                          </svg>
                                          <span className="mt-1 d-block">
                                            I
                                            {gonioscopyShapeRightI.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeRightI.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-4  d-flex align-items-center">
                                          <span className="ms-2">
                                            N
                                            {gonioscopyShapeRightN.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeRightN.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 text-center">
                                      <div className="text-center">
                                        <span className="py-2">Left</span>
                                      </div>
                                      <div className="row">
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                          <span className="me-2">
                                            T
                                            {gonioscopyShapeLeftT.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeLeftT.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-4 text-center">
                                          <span className="d-block mt-2 mb-1">
                                            S
                                            {gonioscopyShapeLeftS.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeLeftS.toString()}
                                              </>
                                            )}
                                          </span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="80"
                                            height="80"
                                          >
                                            {/* part1 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeLeftT.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl22(e.currentTarget);
                                                setGonioscopyPopup("LeftT");
                                              }}
                                              d="m 25.379164,41.439909 a 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.567421,14.56478 0 0 1 3.613403,-9.650589 L 11.003793,12.452584 A 39.832793,39.82557 0 0 0 -1.5761401e-5,39.996233 c 0,0.483596 0,0.967192 0,1.422341 A 39.832793,39.82557 0 0 0 12.447732,68.983558 L 30.365376,51.069163 a 14.58876,14.586115 0 0 1 -4.986212,-9.629254 z"
                                            />
                                            {/* part2 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeLeftS.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl22(e.currentTarget);
                                                setGonioscopyPopup("LeftS");
                                              }}
                                              d="M 40.010602,-1.8261322e-5 A 39.832793,39.82557 0 0 0 11.018019,12.452584 l 17.917644,17.914395 a 14.652777,14.65012 0 0 1 22.149878,0 L 68.996072,12.445472 A 39.889697,39.882464 0 0 0 40.010602,-1.8261322e-5 Z"
                                            />
                                            {/* part3 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeLeftN.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl22(e.currentTarget);
                                                setGonioscopyPopup("LeftN");
                                              }}
                                              d="M 68.996072,12.445472 51.078428,30.366979 a 14.567421,14.56478 0 0 1 3.613403,9.629254 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.58876,14.586115 0 0 1 -4.979099,9.629254 l 17.93187,17.93573 A 39.832793,39.82557 0 0 0 79.999881,41.439909 c 0,-0.476484 0,-0.96008 0,-1.422341 A 39.889697,39.882464 0 0 0 68.996072,12.445472 Z"
                                            />
                                            {/* part4 */}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeLeftI.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl22(e.currentTarget);
                                                setGonioscopyPopup("LeftI");
                                              }}
                                              d="m 49.634489,51.069163 v 0 a 14.652777,14.65012 0 0 1 -19.254887,0 L 12.454845,68.983558 a 39.975053,39.967804 0 0 0 55.118627,0 z"
                                            />
                                            {/* part middle*/}
                                            <path
                                              className={`eye-svg-bg ${
                                                gonioscopyShapeLeftC.length >
                                                  0 && "active-eye-svg"
                                              }`}
                                              onClick={(e) => {
                                                setAnchorEl22(e.currentTarget);
                                                setGonioscopyPopup("LeftC");
                                              }}
                                              d="m 54.698944,39.996233 a 13.820556,13.81805 0 0 1 -0.07113,1.422341 14.688342,14.685679 0 0 1 -29.234424,0 13.820556,13.81805 0 0 1 -0.07113,-1.422341 14.688342,14.685679 0 0 1 29.376684,0 z"
                                            />
                                            <Popover
                                              id={id22}
                                              open={open22}
                                              anchorEl={anchorEl22}
                                              onClose={() =>
                                                setAnchorEl22(null)
                                              }
                                              anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "center",
                                                horizontal: "right",
                                              }}
                                            >
                                              <div
                                                style={{ width: "300px" }}
                                                className="left-popup"
                                              >
                                                {gonioscopyPopup ===
                                                  "LeftT" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftT.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftT(
                                                              [
                                                                ...gonioscopyShapeLeftT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftT.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftT(
                                                              [
                                                                ...gonioscopyShapeLeftT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftT.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftT(
                                                              [
                                                                ...gonioscopyShapeLeftT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeRightT.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftT(
                                                              [
                                                                ...gonioscopyShapeLeftT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftT.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftT(
                                                              [
                                                                ...gonioscopyShapeLeftT,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftT.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftT(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "LeftS" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftS.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftS(
                                                              [
                                                                ...gonioscopyShapeLeftS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftS.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftS(
                                                              [
                                                                ...gonioscopyShapeLeftS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftS.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftS(
                                                              [
                                                                ...gonioscopyShapeLeftS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftS.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftS(
                                                              [
                                                                ...gonioscopyShapeLeftS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftS.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftS(
                                                              [
                                                                ...gonioscopyShapeLeftS,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftS.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftS(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "LeftN" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftN.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftN(
                                                              [
                                                                ...gonioscopyShapeLeftN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftN.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftN(
                                                              [
                                                                ...gonioscopyShapeLeftN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftN.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftN(
                                                              [
                                                                ...gonioscopyShapeLeftN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftN.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftN(
                                                              [
                                                                ...gonioscopyShapeLeftN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftN.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftN(
                                                              [
                                                                ...gonioscopyShapeLeftN,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeRightN.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftN(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "LeftI" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftI.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftI(
                                                              [
                                                                ...gonioscopyShapeLeftI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftI.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftI(
                                                              [
                                                                ...gonioscopyShapeLeftI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftI.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftI(
                                                              [
                                                                ...gonioscopyShapeLeftI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftI.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftI(
                                                              [
                                                                ...gonioscopyShapeLeftI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftI.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftI(
                                                              [
                                                                ...gonioscopyShapeLeftI,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftI.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftI(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                                {gonioscopyPopup ===
                                                  "LeftC" && (
                                                  <>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="CB"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftC.find(
                                                            (item) =>
                                                              item === "CB"
                                                          ) === "CB"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftC(
                                                              [
                                                                ...gonioscopyShapeLeftC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        CB- Ciliary Body Band
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="SS"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftC.find(
                                                            (item) =>
                                                              item === "SS"
                                                          ) === "SS"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftC(
                                                              [
                                                                ...gonioscopyShapeLeftC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        SS- Scleral Spur
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="TM(P)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftC.find(
                                                            (item) =>
                                                              item === "TM(P)"
                                                          ) === "TM(P)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftC(
                                                              [
                                                                ...gonioscopyShapeLeftC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(P)- Trabecular
                                                        Meshwork pigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                      <input
                                                        className="custom-radio mt-1"
                                                        value="TM(NP)"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftC.find(
                                                            (item) =>
                                                              item === "TM(NP)"
                                                          ) === "TM(NP)"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftC(
                                                              [
                                                                ...gonioscopyShapeLeftC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        TM(NP)- Trabecular
                                                        Meshwork nonpigmented
                                                      </label>
                                                    </div>
                                                    <div className="d-flex">
                                                      <input
                                                        className="custom-radio"
                                                        value="Sch"
                                                        defaultChecked={
                                                          gonioscopyShapeLeftC.find(
                                                            (item) =>
                                                              item === "Sch"
                                                          ) === "Sch"
                                                        }
                                                        type="checkbox"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          const {
                                                            value,
                                                            checked,
                                                          } = e.target;

                                                          if (checked) {
                                                            setGonioscopyShapeLeftC(
                                                              [
                                                                ...gonioscopyShapeLeftC,
                                                                value,
                                                              ]
                                                            );
                                                          } else {
                                                            const newCn =
                                                              gonioscopyShapeLeftC.filter(
                                                                (item) =>
                                                                  item !== value
                                                              );
                                                            setGonioscopyShapeLeftC(
                                                              newCn
                                                            );
                                                          }
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        Schwalbe’s Line
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </Popover>
                                          </svg>
                                          <span className="mt-1 d-block">
                                            I
                                            {gonioscopyShapeLeftI.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeLeftI.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-4 d-flex align-items-center">
                                          <span className="ms-2">
                                            N
                                            {gonioscopyShapeLeftN.length >
                                              0 && (
                                              <>
                                                :
                                                {gonioscopyShapeLeftN.toString()}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="rx-one-button-group d-flex justify-content-end mt-1">
                                    <button
                                      onClick={getGonioscopyImage}
                                      className="btn m-1"
                                    >
                                      Save
                                    </button>
                                  </div>
                                  {imageGonioscopy && <hr />}
                                  <img src={imageGonioscopy} alt="" />
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p>
                        {gonioscopy}
                        {gonioscopy === "Van Herrick" &&
                          ` : ${gonioscopyVanHarrik}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="exam-bg-white p-1">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Pinhole</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange7(!change7);
                              setPinholeLeftNear();
                              setPinholeLeftDistance("");
                              setPinholeRightNear("");
                              setPinholeRightDistance("");
                              setPinholeOUNear("");
                              setPinholeOUDistance("");
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl7(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change7 && (
                            <Popover
                              id={id7}
                              open={open7}
                              anchorEl={anchorEl7}
                              onClose={() => setAnchorEl7(null)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div className="cns-container">
                                  <ul
                                    className="nav nav-pills"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link active"
                                        id="pills-home1-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                      >
                                        Right
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-Left1-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-Left1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-Left"
                                        aria-selected="false"
                                      >
                                        Left
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-Left2-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-Left2"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-OU"
                                        aria-selected="false"
                                      >
                                        OU
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="pills-home1"
                                      role="tabpanel"
                                      aria-labelledby="pills-home-tab"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setPinholeRightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setPinholeRightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough2"
                                                        onChange={(e) => {
                                                          setPinholeRightDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setPinholeRightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setPinholeRightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough1"
                                                        onChange={(e) => {
                                                          setPinholeRightNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-Left1"
                                      role="tabpanel"
                                      aria-labelledby="pills-Left-tab"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setPinholeLeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setPinholeLeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough3"
                                                        onChange={(e) => {
                                                          setPinholeLeftDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setPinholeLeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setPinholeLeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough4"
                                                        onChange={(e) => {
                                                          setPinholeLeftNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-Left2"
                                      role="tabpanel"
                                      aria-labelledby="pills-Left-OU"
                                    >
                                      <div className="row eye-large-popup p-2">
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1">Distance</h6>
                                          <div className="border-top row">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough5"
                                                        onChange={(e) => {
                                                          setPinholeOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough5"
                                                        onChange={(e) => {
                                                          setPinholeOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough5"
                                                        onChange={(e) => {
                                                          setPinholeOUDistance(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-6 text-center border rounded">
                                          <h6 className="mt-1 ">Near</h6>
                                          <div className="border-top row clearfix">
                                            <div className="col-4 border-right">
                                              {UCVAArray1.length > 0 &&
                                                UCVAArray1.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough6"
                                                        onChange={(e) => {
                                                          setPinholeOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4 border-right">
                                              {UCVAArray2.length > 0 &&
                                                UCVAArray2.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough6"
                                                        onChange={(e) => {
                                                          setPinholeOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                            <div className="col-4">
                                              {UCVAArray3.length > 0 &&
                                                UCVAArray3.map((v, i) => {
                                                  return (
                                                    <div
                                                      key={i}
                                                      className="d-flex"
                                                    >
                                                      <input
                                                        className="custom-radio"
                                                        value={v.name}
                                                        type="radio"
                                                        name="cough6"
                                                        onChange={(e) => {
                                                          setPinholeOUNear(
                                                            e.target.value
                                                          );
                                                        }}
                                                        id="flexRadioDefault1"
                                                      />
                                                      <label className="">
                                                        {v.name}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p className="ms-1">
                        {pinholeRightDistance &&
                          `R : D - ${pinholeRightDistance}`}
                        {pinholeRightNear && `,R : N -  ${pinholeRightNear} `}
                        {(pinholeRightNear || pinholeRightDistance) && " | "}
                        {pinholeLeftDistance &&
                          `L : D - ${pinholeLeftDistance}`}
                        {pinholeLeftNear && `,L : N -  ${pinholeLeftNear} `}
                        {(pinholeLeftNear || pinholeLeftDistance) && " | "}
                        {pinholeOUDistance && `OU : D - ${pinholeOUDistance}`}
                        {pinholeOUNear && `,OU : N -  ${pinholeOUNear} `}
                        {(pinholeOUNear || pinholeOUDistance) && " | "}
                      </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Pupils</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange10(!change10);
                              takePupilsScrenShot("");
                              setPupilsRight("");
                              setPupilsLeft("");
                              setPupilsRightValue("");
                              setPupilsLeftValue("");
                              setPupilsTypeRight([]);
                              setPupilsTypeLeft([]);
                              setPupilsRightShape({
                                part1: "",
                                part2: "",
                                part3: "",
                                part4: "",
                                part5: "",
                                part6: "",
                                part7: "",
                                part8: "",
                                center: "",
                              });
                              setPupilsLeftShape({
                                part1: "",
                                part2: "",
                                part3: "",
                                part4: "",
                                part5: "",
                                part6: "",
                                part7: "",
                                part8: "",
                                center: "",
                              });
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl10(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change10 && (
                            <Popover
                              id={id10}
                              open={open10}
                              anchorEl={anchorEl10}
                              onClose={() => setAnchorEl10(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{ width: "300px" }}
                                className="left-popup"
                              >
                                <div className="cns-container">
                                  <ul
                                    className="nav nav-pills mb-2"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link active"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home-pupils"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home-pupils"
                                        aria-selected="true"
                                      >
                                        Right
                                      </button>
                                    </li>
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link"
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-profile-pupils"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile-pupils"
                                        aria-selected="false"
                                      >
                                        Left
                                      </button>
                                    </li>
                                  </ul>
                                  <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="pills-home-pupils"
                                      role="tabpanel"
                                      aria-labelledby="pills-home-tab"
                                    >
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="border">
                                            <div className="d-flex">
                                              <input
                                                className="custom-radio"
                                                value="Normal"
                                                type="radio"
                                                name="cough2"
                                                onChange={(e) => {
                                                  setPupilsRight(
                                                    e.target.value
                                                  );
                                                  setPupilsTypeRight([]);
                                                }}
                                                id="flexRadioDefault1"
                                              />
                                              <label className="">Normal</label>
                                            </div>
                                            <div className="d-flex">
                                              <input
                                                className="custom-radio"
                                                value="Abnormal"
                                                type="radio"
                                                name="cough2"
                                                onChange={(e) => {
                                                  setPupilsRight(
                                                    e.target.value
                                                  );
                                                }}
                                                id="flexRadioDefault1"
                                              />
                                              <label className="">
                                                Abnormal
                                              </label>
                                            </div>
                                            {pupilsRight === "Abnormal" && (
                                              <div className="form-check ms-1 border rounded shadow">
                                                <div className="p-2">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="+ RAPD"
                                                    onChange={(e) =>
                                                      handlePupilsRight(e)
                                                    }
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    + RAPD
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Horner’s"
                                                    onChange={(e) => {
                                                      handlePupilsRight(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Horner’s
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Miotic"
                                                    onChange={(e) => {
                                                      handlePupilsRight(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Miotic
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Fixed"
                                                    onChange={(e) => {
                                                      handlePupilsRight(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Fixed
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Dilated"
                                                    onChange={(e) => {
                                                      handlePupilsRight(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Dilated
                                                  </label>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="border">
                                            <div className="border-bottom text-center">
                                              <span className="my-1">Size</span>
                                            </div>

                                            <div className="row p-1">
                                              <div className="col-3">
                                                <label>Right</label>
                                              </div>
                                              <div className="col-7">
                                                <input
                                                  type="text"
                                                  onChange={(e) =>
                                                    setPupilsRightValue(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control form-control-sm"
                                                />
                                              </div>
                                              <div className="col-2">
                                                <span>mm</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="pills-profile-pupils"
                                      role="tabpanel"
                                      aria-labelledby="pills-profile-tab"
                                    >
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="border">
                                            <div className="d-flex">
                                              <input
                                                className="custom-radio"
                                                value="Normal"
                                                type="radio"
                                                name="cough2"
                                                onChange={(e) => {
                                                  setPupilsLeft(e.target.value);
                                                  setPupilsTypeLeft([]);
                                                }}
                                                id="flexRadioDefault1"
                                              />
                                              <label className="">Normal</label>
                                            </div>
                                            <div className="d-flex">
                                              <input
                                                className="custom-radio"
                                                value="Abnormal"
                                                type="radio"
                                                name="cough2"
                                                onChange={(e) => {
                                                  setPupilsLeft(e.target.value);
                                                }}
                                                id="flexRadioDefault1"
                                              />
                                              <label className="">
                                                Abnormal
                                              </label>
                                            </div>
                                            {pupilsLeft === "Abnormal" && (
                                              <div className="form-check ms-1 border rounded shadow">
                                                <div className="p-2">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="+ RAPD"
                                                    onChange={(e) =>
                                                      handlePupilsLeft(e)
                                                    }
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    + RAPD
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Horner’s"
                                                    onChange={(e) => {
                                                      handlePupilsLeft(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Horner’s
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Miotic"
                                                    onChange={(e) => {
                                                      handlePupilsLeft(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Miotic
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Fixed"
                                                    onChange={(e) => {
                                                      handlePupilsLeft(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Fixed
                                                  </label>
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Dilated"
                                                    onChange={(e) => {
                                                      handlePupilsLeft(e);
                                                    }}
                                                    id="flexCheckDefault"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    for="flexCheckDefault"
                                                  >
                                                    Dilated
                                                  </label>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="border">
                                            <div className="border-bottom text-center">
                                              <span className="my-1">Size</span>
                                            </div>
                                            <div className="row p-1">
                                              <div className="col-3">
                                                <label>Left</label>
                                              </div>
                                              <div className="col-7">
                                                <input
                                                  type="text"
                                                  onChange={(e) =>
                                                    setPupilsLeftValue(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control form-control-sm"
                                                />
                                              </div>
                                              <div className="col-2">
                                                <span>mm</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  ref={pupilsRef}
                                  className="mt-1 border rounded p-1 eye-svg-content"
                                >
                                  <div className="border-bottom">
                                    <span className="my-1">Shape</span>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 text-center">
                                      <div className="text-center">
                                        <span className="py-2">Right</span>
                                      </div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                      >
                                        {/* part1 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part1 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part1
                                                ? {
                                                    ...pupilsRightShape,
                                                    part1: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part1: "part1",
                                                  }
                                            )
                                          }
                                          d="M 28.895762,30.37249 10.994022,12.454844 A 39.797436,39.832798 0 0 0 -1.9303509e-5,40.003492 c 0,0.483684 0,0.967368 0,1.4226 H 25.356633 a 13.808289,13.820558 0 0 1 -0.07107,-1.4226 14.554491,14.567423 0 0 1 3.610196,-9.631002 z"
                                        />
                                        {/* part2 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part2 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part2
                                                ? {
                                                    ...pupilsRightShape,
                                                    part2: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part2: "part2",
                                                  }
                                            )
                                          }
                                          d="M 10.901635,12.454844 28.803375,30.37249 A 14.582918,14.595875 0 0 1 39.868484,25.315148 V -1.825316e-5 A 39.797436,39.832798 0 0 0 10.901635,12.454844 Z"
                                        />
                                        {/* part3 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part3 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part3
                                                ? {
                                                    ...pupilsRightShape,
                                                    part3: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part3: "part3",
                                                  }
                                            )
                                          }
                                          d="M 39.868484,-1.825316e-5 V 25.315148 A 14.582918,14.595875 0 0 1 50.933592,30.37249 L 68.842438,12.447731 A 39.854289,39.889702 0 0 0 39.868484,-1.825316e-5 Z"
                                        />
                                        {/* part4 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part4 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part4
                                                ? {
                                                    ...pupilsRightShape,
                                                    part4: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part4: "part4",
                                                  }
                                            )
                                          }
                                          d="M 69.005892,12.447731 51.097046,30.37249 a 14.554491,14.567423 0 0 1 3.624409,9.631002 13.808289,13.820558 0 0 1 -0.07107,1.4226 H 79.98572 c 0,-0.476571 0,-0.960255 0,-1.4226 A 39.854289,39.889702 0 0 0 69.005892,12.447731 Z"
                                        />
                                        {/* part5 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part5 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part5
                                                ? {
                                                    ...pupilsRightShape,
                                                    part5: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part5: "part5",
                                                  }
                                            )
                                          }
                                          d="m 54.664602,41.447431 a 14.575811,14.588762 0 0 1 -4.97468,9.631002 L 67.584555,68.996079 A 39.797436,39.832798 0 0 0 79.999934,41.447431 Z"
                                        />
                                        {/* part6 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part6 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part6
                                                ? {
                                                    ...pupilsRightShape,
                                                    part6: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part6: "part6",
                                                  }
                                            )
                                          }
                                          d="m 49.682816,51.078433 a 14.597131,14.610101 0 0 1 -9.622452,3.60629 v 25.315166 a 39.797436,39.832798 0 0 0 27.524191,-11.00381 z"
                                        />
                                        {/* part7 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part7 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part7
                                                ? {
                                                    ...pupilsRightShape,
                                                    part7: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part7: "part7",
                                                  }
                                            )
                                          }
                                          d="m 30.366846,51.078433 -17.90174,17.917646 a 39.797436,39.832798 0 0 0 27.524191,11.00381 V 54.684723 a 14.597131,14.610101 0 0 1 -9.622451,-3.60629 z"
                                        />
                                        {/* part8 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.part8 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.part8
                                                ? {
                                                    ...pupilsRightShape,
                                                    part8: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    part8: "part8",
                                                  }
                                            )
                                          }
                                          d="M 25.38506,41.447431 H 0.05683418 A 39.797436,39.832798 0 0 0 12.465106,68.996079 L 30.366846,51.078433 A 14.575811,14.588762 0 0 1 25.38506,41.447431 Z"
                                        />
                                        {/* part middle*/}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsRightShape.center !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsRightShape(
                                              pupilsRightShape.center
                                                ? {
                                                    ...pupilsRightShape,
                                                    center: "",
                                                  }
                                                : {
                                                    ...pupilsRightShape,
                                                    center: "center",
                                                  }
                                            )
                                          }
                                          d="m 54.664602,40.003492 a 13.808289,13.820558 0 0 1 -0.07107,1.4226 14.675305,14.688344 0 0 1 -29.208475,0 13.808289,13.820558 0 0 1 -0.07107,-1.4226 14.675305,14.688344 0 0 1 29.350609,0 z"
                                        />
                                      </svg>
                                    </div>
                                    <div className="col-6 text-center">
                                      <div className="text-center">
                                        <span className="py-2">Left</span>
                                      </div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                      >
                                        {/* part1 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part1 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part1
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part1: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part1: "part1",
                                                  }
                                            )
                                          }
                                          d="M 28.895762,30.37249 10.994022,12.454844 A 39.797436,39.832798 0 0 0 -1.9303509e-5,40.003492 c 0,0.483684 0,0.967368 0,1.4226 H 25.356633 a 13.808289,13.820558 0 0 1 -0.07107,-1.4226 14.554491,14.567423 0 0 1 3.610196,-9.631002 z"
                                        />
                                        {/* part2 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part2 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part2
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part2: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part2: "part2",
                                                  }
                                            )
                                          }
                                          d="M 10.901635,12.454844 28.803375,30.37249 A 14.582918,14.595875 0 0 1 39.868484,25.315148 V -1.825316e-5 A 39.797436,39.832798 0 0 0 10.901635,12.454844 Z"
                                        />
                                        {/* part3 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part3 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part3
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part3: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part3: "part3",
                                                  }
                                            )
                                          }
                                          d="M 39.868484,-1.825316e-5 V 25.315148 A 14.582918,14.595875 0 0 1 50.933592,30.37249 L 68.842438,12.447731 A 39.854289,39.889702 0 0 0 39.868484,-1.825316e-5 Z"
                                        />
                                        {/* part4 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part4 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part4
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part4: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part4: "part4",
                                                  }
                                            )
                                          }
                                          d="M 69.005892,12.447731 51.097046,30.37249 a 14.554491,14.567423 0 0 1 3.624409,9.631002 13.808289,13.820558 0 0 1 -0.07107,1.4226 H 79.98572 c 0,-0.476571 0,-0.960255 0,-1.4226 A 39.854289,39.889702 0 0 0 69.005892,12.447731 Z"
                                        />
                                        {/* part5 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part5 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part5
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part5: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part5: "part5",
                                                  }
                                            )
                                          }
                                          d="m 54.664602,41.447431 a 14.575811,14.588762 0 0 1 -4.97468,9.631002 L 67.584555,68.996079 A 39.797436,39.832798 0 0 0 79.999934,41.447431 Z"
                                        />
                                        {/* part6 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part6 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part6
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part6: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part6: "part6",
                                                  }
                                            )
                                          }
                                          d="m 49.682816,51.078433 a 14.597131,14.610101 0 0 1 -9.622452,3.60629 v 25.315166 a 39.797436,39.832798 0 0 0 27.524191,-11.00381 z"
                                        />
                                        {/* part7 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part7 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part7
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part7: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part7: "part7",
                                                  }
                                            )
                                          }
                                          d="m 30.366846,51.078433 -17.90174,17.917646 a 39.797436,39.832798 0 0 0 27.524191,11.00381 V 54.684723 a 14.597131,14.610101 0 0 1 -9.622451,-3.60629 z"
                                        />
                                        {/* part8 */}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.part8 !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.part8
                                                ? {
                                                    ...pupilsLeftShape,
                                                    part8: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    part8: "part8",
                                                  }
                                            )
                                          }
                                          d="M 25.38506,41.447431 H 0.05683418 A 39.797436,39.832798 0 0 0 12.465106,68.996079 L 30.366846,51.078433 A 14.575811,14.588762 0 0 1 25.38506,41.447431 Z"
                                        />
                                        {/* part middle*/}
                                        <path
                                          className={`eye-svg-bg ${
                                            pupilsLeftShape.center !== "" &&
                                            "active-eye-svg"
                                          }`}
                                          onClick={() =>
                                            setPupilsLeftShape(
                                              pupilsLeftShape.center
                                                ? {
                                                    ...pupilsLeftShape,
                                                    center: "",
                                                  }
                                                : {
                                                    ...pupilsLeftShape,
                                                    center: "center",
                                                  }
                                            )
                                          }
                                          d="m 54.664602,40.003492 a 13.808289,13.820558 0 0 1 -0.07107,1.4226 14.675305,14.688344 0 0 1 -29.208475,0 13.808289,13.820558 0 0 1 -0.07107,-1.4226 14.675305,14.688344 0 0 1 29.350609,0 z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>

                                <div className="rx-one-button-group d-flex justify-content-end">
                                  <button
                                    onClick={() => {
                                      setAnchorEl10(null);
                                      takePupilsScrenShot(pupilsRef.current);
                                    }}
                                    className="btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value">
                      <p className="ms-1">
                        {pupilsRight && `Right: ${pupilsRight}`}
                        {pupilsTypeRight.map((item, i) => (
                          <span key={i}>{item} ,</span>
                        ))}
                        {pupilsRightValue && (
                          <span>Right: {pupilsRightValue}mm</span>
                        )}
                      </p>
                      <p className="ms-1">
                        {pupilsLeft && `Left: ${pupilsLeft}`}
                        {pupilsTypeLeft.map((item, i) => (
                          <span key={i}>{item} ,</span>
                        ))}
                        {pupilsLeftValue && (
                          <span>Left: {pupilsLeftValue}mm ,</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Intra-Ocular Pressure</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange12(!change12);
                              setIntraOcularPressureCheckbox([]);
                              setIntraOcularPressure([]);
                              setIntraOcularPressureRightPre("");
                              setIntraOcularPressureLeftPost("");
                              setIntraOcularPressureRightPre("");
                              setIntraOcularPressureLeftPost("");
                              setIsPressureDilated(false);
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl12(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change12 && (
                            <Popover
                              id={id12}
                              open={open12}
                              anchorEl={anchorEl12}
                              onClose={() => setAnchorEl12(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <div
                                style={{ width: "600px" }}
                                className="left-popup eye-check-popup"
                              >
                                <div className="row">
                                  <div className="col-4">
                                    <div className="border">
                                      <div className="d-flex">
                                        <input
                                          className="custom-radio"
                                          value="Goldmann"
                                          type="checkbox"
                                          name="cough2"
                                          onChange={(e) => {
                                            pressureHandler(e);
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">Goldmann</label>
                                      </div>
                                      <div className="d-flex">
                                        <input
                                          className="custom-radio"
                                          value="Perkins"
                                          type="checkbox"
                                          name="cough2"
                                          onChange={(e) => {
                                            pressureHandler(e);
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">Perkins</label>
                                      </div>
                                      <div className="d-flex">
                                        <input
                                          className="custom-radio"
                                          value="Non-Contact"
                                          type="checkbox"
                                          name="cough2"
                                          onChange={(e) => {
                                            pressureHandler(e);
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">Non-Contact</label>
                                      </div>
                                      <div className="d-flex">
                                        <input
                                          className="custom-radio"
                                          value="Undilated"
                                          type="checkbox"
                                          name="cough2"
                                          onChange={(e) => {
                                            pressureHandler(e);
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">Undilated</label>
                                      </div>
                                      <div className="d-flex">
                                        <input
                                          className="custom-radio"
                                          value="Dilated"
                                          type="checkbox"
                                          name="cough2"
                                          onChange={(e) => {
                                            pressureHandler(e);
                                            setIsPressureDilated(
                                              !isPressureDilated
                                            );
                                          }}
                                          id="flexRadioDefault1"
                                        />
                                        <label className="">Dilated</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-8">
                                    <div className="row p-1 border">
                                      <div className="col-6 border-end row">
                                        <span>Pre Dilation</span>
                                        <span className="">
                                          Start Time :
                                          <input
                                            type="time"
                                            onChange={(e) =>
                                              setIntraOcularPressureStartTime(
                                                e.target.value
                                              )
                                            }
                                            className="w-"
                                          />
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        <span className="ms-2">
                                          Post Dilation
                                        </span>
                                        <span className="ms-2 d-block">
                                          End Time :
                                          <input
                                            type="time"
                                            onChange={(e) =>
                                              setIntraOcularPressureEndTime(
                                                e.target.value
                                              )
                                            }
                                            defaultValue={
                                              intraOcularPressureEndTime
                                            }
                                            className="w-"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mt-1 row">
                                      <div className="col-6 border">
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <label> Right</label>
                                          </div>
                                          <div className="col-6">
                                            <input
                                              type="text"
                                              onChange={(e) =>
                                                setIntraOcularPressureLeftPre(
                                                  e.target.value
                                                )
                                              }
                                              className="form-control form-control-sm"
                                            />
                                          </div>
                                          <div className="col-3">mmHg</div>
                                        </div>

                                        <div className="row mb-1">
                                          <div className="col-3">
                                            <label> Left</label>
                                          </div>
                                          <div className="col-6">
                                            <input
                                              type="text"
                                              onChange={(e) =>
                                                setIntraOcularPressureRightPre(
                                                  e.target.value
                                                )
                                              }
                                              className="form-control form-control-sm"
                                            />
                                          </div>
                                          <div className="col-3">mmHg</div>
                                        </div>
                                      </div>
                                      <div className="col-6 border">
                                        <div className="row mb-2">
                                          <div className="col-3">
                                            <label> Right</label>
                                          </div>
                                          <div className="col-6">
                                            <input
                                              type="text"
                                              onChange={(e) =>
                                                setIntraOcularPressureLeftPost(
                                                  e.target.value
                                                )
                                              }
                                              className="form-control form-control-sm"
                                            />
                                          </div>
                                          <div className="col-3">mmHg</div>
                                        </div>
                                        <div className="row mb-1">
                                          <div className="col-3">
                                            <label> Left</label>
                                          </div>
                                          <div className="col-6">
                                            <input
                                              type="text"
                                              onChange={(e) =>
                                                setIntraOcularPressureRightPost(
                                                  e.target.value
                                                )
                                              }
                                              className="form-control form-control-sm"
                                            />
                                          </div>
                                          <div className="col-3">mmHg</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  {isPressureDilated && (
                                    <div className="border row mt-2 p-2">
                                      <div className="form-check col-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={`Cyclopentolate : 1%`}
                                          onChange={(e) =>
                                            handleIntraOcularPressure(e)
                                          }
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Cyclopentolate 1%
                                        </label>
                                      </div>
                                      <div className="form-check col-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={`Tropicamide : 1%`}
                                          onChange={(e) =>
                                            handleIntraOcularPressure(e)
                                          }
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Tropicamide 1%
                                        </label>
                                      </div>
                                      <div className="form-check col-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={`Tropicamide : 0.5%`}
                                          onChange={(e) =>
                                            handleIntraOcularPressure(e)
                                          }
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Tropicamide 0.5%
                                        </label>
                                      </div>
                                      <div className="form-check col-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={`Phenylephrine : 2.5%`}
                                          onChange={(e) =>
                                            handleIntraOcularPressure(e)
                                          }
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Phenylephrine 2.5%
                                        </label>
                                      </div>
                                      <div className="form-check col-4">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={`Phenylephrine : 10%`}
                                          onChange={(e) =>
                                            handleIntraOcularPressure(e)
                                          }
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          Phenylephrine 10%
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value ">
                      <p className="ms-1">
                        {intraOcularPressure.toString()}
                        {intraOcularPressureCheckbox.map((item, i) => (
                          <span key={i}>{item} ,</span>
                        ))}
                      </p>
                      <p>
                        {intraOcularPressureRightPre && (
                          <span>Pre R: {intraOcularPressureRightPre}mmHg</span>
                        )}
                        {intraOcularPressureLeftPre && (
                          <span>Pre L: {intraOcularPressureLeftPre}mmHg ,</span>
                        )}
                      </p>
                      <p>
                        {intraOcularPressureRightPost && (
                          <span>
                            Post R: {intraOcularPressureRightPost}mmHg
                          </span>
                        )}
                        {intraOcularPressureLeftPost && (
                          <span>
                            Post L: {intraOcularPressureLeftPost}mmHg ,
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="exam-bg-white p-1">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Cover Test</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange8(!change8);
                              setCoverDistanceTropia();
                              setCoverDistancePhoria("");
                              setCoverNearTropia("");
                              setCoverNearPhoria("");
                              setCoverDistanceTropiaType([]);
                              setCoverNearTropiaType([]);
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl8(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change8 && (
                            <Popover
                              id={id8}
                              open={open8}
                              anchorEl={anchorEl8}
                              onClose={() => setAnchorEl8(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div className="row eye-large-popup p-1">
                                  <div className="col-6 text-center border rounded">
                                    <h6 className="my-1">Distance</h6>
                                    <div className="row border-top">
                                      <div className="col-6 border-right">
                                        <h6 className="border-bottom">
                                          Phoria
                                        </h6>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="ESO"
                                            type="radio"
                                            name="cough2"
                                            onChange={(e) => {
                                              setCoverDistancePhoria(
                                                e.target.value
                                              );
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">ESO</label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="EXO"
                                            type="radio"
                                            name="cough2"
                                            onChange={(e) => {
                                              setCoverDistancePhoria(
                                                e.target.value
                                              );
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">EXO</label>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <h6 className="border-bottom">
                                          Tropia
                                        </h6>
                                        <div className="border-bottom">
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="ESO"
                                              type="radio"
                                              name="near"
                                              onChange={(e) => {
                                                setCoverDistanceTropia(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">ESO</label>
                                          </div>
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="EXO"
                                              type="radio"
                                              name="near"
                                              onChange={(e) => {
                                                setCoverDistanceTropia(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">EXO</label>
                                          </div>
                                        </div>
                                        <div className="border-bottom">
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="Right"
                                              type="radio"
                                              name="near2"
                                              onChange={(e) => {
                                                setCoverDistanceTropiaSide(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">Right</label>
                                          </div>
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="Left"
                                              type="radio"
                                              name="near2"
                                              onChange={(e) => {
                                                setCoverDistanceTropiaSide(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">Left</label>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Alternative"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverDistanceTropiaType([
                                                  ...coverDistanceTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverDistanceTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverDistanceTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">
                                            Alternating
                                          </label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Intermittent"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverDistanceTropiaType([
                                                  ...coverDistanceTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverDistanceTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverDistanceTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">
                                            Intermittent
                                          </label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Constant"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverDistanceTropiaType([
                                                  ...coverDistanceTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverDistanceTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverDistanceTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">Constant</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 text-center border rounded">
                                    <h6 className="my-1">Near</h6>
                                    <div className="row border-top">
                                      <div className="col-6 border-right">
                                        <h6 className="border-bottom">
                                          Phoria
                                        </h6>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="ESO"
                                            type="radio"
                                            name="distance"
                                            onChange={(e) => {
                                              setCoverNearPhoria(
                                                e.target.value
                                              );
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">ESO</label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="EXO"
                                            type="radio"
                                            name="distance"
                                            onChange={(e) => {
                                              setCoverNearPhoria(
                                                e.target.value
                                              );
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">EXO</label>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <h6 className="border-bottom">
                                          Tropia
                                        </h6>
                                        <div className="border-bottom">
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="ESO"
                                              type="radio"
                                              name="cough6"
                                              onChange={(e) => {
                                                setCoverNearTropia(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">ESO</label>
                                          </div>
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="EXO"
                                              type="radio"
                                              name="cough6"
                                              onChange={(e) => {
                                                setCoverNearTropia(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">EXO</label>
                                          </div>
                                        </div>
                                        <div className="border-bottom">
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="Right"
                                              type="radio"
                                              name="cough7"
                                              onChange={(e) => {
                                                setCoverNearTropiaSide(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">Right</label>
                                          </div>
                                          <div className="d-flex">
                                            <input
                                              className="custom-radio"
                                              value="Left"
                                              type="radio"
                                              name="cough7"
                                              onChange={(e) => {
                                                setCoverNearTropiaSide(
                                                  e.target.value
                                                );
                                              }}
                                              id="flexRadioDefault1"
                                            />
                                            <label className="">Left</label>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Alternative"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverNearTropiaType([
                                                  ...coverNearTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverNearTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverNearTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">
                                            Alternating
                                          </label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Intermittent"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverNearTropiaType([
                                                  ...coverNearTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverNearTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverNearTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">
                                            Intermittent
                                          </label>
                                        </div>
                                        <div className="d-flex">
                                          <input
                                            className="custom-radio"
                                            value="Constant"
                                            type="checkbox"
                                            name="near3"
                                            onChange={(e) => {
                                              const { checked, value } =
                                                e.target;
                                              if (checked) {
                                                setCoverNearTropiaType([
                                                  ...coverNearTropiaType,
                                                  value,
                                                ]);
                                              } else {
                                                const newTropia =
                                                  coverNearTropiaType.filter(
                                                    (item) => item !== value
                                                  );
                                                setCoverNearTropiaType(
                                                  newTropia
                                                );
                                              }
                                            }}
                                            id="flexRadioDefault1"
                                          />
                                          <label className="">Constant</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p className="ms-1">
                        {coverDistancePhoria && `D - P: ${coverDistancePhoria}`}
                        {coverDistanceTropia &&
                          `,T -  ${coverDistanceTropia}, ${coverDistanceTropiaSide}, ${coverDistanceTropiaType.toString()} `}
                        {(coverDistanceTropia || coverDistancePhoria) && " | "}
                        {coverNearPhoria && `N : P - ${coverNearPhoria}`}
                        {coverNearTropia &&
                          `,T -  ${coverNearTropia},${coverNearTropiaSide},${coverNearTropiaType.toString()} `}
                        {(coverNearTropia || coverNearPhoria) && " | "}
                      </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Slit lamp Biomicroscopy</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange13(!change13);
                              setCoverDistanceTropia();
                              setCoverDistancePhoria("");
                              setCoverNearTropia("");
                              setCoverNearPhoria("");
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl13(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change13 && (
                            <Popover
                              id={id13}
                              open={open13}
                              anchorEl={anchorEl13}
                              onClose={() => setAnchorEl13(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <div
                                style={{ width: "500px" }}
                                className="left-popup"
                              >
                                <div className="border rounded p-1">
                                  <div ref={refSitLamp}>
                                    <ImageEditor
                                      includeUI={{
                                        loadImage: {
                                          path: eyeGroup,
                                          name: "SampleImage",
                                        },
                                        theme: whiteTheme,
                                        menu: ["draw"],
                                        initMenu: "draw",
                                        uiSize: {
                                          width: "433px",
                                          height: "330px",
                                        },
                                        menuBarPosition: "top",
                                      }}
                                      cssMaxHeight={400}
                                      cssMaxWidth={500}
                                      selectionStyle={{
                                        cornerSize: 20,
                                        rotatingPointOffset: 70,
                                      }}
                                      usageStatistics={false}
                                    />
                                  </div>

                                  <div className="rx-one-button-group d-flex justify-content-end">
                                    <button
                                      onClick={getSitLampImage}
                                      className="btn"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <textarea
                                    name=""
                                    id=""
                                    onChange={(e) => setSitLamp(e.target.value)}
                                    className="form-control"
                                    cols="30"
                                    rows="6"
                                    placeholder="Write here"
                                  ></textarea>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p className="ms-1">{sitLamp} </p>
                    </div>
                  </div>
                  <div className="exam-bg-white p-1 mt-2">
                    <div className="d-flex justify-content-between">
                      <p className="w-75">Pachymetry</p>
                      <div className="ms-1">
                        <label className="switch me-1">
                          <input
                            name="Jaundiced"
                            value="Jaundiced"
                            type="checkbox"
                            onChange={(e) => {
                              setChange14(!change14);
                              setPachymetryRight("");
                              setPachymetryLeft("");
                            }}
                            id="togBtn"
                          />
                          <div
                            onClick={(e) => setAnchorEl14(e.currentTarget)}
                            className="slider round"
                          ></div>
                          {change14 && (
                            <Popover
                              id={id14}
                              open={open14}
                              anchorEl={anchorEl14}
                              onClose={() => setAnchorEl14(null)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <div
                                style={{ width: "200px" }}
                                className="left-popup"
                              >
                                <div className=" border p-1">
                                  <label> Right</label>
                                  <div className="row mb-2">
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          setPachymetryRight(e.target.value)
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                    <div className="col-4">Microns</div>
                                  </div>
                                  <label> Left</label>
                                  <div className="row mb-1">
                                    <div className="col-8">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          setPachymetryLeft(e.target.value)
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </div>
                                    <div className="col-4">Microns</div>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="history-popup-value d-flex">
                      <p className="ms-1">
                        {pachymetryRight && `R - ${pachymetryRight} microns`}
                        {pachymetryRight && ` | `}
                        {pachymetryLeft && `L - ${pachymetryLeft} microns`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-4">
                <div className="exam-bg-white p-1 mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Keratometry</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange15(!change15);
                            setKRight1("");
                            setKRight2("");
                            setKRight3("");
                            setKRightD3("");
                            setKRightD2("");
                            setKRightD1("");
                            setKRightAt1("");
                            setKRightAt2("");
                            setKRightCly("");
                            setKRightClyAt("");
                            setKLeft1("");
                            setKLeft2("");
                            setKLeft3("");
                            setKLeftD3("");
                            setKLeftD2("");
                            setKLeftD1("");
                            setKLeftAt1("");
                            setKLeftAt2("");
                            setKLeftCly("");
                            setKLeftClyAt("");
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl15(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change15 && (
                          <Popover
                            id={id15}
                            open={open15}
                            anchorEl={anchorEl15}
                            onClose={() => setAnchorEl15(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{ width: "500px" }}
                              className="left-popup"
                            >
                              <div className=" border p-1 pb-2">
                                <div className="row">
                                  <div className="col-3">
                                    <label>Consultation date: </label>
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="date"
                                      className="form-control form-control-sm ms -1"
                                    />
                                  </div>
                                </div>
                                <span>K Reading</span>
                                <div
                                  ref={KeratometryRef}
                                  className="row mt-2 k-reading"
                                >
                                  <div className="border col-6">
                                    <div className="border-bottom text-center">
                                      <span className="my-1">Right</span>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setKRight1(
                                              parseFloat(e.target.value / 2)
                                            );
                                            e.target.value.length > 0
                                              ? setKRightD1(
                                                  parseFloat(
                                                    1000 / e.target.value
                                                  ).toFixed(2)
                                                )
                                              : setKRightD1("");
                                          }}
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kRightD1}
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKRightAt1(
                                              parseFloat(e.target.value)
                                            )
                                          }
                                          className="form-control form-control-sm w-75"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setKRight2(
                                              parseFloat(e.target.value / 2)
                                            );
                                            e.target.value.length > 0
                                              ? setKRightD2(
                                                  parseFloat(
                                                    1000 / e.target.value
                                                  ).toFixed(2)
                                                )
                                              : setKRightD2("");
                                          }}
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kRightD2}
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKRightAt2(e.target.value)
                                          }
                                          className="form-control form-control-sm w-75"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kRight3}
                                          readOnly
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kRightD3}
                                          readOnly
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-3"></div>
                                      <div className="col-5 d-flex">
                                        Cly
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKRightCly(e.target.value)
                                          }
                                          className="form-control form-control-sm"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKRightClyAt(e.target.value)
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border col-6">
                                    <div className="border-bottom text-center">
                                      <span className="my-1">Left</span>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setKLeft1(
                                              parseFloat(e.target.value / 2)
                                            );
                                            e.target.value.length > 0
                                              ? setKLeftD1(
                                                  parseFloat(
                                                    1000 / e.target.value
                                                  ).toFixed(2)
                                                )
                                              : setKLeftD1("");
                                          }}
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kLeftD1}
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKLeftAt1(
                                              parseFloat(e.target.value)
                                            )
                                          }
                                          className="form-control form-control-sm w-75"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            setKLeft2(
                                              parseFloat(e.target.value / 2)
                                            );
                                            e.target.value.length > 0
                                              ? setKLeftD2(
                                                  parseFloat(
                                                    1000 / e.target.value
                                                  ).toFixed(2)
                                                )
                                              : setKLeftD2("");
                                          }}
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kLeftD2}
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKLeftAt2(e.target.value)
                                          }
                                          className="form-control form-control-sm w-75"
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kLeft3}
                                          readOnly
                                          className="form-control form-control-sm w-75"
                                        />
                                        mm
                                      </div>
                                      <div className="col-4 d-flex">
                                        <input
                                          type="number"
                                          defaultValue={kLeftD3}
                                          readOnly
                                          className="form-control form-control-sm w-75"
                                        />
                                        D
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="col-3"></div>
                                      <div className="col-5 d-flex">
                                        Cly
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKLeftCly(e.target.value)
                                          }
                                          className="form-control form-control-sm"
                                        />
                                        D
                                      </div>
                                      <div className="col-4 d-flex">
                                        at
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setKLeftClyAt(e.target.value)
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="rx-one-button-group d-flex justify-content-end">
                                  <button
                                    onClick={() => {
                                      setAnchorEl15(null);
                                      takeKeratometryScrenShot(
                                        KeratometryRef.current
                                      );
                                    }}
                                    className="btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="history-popup-value d-flex">
                    <p className="ms-1">
                      {kRight3 && `Right - ${kRight3} mm ${kRightD3} at`}
                      {kRightAt1} {(kRightD3 || kRight3) && ` | `}
                      {kLeft3 && `Left - ${kLeft3} mm ${kLeftD3} at`} {kLeftAt1}
                    </p>
                  </div>
                </div>
                <div className="exam-bg-white p-1 mt-2">
                  <div className="d-flex justify-content-between">
                    <p className="w-75">Previous Spectacles RX</p>
                    <div className="ms-1">
                      <label className="switch me-1">
                        <input
                          name="Jaundiced"
                          value="Jaundiced"
                          type="checkbox"
                          onChange={(e) => {
                            setChange20(!change20);
                            setPreviousSpectacles1({
                              date: today,
                              optom: "",
                              type: "",
                              sphereRight: "",
                              cylRight: "",
                              axisRight: "",
                              addRight: "",
                              interRight: "",
                              sphereLeft: "",
                              cylLeft: "",
                              axisLeft: "",
                              addLeft: "",
                              interLeft: "",
                              noteLeft: "",
                            });
                          }}
                          id="togBtn"
                        />
                        <div
                          onClick={(e) => setAnchorEl20(e.currentTarget)}
                          className="slider round"
                        ></div>
                        {change20 && (
                          <Popover
                            id={id20}
                            open={open20}
                            anchorEl={anchorEl20}
                            onClose={() => setAnchorEl20(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div
                              style={{ width: "780px", marginRight: "3px" }}
                              className="left-popup eye-spack-popup"
                            >
                              <div className="eye-lens-popup">
                                <table ref={PreviousSpectaclesRef}>
                                  <tr>
                                    <td colSpan={8} className="text-center">
                                      Right
                                    </td>
                                    <td colSpan={6} className="text-center">
                                      Left
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>Optom</td>
                                    <td>Type</td>
                                    <td>Sphere</td>
                                    <td>Cyl</td>
                                    <td>Axis</td>
                                    <td>Add</td>
                                    <td>Inter</td>
                                    <td>Sphere</td>
                                    <td>Cyl</td>
                                    <td>Axis</td>
                                    <td>Add</td>
                                    <td>Inter</td>
                                    <td>Note</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        defaultValue={previousSpectacles1.date}
                                        type="date"
                                        onChange={(e) =>
                                          setPreviousSpectacles1({
                                            ...previousSpectacles1,
                                            date: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.optom ? (
                                        <input
                                          autoFocus={focus === "optom"}
                                          defaultValue={
                                            previousSpectacles1.optom
                                          }
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.optom !== "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                optom: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              optom: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              optom: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              optom: true,
                                            });
                                            setFocus("optom");
                                          }}
                                        >
                                          {previousSpectaclesValue1.optom}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.type ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.type
                                          }
                                          autoFocus={focus === "type"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.type !== "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                type: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              type: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              type: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              type: true,
                                            });
                                            setFocus("type");
                                          }}
                                        >
                                          {previousSpectaclesValue1.type}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive1.sphereRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.sphereRight
                                          }
                                          autoFocus={focus === "sphereRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.sphereRight !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                sphereRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              sphereRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              sphereRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              sphereRight: true,
                                            });
                                            setFocus("sphereRight");
                                          }}
                                        >
                                          {previousSpectaclesValue1.sphereRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.cylRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.cylRight
                                          }
                                          autoFocus={focus === "cylRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.cylRight !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                cylRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              cylRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              cylRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              cylRight: true,
                                            });
                                            setFocus("cylRight");
                                          }}
                                        >
                                          {previousSpectaclesValue1.cylRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles1({
                                            ...previousSpectacles1,
                                            axisRight: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.addRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.addRight
                                          }
                                          autoFocus={focus === "addRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.addRight !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                addRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              addRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              addRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              addRight: true,
                                            });
                                            setFocus("addRight");
                                          }}
                                        >
                                          {previousSpectaclesValue1.addRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.interRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.interRight
                                          }
                                          autoFocus={focus === "interRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.interRight !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                interRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              interRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              interRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              interRight: true,
                                            });
                                            setFocus("interRight");
                                          }}
                                        >
                                          {previousSpectaclesValue1.interRight}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive1.sphereLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.sphereLeft
                                          }
                                          autoFocus={focus === "sphereLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.sphereLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                sphereLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              sphereLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              sphereLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              sphereLeft: true,
                                            });
                                            setFocus("sphereLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue1.sphereLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.cylLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.cylLeft
                                          }
                                          autoFocus={focus === "cylLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.cylLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                cylLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              cylLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              cylLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              cylLeft: true,
                                            });
                                            setFocus("cylLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue1.cylLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles1({
                                            ...previousSpectacles1,
                                            axisLeft: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.addLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.addLeft
                                          }
                                          autoFocus={focus === "addLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.addLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                addLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              addLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              addLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              addLeft: true,
                                            });
                                            setFocus("addLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue1.addLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive1.interLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles1.interLeft
                                          }
                                          autoFocus={focus === "interLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles1.interLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive1({
                                                ...previousSpectaclesActive1,
                                                interLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles1({
                                              ...previousSpectacles1,
                                              interLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue1({
                                              ...previousSpectaclesValue1,
                                              interLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive1({
                                              ...previousSpectaclesActive1,
                                              interLeft: true,
                                            });
                                            setFocus("interLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue1.interLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles1({
                                            ...previousSpectacles1,
                                            noteLeft: handleValueFormat(
                                              e.target.value
                                            ),
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        defaultValue={previousSpectacles2.date}
                                        type="date"
                                        onChange={(e) =>
                                          setPreviousSpectacles2({
                                            ...previousSpectacles2,
                                            date: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.optom ? (
                                        <input
                                          autoFocus={focus === "optom"}
                                          defaultValue={
                                            previousSpectacles2.optom
                                          }
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.optom !== "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                optom: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              optom: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              optom: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              optom: true,
                                            });
                                            setFocus("optom");
                                          }}
                                        >
                                          {previousSpectaclesValue2.optom}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.type ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.type
                                          }
                                          autoFocus={focus === "type"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.type !== "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                type: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              type: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              type: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              type: true,
                                            });
                                            setFocus("type");
                                          }}
                                        >
                                          {previousSpectaclesValue2.type}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive2.sphereRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.sphereRight
                                          }
                                          autoFocus={focus === "sphereRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.sphereRight !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                sphereRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              sphereRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              sphereRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              sphereRight: true,
                                            });
                                            setFocus("sphereRight");
                                          }}
                                        >
                                          {previousSpectaclesValue2.sphereRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.cylRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.cylRight
                                          }
                                          autoFocus={focus === "cylRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.cylRight !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                cylRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              cylRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              cylRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              cylRight: true,
                                            });
                                            setFocus("cylRight");
                                          }}
                                        >
                                          {previousSpectaclesValue2.cylRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles2({
                                            ...previousSpectacles2,
                                            axisRight: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.addRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.addRight
                                          }
                                          autoFocus={focus === "addRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.addRight !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                addRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              addRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              addRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              addRight: true,
                                            });
                                            setFocus("addRight");
                                          }}
                                        >
                                          {previousSpectaclesValue2.addRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.interRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.interRight
                                          }
                                          autoFocus={focus === "interRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.interRight !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                interRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              interRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              interRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              interRight: true,
                                            });
                                            setFocus("interRight");
                                          }}
                                        >
                                          {previousSpectaclesValue2.interRight}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive2.sphereLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.sphereLeft
                                          }
                                          autoFocus={focus === "sphereLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.sphereLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                sphereLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              sphereLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              sphereLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              sphereLeft: true,
                                            });
                                            setFocus("sphereLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue2.sphereLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.cylLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.cylLeft
                                          }
                                          autoFocus={focus === "cylLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.cylLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                cylLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              cylLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              cylLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              cylLeft: true,
                                            });
                                            setFocus("cylLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue2.cylLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles2({
                                            ...previousSpectacles2,
                                            axisLeft: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.addLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.addLeft
                                          }
                                          autoFocus={focus === "addLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.addLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                addLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              addLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              addLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              addLeft: true,
                                            });
                                            setFocus("addLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue2.addLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive2.interLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles2.interLeft
                                          }
                                          autoFocus={focus === "interLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles2.interLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive2({
                                                ...previousSpectaclesActive2,
                                                interLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles2({
                                              ...previousSpectacles2,
                                              interLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue2({
                                              ...previousSpectaclesValue2,
                                              interLeft: e.target.value,
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive2({
                                              ...previousSpectaclesActive2,
                                              interLeft: true,
                                            });
                                            setFocus("interLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue2.interLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles2({
                                            ...previousSpectacles2,
                                            noteLeft: handleValueFormat(
                                              e.target.value
                                            ),
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        defaultValue={previousSpectacles3.date}
                                        type="date"
                                        onChange={(e) =>
                                          setPreviousSpectacles3({
                                            ...previousSpectacles3,
                                            date: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.optom ? (
                                        <input
                                          autoFocus={focus === "optom"}
                                          defaultValue={
                                            previousSpectacles3.optom
                                          }
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.optom !== "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                optom: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              optom: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              optom: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              optom: true,
                                            });
                                            setFocus("optom");
                                          }}
                                        >
                                          {previousSpectaclesValue3.optom}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.type ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.type
                                          }
                                          autoFocus={focus === "type"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.type !== "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                type: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              type: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              type: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              type: true,
                                            });
                                            setFocus("type");
                                          }}
                                        >
                                          {previousSpectaclesValue3.type}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive3.sphereRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.sphereRight
                                          }
                                          autoFocus={focus === "sphereRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.sphereRight !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                sphereRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              sphereRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              sphereRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              sphereRight: true,
                                            });
                                            setFocus("sphereRight");
                                          }}
                                        >
                                          {previousSpectaclesValue3.sphereRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.cylRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.cylRight
                                          }
                                          autoFocus={focus === "cylRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.cylRight !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                cylRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              cylRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              cylRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              cylRight: true,
                                            });
                                            setFocus("cylRight");
                                          }}
                                        >
                                          {previousSpectaclesValue3.cylRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles3({
                                            ...previousSpectacles3,
                                            axisRight: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.addRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.addRight
                                          }
                                          autoFocus={focus === "addRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.addRight !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                addRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              addRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              addRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              addRight: true,
                                            });
                                            setFocus("addRight");
                                          }}
                                        >
                                          {previousSpectaclesValue3.addRight}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.interRight ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.interRight
                                          }
                                          autoFocus={focus === "interRight"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.interRight !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                interRight: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              interRight: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              interRight: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              interRight: true,
                                            });
                                            setFocus("interRight");
                                          }}
                                        >
                                          {previousSpectaclesValue3.interRight}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {previousSpectaclesActive3.sphereLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.sphereLeft
                                          }
                                          autoFocus={focus === "sphereLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.sphereLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                sphereLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              sphereLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              sphereLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              sphereLeft: true,
                                            });
                                            setFocus("sphereLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue3.sphereLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.cylLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.cylLeft
                                          }
                                          autoFocus={focus === "cylLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.cylLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                cylLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              cylLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              cylLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              cylLeft: true,
                                            });
                                            setFocus("cylLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue3.cylLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles3({
                                            ...previousSpectacles3,
                                            axisLeft: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.addLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.addLeft
                                          }
                                          autoFocus={focus === "addLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.addLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                addLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              addLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              addLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              addLeft: true,
                                            });
                                            setFocus("addLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue3.addLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {previousSpectaclesActive3.interLeft ? (
                                        <input
                                          defaultValue={
                                            previousSpectacles3.interLeft
                                          }
                                          autoFocus={focus === "interLeft"}
                                          type="text"
                                          onBlur={() => {
                                            previousSpectacles3.interLeft !==
                                              "" &&
                                              setPreviousSpectaclesActive3({
                                                ...previousSpectaclesActive3,
                                                interLeft: false,
                                              });
                                          }}
                                          onChange={(e) => {
                                            setPreviousSpectacles3({
                                              ...previousSpectacles3,
                                              interLeft: e.target.value,
                                            });
                                            setPreviousSpectaclesValue3({
                                              ...previousSpectaclesValue3,
                                              interLeft: handleValueFormat(
                                                e.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setPreviousSpectaclesActive3({
                                              ...previousSpectaclesActive3,
                                              interLeft: true,
                                            });
                                            setFocus("interLeft");
                                          }}
                                        >
                                          {previousSpectaclesValue3.interLeft}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          setPreviousSpectacles3({
                                            ...previousSpectacles3,
                                            noteLeft: e.target.value,
                                          })
                                        }
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                </table>
                                <div className="rx-one-button-group d-flex justify-content-end">
                                  <button
                                    onClick={() => {
                                      setAnchorEl20(null);
                                      takePreviousSpectaclesScrenShot(
                                        PreviousSpectaclesRef.current
                                      );
                                    }}
                                    className="btn"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="history-popup-value d-flex"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile-eye"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="exam-bg-white mb-2">
              <div className="p-1 row">
                <div className="col-lg-7">
                  <div ref={ref} className="border rounded p-1">
                    <ImageEditor
                      includeUI={{
                        loadImage: {
                          path: eyeGroup,
                          name: "SampleImage",
                        },
                        theme: whiteTheme,
                        menu: ["draw"],
                        initMenu: "draw",
                        uiSize: {
                          width: "433px",
                          height: "330px",
                        },
                        menuBarPosition: "top",
                      }}
                      cssMaxHeight={500}
                      cssMaxWidth={700}
                      selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                      }}
                      usageStatistics={false}
                    />
                  </div>
                  <div className="mt-2 d-lg-none w-100 rx-one-button-group d-flex justify-content-end">
                    <button className="btn me-2" onClick={getImage}>
                      Save
                    </button>
                  </div>
                  <div className="d-lg-none eye-image-container row p-1">
                    {eyeImage.length > 0 &&
                      eyeImage.map((img, i) => (
                        <div key={i} className="col-4">
                          <i
                            onClick={() => removeEyeImage(img)}
                            className="far fa-times-circle float-end mt-1"
                          ></i>
                          <img src={img} alt="" className="img-fluid" />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-lg-5">
                  <textarea
                    name=""
                    id=""
                    className="form-control d-none d-lg-block"
                    cols="30"
                    rows="10"
                    placeholder="Write here"
                  ></textarea>
                  <textarea
                    name=""
                    id=""
                    className="form-control d-lg-none d-block"
                    cols="30"
                    rows="5"
                    placeholder="Write here"
                  ></textarea>
                  <div className="mt-2 d-none w-100 rx-one-button-group d-lg-flex justify-content-end">
                    <button className="btn me-2" onClick={getImage}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="eye-image-container row p-1">
                  {eyeImage.length > 0 &&
                    eyeImage.map((img, i) => (
                      <div key={i} className="col-4">
                        <i
                          onClick={() => removeEyeImage(img)}
                          className="far fa-times-circle float-end mt-1"
                        ></i>
                        <img src={img} alt="" className="img-fluid" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eye;
