import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import { AiFillPrinter } from "react-icons/ai";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import useUserData from "../../hooks/useUserData";
import useCredentialURL from "../../hooks/useCredentialURL";
import { getAllBranch } from "../../utils/getAllBranch";
import SimpleSelect from "../../common/components/SimpleSelect";
import CountUp from "react-countup";
import { VscPreview } from "react-icons/vsc";
import { nullParser } from "../../utils/null-parser";
import { NewModal } from "../../common/components/NewModal";
import Input from "../../common/components/input";
import moment from "moment";
import Pagination from "../../common/components/Pagination";
import { QueryContext } from "../../context/Query-conext";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Button from "../../common/components/Button";
export const debounce = (func, delay = 400) => {
  let debounceTimer;
  // Ensure the delay is at least 300ms
  const adjustedDelay = Math.max(300, delay);

  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), adjustedDelay);
  };
};

const GreatLabMoneyReciept = () => {
  const [loading, setLoading] = useState(true);
  const [totalInvoice, setTotalInvoice] = useState([]);
  const [totalInvoiceSort, setTotalInvoiceSort] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const user = useUserData();
  const { SaasAuthURL } = useCredentialURL();
  const [orgBranch, setOrgBranch] = useState([]);
  const [transaction, setTransaction] = useState({});
  // Start Pagination logic
  const [totalCount, setTotalCount] = useState(0);
  const { query, setQuery, resetQuery, refreshQuery } =
    useContext(QueryContext);

  const page = useMemo(() => {
    return query.get("page") || 1;
  }, [query]);

  const limit = useMemo(() => {
    return query.get("limit") || 10;
  }, [query]);
  const search = useMemo(() => {
    return query.get("search") || "";
  }, [query]);

  const handleChangePage = (newPage) => {
    setQuery({ page: newPage, limit });
  };

  const handleChangeRowsPerPage = (newLimit) => {
    setQuery({ limit: newLimit, page: 1 }); // Reset to page 1 when page size changes
  };

  const handleSearch = (e) => {
    setQuery({ search: e.target.value });
  };

  const pageOptions = [10, 20, 50, 100];
  // End Pagination logic
  const [updateData, setUpdateData] = useState("");
  useEffect(() => {
    const getBranch = async () => {
      const branches = await getAllBranch(
        SaasAuthURL + "/branch/service/find-branch-by-organizationId"
      );
      if (branches.status === 200) {
        const updatedBranches = branches?.data?.data?.map((branch) => ({
          ...branch,
          value: branch.id,
          label: branch.name,
        }));
        setOrgBranch(updatedBranches);
      }
    };
    getBranch();
    return () => {};
  }, [SaasAuthURL]);

  useEffect(() => {
    if (selectedBranch) {
      const filterData = totalInvoice.filter((item) => {
        return item.saas_branch_id === selectedBranch?.value;
      });
      setTotalInvoiceSort(filterData);
    } else {
      setTotalInvoiceSort(totalInvoice);
    }
    return () => {};
  }, [selectedBranch, totalInvoice]);

  const handleChangeBranch = (data) => {
    if (data) {
      setSelectedBranch(data);
    } else {
      setSelectedBranch(null);
    }
  };

  useEffect(() => {
    axios
      .get(`/great-lab-all-invoice`, { params: { page, limit, search } })
      .then((res) => {
        if (res?.status === 200) {
          setTotalInvoice(res?.data?.data);
          setTotalInvoiceSort(res?.data?.data);
          setTotalCount(res?.data?.total);
          setLoading(false);
        }
      });
    return () => {};
  }, [page, limit, search, updateData]);

  useEffect(() => {
    axios
      .get("/great-lab-transactions")
      .then((res) => {
        setTransaction(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    return () => {};
  }, []);

  const handleInvoicePreview = (data) => {
    setSelectedInvoice(data);
    setPreviewModal(true);
  };
  const handleInvoiceEdit = (data) => {
    setSelectedInvoice(data);
    setEditModal(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = [
    {
      title: "Invoice",
      field: `invoiceNo`,

      cellStyle: {
        width: "2%",
      },
    },
    {
      title: "Name",
      field: `patient_first_name`,
    },
    {
      title: "Branch",
      field: `saas_branch_name`,
    },
    {
      title: "Total Amount",
      field: `totalBill`,
      render: (row) => {
        return parseFloat(row.totalBill).toFixed(2);
      },
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Paid Amount",
      field: `paidAmount`,
      render: (row) => {
        return parseFloat(row.paidAmount).toFixed(2);
      },
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Refund Amount",
      field: `paidAmount`,
      render: (row) => {
        return parseFloat(row.refundAmount).toFixed(2);
      },
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Due",
      field: `due`,
      render: (row) => {
        return parseFloat(row.due).toFixed(2);
      },
    },
    {
      title: "Discount",
      field: `discount`,
      render: (row) => {
        const totalDiscount =
          parseFloat(row?.specialDiscount || 0) +
          parseFloat(row?.discount || 0);
        return `${parseFloat(totalDiscount).toFixed(2)}`;
      },
    },
    {
      title: "Payment Method",
      field: "paymentMethod",
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Action",
      field: "patient",
      render: (row) => (
        <div className="d-flex align-items-center  gap-2  justify-content-center">
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleInvoice(row)}
            className={`new-action-btn `}
          >
            <AiFillPrinter />
          </button>
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleInvoicePreview(row)}
            className={`new-action-btn `}
          >
            <VscPreview />
          </button>
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleInvoiceEdit(row)}
            className={`new-action-btn `}
          >
            <BiEdit size={18} />
          </button>
          &nbsp;
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  // search with date range
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const searchByDateRange = () => {
    if (dateRange?.startDate && dateRange.endDate) {
      axios
        .post("great-lab-invoice-date-range-search", dateRange)
        .then((res) => {
          if (res.status === 200) {
            setTotalInvoice(res.data.invoice);
            setTotalInvoiceSort(res.data.invoice);
            setTransaction(res.data.totals);
          }
        });
    } else {
      toast.error("Please enter start and end date!");
    }
  };
  // print test List
  const [selectedTest, setSelectedTest] = useState([]);
  // const testRef = useRef();

  const [moneyReceiptData, setMoneyReceiptData] = useState({});
  const invoiceRef = useRef();
  const handleInvoicePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const handleInvoice = (item) => {
    setMoneyReceiptData(item);
    setTimeout(() => handleInvoicePrint(), 1000);
  };
  // const totalBill = moneyReceiptData?.tests?.reduce(
  //   (total, current) => total + Number(current.fee),
  //   0
  // );
  // const discountTotal = moneyReceiptData?.tests?.reduce(
  //   (total, current) => total + Number(current.discount),
  //   0
  // );
  const handleDue = () => {
    const filtered = totalInvoice.filter((item) => Number(item.due) > 0);
    setTotalInvoiceSort(filtered);
  };
  const handlePaid = () => {
    const filtered = totalInvoice.filter((item) => Number(item.paidAmount) > 0);
    setTotalInvoiceSort(filtered);
  };

  const filterColumns = useMemo(() => {
    if (user?.isSuperAdmin) {
      return columns;
    } else {
      return columns.filter((item) => item.field !== "saas_branch_name");
    }
  }, [columns, user?.isSuperAdmin]);

  const handleCloseModal = () => {
    setPreviewModal(false);
    setSelectedInvoice(null);
  };
  const handleCloseEditModal = () => {
    setEditModal(false);
    setSelectedInvoice(null);
  };

  const selectedMoneyReceipt = moneyReceiptData;
  console.log(selectedMoneyReceipt, "moneyReceiptData");
  return (
    <div className="ms-2 lab-agent all-patients mt-2">
      <div className="row">
        <div className="col-md-12">
          <div className="patients-head custom-card d-flex justify-content-between align-items-center">
            <h5 className="fw-normal ml-3 text-start mb-1 text-login py-2">
              Great Lab
            </h5>
            {user?.isSuperAdmin && (
              <SimpleSelect
                options={orgBranch}
                value={selectedBranch}
                placeholder="Select Branch"
                onChange={handleChangeBranch}
              />
            )}
          </div>
          <div className="custom-card p-2 mt-2">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <h6 className="my-0">Invoice List</h6>
              </div>
              <div
                className="col-9 d-flex justify-content-end align-items-center"
                style={{ gap: "15px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <label className="fw-bold my-0"> Date</label>
                  <input
                    onChange={(e) =>
                      setDateRange({ ...dateRange, startDate: e.target.value })
                    }
                    type="date"
                    className="form-control form-control-sm"
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <label className="fw-bold my-0"> To</label>
                  <input
                    onChange={(e) =>
                      setDateRange({ ...dateRange, endDate: e.target.value })
                    }
                    type="date"
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="rx-one-button-group">
                  <button onClick={searchByDateRange} className="btn">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mx- my-2">
            <div className="row">
              <div onClick={handlePaid} className="col-4">
                <div className="transaction-card tran__card_1 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Invoice : {totalCount}</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    <span style={{ fontSize: "22px" }} className="me-2">
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      decimals={2}
                      end={
                        parseFloat(transaction?.total_invoice).toFixed(2) || 0
                      }
                      duration={2}
                    />
                  </p>
                </div>
              </div>
              <div onClick={handlePaid} className="col-4">
                <div className=" transaction-card tran__card_2 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Received</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    <span style={{ fontSize: "22px" }} className="me-2">
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      decimals={2}
                      end={
                        parseFloat(transaction?.total_received).toFixed(2) || 0
                      }
                      duration={2}
                    />
                  </p>
                </div>
              </div>
              <div onClick={handleDue} className="col-4">
                <div className="transaction-card tran__card_3 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Due</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    <span style={{ fontSize: "22px" }} className="me-2">
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      decimals={2}
                      end={parseFloat(transaction?.total_due).toFixed(2) || 0}
                      duration={2}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="patient-table my-2"
            style={{
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <div className="d-flex align-items-center justify-content-between m-4">
              <Input
                placeholder="Search Name, Phone, Invoice No"
                style={{
                  width: "250px",
                  padding: "0.5rem 1rem",
                }}
                value={search}
                onChange={handleSearch}
              />
              <div className="d-flex align-items-center gap-1">
                <div className="rx-one-button-group">
                  <button
                    onClick={() => {
                      resetQuery();
                    }}
                    className="btn"
                  >
                    Clear
                  </button>
                </div>
                <div className="rx-one-button-group">
                  <button
                    onClick={() => {
                      refreshQuery();
                    }}
                    className="btn"
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  {filterColumns.map((item, i) => (
                    <th scope="col" key={i}>
                      {item.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {totalInvoiceSort.length > 0 ? (
                  <>
                    {totalInvoiceSort.map((item, i) => (
                      <tr key={i}>
                        {filterColumns.map((col, index) => (
                          <td key={index}>
                            {col.render ? col.render(item) : item[col.field]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={filterColumns.length} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              <div className="d-flex justify-content-end">
                <Pagination
                  limit={Number(limit)}
                  onChangePerPage={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  totalCount={Number(totalCount || 0)}
                  page={Number(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedTest.length > 0 && (
        <div className="print-money-receipt">
          <div
            ref={invoiceRef}
            className="money-receipt-container px-4 py-2 mt-4"
          >
            <span className="money-receipt-head">
              Online Payment Confirmation Or Offline Payment Confirmation
            </span>
            <div className="receipt-welcome-section mt-3">
              <p>Dear Sir / Madam</p>
              <p>Thank You</p>
              <p>For your payment for medical expense</p>
            </div>
            <div className="mt-3 receipt-pay-info">
              <h6>Payment Confirmation</h6>
              <hr />
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Money Receipt Number
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.money_receipt_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  HN
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.hn_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Name
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.name}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Invoice Number
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.invoice_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Requested Amount
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.requested_amount}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Amount Paid
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.paid_amount}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Payment Date
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_date}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Payment Time
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_time}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Channel
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_method}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Total Amount Paid
                </span>
                :
                <span className="receipt-details-value">
                  {moneyReceiptData?.total_amount_paid}
                </span>
              </p>
            </div>
            <div className="my-3">
              <p>
                If you have any questions or need help, please 'Reply all' to
                this email and we will reply as quickly as possible
              </p>
            </div>
            <div>
              <p>Sincerely,</p>
              <p>Al-Shifa Hospital</p>
              <p>Phone +66 2066 8888</p>
            </div>
            <div className="mt-5">
              <span className="bottom-nb">
                This e-mail message (and attachments) may contain information
                that is confidential to Bumiungrad International. If you are not
                the intended recipient you cannot use, dismbute or copy the
                message or attachments, In such a case, please nobfy the sender
                by return e-mail immedately and erase all copies of the message
                and attachments. Opinions, conclusions and other information in
                this message and attachments that do not relate to the official
                business of Bumningrad International are nether given no'
                endorsed by it.
              </span>
            </div>
            <div className="mt-2">
              <span className="receipt-end-line">
                Save the Environment — Think before you print this e-man
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="print-invoice">
        <div ref={invoiceRef} className="sales-invoice">
          {moneyReceiptData && (
            <div style={{ padding: "60px" }} className="invoice-print">
              <div className="invoice-pharmacy-details d-flex gap-2 align-items-center justify-content-start">
                <img
                  src={user?.organization_logo}
                  // src="https://gdsaasbackend.macrohealthplus.org/logo10240861-846c-4d2c-a8b6-aee9020f0337.jpeg"
                  alt="logo"
                  style={{ width: "80px", height: "80px" }}
                />
                <div className="text-start">
                  <h5>{user?.organization_name}</h5>
                  <p>
                    {user?.organization_address}
                    <span>Tel : {user?.organization_mobile}</span>
                  </p>
                  <p>(A Computerized Diagnostic and Consultation Centre)</p>
                </div>
              </div>
              <div className="row  mt-3 mb-1">
                <div className="row col-8">
                  <div className="col-6 d-flex align-items-center">
                    <p className="p-0 m-0">
                      Received No:
                      <span>{selectedMoneyReceipt?.invoiceNo || 10001}</span>
                    </p>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <h4
                      className="text-center py-1 px-4  m-0"
                      style={{
                        border: "1px dashed gray",
                        borderRadius: "20px",
                        display: "inline-block",
                        fontSize: "16px",
                      }}
                    >
                      Cash Memo
                    </h4>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <div className="d-flex justify-content-end align-items-center">
                    Date
                    <span style={{ width: "60px", display: "inline-block" }}>
                      :
                      {moment(selectedMoneyReceipt?.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ borderBottom: "1px dashed gray" }}>
                <div className="col-8">
                  <p className="m-0 p-0">
                    <span
                      style={{
                        width: "100px",
                        display: "inline-block",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      Patient Name
                    </span>
                    : {selectedMoneyReceipt?.patient?.fullName || "N/A"}
                  </p>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <div className="d-flex justify-content-end">
                    Age
                    <span
                      style={{ paddingLeft: "15px", display: "inline-block" }}
                    >
                      :{" "}
                      {moment().diff(
                        selectedMoneyReceipt?.patient?.patient_dob,
                        "years"
                      )}
                      Years
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <p className="m-0 p-0">
                    <span
                      style={{
                        width: "104px",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Prof./Dr.
                    </span>
                    : {selectedMoneyReceipt?.referredBy}
                  </p>
                </div>
              </div>
              <div className="invoice-item-table">
                <table>
                  <tr className="invoice-border-dashed">
                    <td>Examination</td>
                    <td>Rate</td>
                    <td colSpan={5} className="text-end">
                      Total
                    </td>
                  </tr>
                  {moneyReceiptData?.tests?.map((item, i) => (
                    <tr key={i}>
                      <td className="text-start">{item.testName}</td>
                      <td className="text-start">{item.fee}</td>
                      <td colSpan={5} className="text-end">
                        {parseFloat(item.fee).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr className="invoice-border-dashed-top">
                    <td rowSpan={5} colSpan={2}>
                      {Number(selectedMoneyReceipt?.due) > 0 && (
                        <div className=" d-flex justify-content-center align-items-center">
                          <p
                            style={{
                              fontSize: "22px",
                              whiteSpace: "nowrap",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            Due Amount:
                            {parseFloat(selectedMoneyReceipt?.due).toFixed(2)}
                          </p>
                        </div>
                      )}
                    </td>
                    <td colSpan={3} className="text-end">
                      Sub Total :
                    </td>
                    <td className="text-end">
                      {parseFloat(moneyReceiptData?.totalBill).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-end">
                      Discount :
                    </td>
                    <td className="text-end">
                      {parseFloat(
                        parseFloat(moneyReceiptData?.discount || 0) +
                          parseFloat(moneyReceiptData?.specialDiscount || 0)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Bill Total :
                    </td>
                    <td className="text-end">
                      {parseFloat(
                        parseFloat(moneyReceiptData?.totalBill) -
                          parseFloat(moneyReceiptData?.discount)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Paid :
                    </td>
                    <td className="text-end">
                      {parseFloat(selectedMoneyReceipt?.paidAmount).toFixed(2)}
                    </td>
                  </tr>
                  {Number(selectedMoneyReceipt?.refundAmount) > 0 && (
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Refund :
                      </td>
                      <td className="text-end">
                        {parseFloat(selectedMoneyReceipt?.refundAmount).toFixed(
                          2
                        )}
                      </td>
                    </tr>
                  )}

                  {Number(selectedMoneyReceipt?.due) > 0 && (
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Due :
                      </td>
                      <td className="text-end">
                        {parseFloat(selectedMoneyReceipt?.due).toFixed(2)}
                      </td>
                    </tr>
                  )}
                </table>
              </div>
              <br />
              <div className="d-flex invoice-creator justify-content-between mt-4">
                <p>
                  Delivery Date:
                  {moment(moneyReceiptData?.deliveryDate).format("DD/MM/YYYY")}-
                  {moment(moneyReceiptData?.deliveryTime, "HH:mm:ss").format(
                    "hh A"
                  )}
                </p>
                <p>Posted : {user?.name}</p>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <Barcode
                  displayValue="false"
                  lineColor="#333333"
                  width={2}
                  height={20}
                  value={selectedMoneyReceipt?.invoiceNo || 10001}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <GreatLabInvoicePreview
        data={selectedInvoice}
        isOpen={previewModal}
        onClose={handleCloseModal}
      />

      <GreatLabInvoiceEdit
        invoice={selectedInvoice}
        isOpen={editModal}
        onClose={handleCloseEditModal}
        setUpdateData={setUpdateData}
      />
    </div>
  );
};

export default GreatLabMoneyReciept;

const GreatLabInvoicePreview = ({ data, onClose, isOpen }) => {
  const invoiceRef = useRef();
  const [loading, setLoading] = useState(false);
  const [moneyReceipts, setMoneyReceipts] = useState([]);
  const [selectedMoneyReceipt, setSelectedMoneyReceipt] = useState(null);
  const user = useUserData();

  const handleMoneyPrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const handleMoney = (data) => {
    setSelectedMoneyReceipt(data);
    setTimeout(() => handleMoneyPrint(), 300);
  };

  useEffect(() => {
    if (data) {
      (async () => {
        setLoading(true);
        const res = await axios.get(
          `/great-lab-money-receipt-preview/${data?.id}`
        );
        if (res.data.status === 200) {
          setLoading(false);
          setMoneyReceipts(res?.data?.receipt);
        }
      })();
    }
  }, [data]);

  const columns = [
    {
      title: "Money Receipt No",
      field: `money_receipt_number`,
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Invoice No",
      field: `invoice_number`,
    },
    {
      title: "Date & Time",
      render: (row) => moment(row?.created_at).format("DD/MM/YYYY - hh A"),
    },
    {
      title: "Paid Amount",
      field: "paid_amount",
      render: (row) => parseFloat(row?.paid_amount).toFixed(2),
    },
    {
      title: "Due Amount",
      field: "due_amount",
      render: (row) => parseFloat(row?.due_amount).toFixed(2),
    },
    {
      title: "Action",
      field: "payment_method",
      render: (row) => (
        <div className="d-flex align-items-center  gap-2  justify-content-center">
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleMoney(row)}
            className={`new-action-btn `}
          >
            <AiFillPrinter />
          </button>
        </div>
      ),
    },
  ];
  return (
    <NewModal isOpen={isOpen} onClose={onClose} size="lg">
      <NewModal.Header onClose={onClose}>
        <NewModal.Title>Invoice Summary</NewModal.Title>
      </NewModal.Header>
      <NewModal.Body>
        <MaterialTable
          title={"Money Receipts"}
          columns={columns}
          data={moneyReceipts}
          isLoading={loading}
        />
        <div className="print-invoice">
          <div ref={invoiceRef} className="sales-invoice">
            {selectedMoneyReceipt && (
              <div style={{ padding: "60px" }} className="invoice-print">
                <div className="invoice-pharmacy-details d-flex gap-2 align-items-center justify-content-start">
                  <img
                    src={user?.organization_logo}
                    // src="https://gdsaasbackend.macrohealthplus.org/logo10240861-846c-4d2c-a8b6-aee9020f0337.jpeg"
                    alt="logo"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <div className="text-start">
                    <h5>{user?.organization_name}</h5>
                    <p>
                      {user?.organization_address}
                      <span>Tel : {user?.organization_mobile}</span>
                    </p>
                    <p>(A Computerized Diagnostic and Consultation Centre)</p>
                  </div>
                </div>
                <div className="row  mt-3 mb-1">
                  <div className="row col-8">
                    <div className="col-6 d-flex align-items-center">
                      <p className="p-0 m-0">
                        Received No:
                        <span>
                          {selectedMoneyReceipt?.money_receipt_number || 10001}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <h4
                        className="text-center py-1 px-4  m-0"
                        style={{
                          border: "1px dashed gray",
                          borderRadius: "20px",
                          display: "inline-block",
                          fontSize: "16px",
                        }}
                      >
                        Cash Memo
                      </h4>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end align-items-center">
                      Date
                      <span style={{ width: "60px", display: "inline-block" }}>
                        :
                        {moment(selectedMoneyReceipt?.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px dashed gray" }}
                >
                  <div className="col-8">
                    <p className="m-0 p-0">
                      <span
                        style={{
                          width: "100px",
                          display: "inline-block",
                          // whiteSpace: "nowrap",
                        }}
                      >
                        Patient Name
                      </span>
                      : {selectedMoneyReceipt?.name}
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      Age
                      <span
                        style={{ paddingLeft: "15px", display: "inline-block" }}
                      >
                        : {selectedMoneyReceipt?.age} Years
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="m-0 p-0">
                      <span
                        style={{
                          width: "104px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Prof./Dr.
                      </span>
                      : {selectedMoneyReceipt?.referredBy}
                    </p>
                  </div>
                </div>
                <div className="invoice-item-table">
                  <table>
                    <tr className="invoice-border-dashed">
                      <td>Examination</td>
                      <td>Rate</td>
                      <td colSpan={5} className="text-end">
                        Total
                      </td>
                    </tr>
                    {data?.tests?.map((item, i) => (
                      <tr key={i}>
                        <td className="text-start">{item.testName}</td>
                        <td className="text-start">{item.fee}</td>
                        <td colSpan={5} className="text-end">
                          {parseFloat(item.fee).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="invoice-border-dashed-top">
                      <td rowSpan={5} colSpan={2}>
                        {selectedMoneyReceipt?.due_amount > 0 && (
                          <div className=" d-flex justify-content-center align-items-center">
                            <p
                              style={{
                                fontSize: "22px",
                                whiteSpace: "nowrap",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              Due Amount:
                              {parseFloat(
                                selectedMoneyReceipt?.due_amount
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </td>
                      <td colSpan={3} className="text-end">
                        Sub Total :
                      </td>
                      <td className="text-end">
                        {parseFloat(data?.totalBill).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-end">
                        Discount :
                      </td>
                      <td className="text-end">
                        {parseFloat(
                          parseFloat(data?.discount || 0) +
                            parseFloat(data?.specialDiscount || 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Bill Total :
                      </td>
                      <td className="text-end">
                        {parseFloat(
                          parseFloat(data?.totalBill) -
                            parseFloat(data?.discount)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Paid :
                      </td>
                      <td className="text-end">
                        {parseFloat(selectedMoneyReceipt?.paid_amount).toFixed(
                          2
                        )}
                      </td>
                    </tr>
                    {selectedMoneyReceipt?.due_amount > 0 && (
                      <tr className="invoice-border-dashed-top">
                        <td colSpan={3} className="text-end">
                          Due :
                        </td>
                        <td className="text-end">
                          {parseFloat(selectedMoneyReceipt?.due_amount).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
                <br />
                <div className="d-flex invoice-creator justify-content-between mt-4">
                  <p>
                    Delivery Date:
                    {moment(data?.deliveryDate).format("DD/MM/YYYY")} -
                    {moment(data?.deliveryTime, "HH:mm:ss").format("hh A")}
                  </p>
                  <p>Posted : {user?.name}</p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <Barcode
                    displayValue="false"
                    lineColor="#333333"
                    width={2}
                    height={20}
                    value={selectedMoneyReceipt?.money_receipt_number || 10001}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </NewModal.Body>
    </NewModal>
  );
};

const GreatLabInvoiceEdit = ({ invoice, setUpdateData, onClose, isOpen }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(invoice);
  }, [invoice]);
  const discount = data?.tests?.reduce(
    (totalDiscount, current) =>
      totalDiscount + parseFloat(current.discount || 0),
    0
  );
  const totalBill = data?.tests?.reduce(
    (totalBill, current) => totalBill + parseFloat(current?.fee || 0),
    0
  );

  const totalDiscount = discount + parseFloat(data?.specialDiscount || 0);
  const dueAmount =
    totalBill - (parseFloat(data?.paidAmount || 0) + totalDiscount);
  const subTotal = parseFloat(totalBill || 0) - totalDiscount;
  const refundAmount = parseFloat(data?.paidAmount || 0) - (subTotal || 0);
  const removeTest = (test) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/great-lab-delete-invoice-details/${test.id}`)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              const existTest = [...data?.tests];
              const filtered = existTest.filter((item) => item.id !== test.id);
              setData({ ...data, tests: filtered });
            }
          })
          .catch((err) => {
            toast.error("Something went wrong. Please try again");
          });
      }
    });
  };

  const handleSpecialDiscount = (e) => {
    const { value, name } = e.target;
    if (name === "refundAmount") {
      if (value) {
        if (refundAmount >= parseFloat(value)) {
          setData({
            ...data,
            refundAmount: parseFloat(value) || 0,
          });
        } else {
          toast.error(
            "Refund amount should be less than or equal to paid amount !"
          );
          return;
        }
      } else {
        setData({
          ...data,
          refundAmount: 0,
        });
      }
    } else {
      setData({ ...data, [name]: parseFloat(value) || 0 });
    }
  };

  const handleSubmit = () => {
    const invoiceData = {
      totalBill,
      dueAmount: dueAmount > 0 ? dueAmount : 0,
      specialDiscount: data?.specialDiscount,
      paidAmount: data?.paidAmount,
      refundAmount: data?.refundAmount,
    };
    axios
      .post(`/great-lab-update-invoice/${data?.id}`, invoiceData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setUpdateData(Math.random());
          onClose();
        }
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try again");
      });
  };
  console.log(refundAmount, "moneyReceiptData");
  return (
    <NewModal isOpen={isOpen} onClose={onClose} size="md">
      <NewModal.Header onClose={onClose}>
        <NewModal.Title>Invoice Edit</NewModal.Title>
      </NewModal.Header>
      <NewModal.Body>
        <div className="row">
          <div className="col-4">
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Invoice No
              </span>
              <span className="ms-1">: {data?.invoiceNo}</span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Name
              </span>
              <span className="ms-1">: {data?.patient?.fullName}</span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Created By
              </span>
              <span className="ms-1">: {data?.created_by}</span>
            </p>
          </div>
          <div className="col-4">
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Date
              </span>
              <span className="ms-1">
                : {moment(data?.created_at).format("DD/MM/YYYY")}
              </span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Delivery Date
              </span>
              <span className="ms-1">
                : {moment(data?.deliveryDate).format("DD/MM/YYYY")}
              </span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Reference By
              </span>
              <span className="ms-1">: {data?.referredBy}</span>
            </p>
          </div>
          <div className="col-4">
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Total Amount
              </span>
              <span className="ms-1">: {totalBill}</span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Discount
              </span>
              <span className="ms-1">: {totalDiscount}</span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Paid Amount
              </span>
              <span className="ms-1">: {data?.paidAmount}</span>
            </p>
            <p className="mb-0">
              <span
                className="d-inline-block"
                style={{ fontWeight: "bold", width: "110px" }}
              >
                Due Amount
              </span>
              <span className="ms-1">: {dueAmount > 0 ? dueAmount : 0}</span>
            </p>
          </div>
        </div>
        <div className="mt-2">
          <h6>Invoice Details</h6>
          <div className="invoice-item-table">
            <table>
              <tr className="invoice-border-dashed">
                <td>Name</td>
                <td>Rate</td>
                <td className="text-end">Discount</td>
                <td className="text-end">Total</td>
                <td className="text-end">Action</td>
              </tr>
              {data?.tests?.map((item, i) => (
                <tr key={i}>
                  <td className="text-start">{item?.testName}</td>
                  <td className="text-start">{item?.fee}</td>
                  <td className="text-end">{item?.discount}</td>
                  <td className="text-end">
                    {parseFloat(item?.fee || 0) -
                      parseFloat(item?.discount || 0)}
                  </td>
                  <td className="text-end">
                    <button
                      disabled={Number(item?.collectionStatus) === 1}
                      style={{
                        border: "none",
                      }}
                      onClick={() => removeTest(item)}
                      className="btn  btn-sm action-btn"
                    >
                      <i className="far fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={3} className="text-end">
                  Special Discount :
                </td>
                <td className="text-end">
                  <div className="d-flex justify-content-end">
                    <div style={{ width: "80px" }}>
                      <input
                        className="form-control form-control-sm text-end"
                        type="number"
                        name="specialDiscount"
                        onChange={handleSpecialDiscount}
                        value={data?.specialDiscount}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="text-end">
                  Total :
                </td>
                <td className="text-end"> {subTotal}</td>
              </tr>
              <tr>
                <td colSpan={3} className="text-end">
                  Refund Amount :
                </td>
                <td className="text-end">
                  <div className="d-flex justify-content-end">
                    <div style={{ width: "80px" }}>
                      <input
                        className="form-control form-control-sm text-end"
                        type="number"
                        name="refundAmount"
                        onChange={handleSpecialDiscount}
                        disabled={
                          refundAmount < 1
                            ? true
                            : refundAmount <= parseFloat(data?.refundAmount)
                            ? true
                            : false
                        }
                        value={data?.refundAmount || ""}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </NewModal.Body>
      <NewModal.Footer>
        <Button className="btn btn-sm btn-outline-primary" onClick={onClose}>
          Close
        </Button>
        <Button className="btn btn-sm btn-primary" onClick={handleSubmit}>
          Save
        </Button>
      </NewModal.Footer>
    </NewModal>
  );
};
